import React, { Component, useState } from 'react';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import {
  getCuratedCampFront, getpartnerprofileinfo, SearchData, getLearningCampsDetailsByTerm,
  partnerdetailforsummercamp, partnerdetailforsummercamptuition,
  getLearningSummerCampsExcluded, getLearningSummerCamps, campRegisterationApi,
  LocationData, storeUserAgent, getSearchList, commonAPI, storeUserAgentCurated
} from '../../API/FetchCall';
import SocialMediaButtons from './../SocialShare/socialShare';
import { ReviewCamp } from './../Banner/ReviewComponent';
import { Similar } from './../Banner/similar';
import { Invite } from './../Banner/invite_people';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import $ from 'jquery';
import { AddWishlist } from '../Banner/addWishlist';
import Rating from 'react-star-rating-component';
import { AdsOne, AdsTwo } from '../../components/others/ads';
import { Comments } from './../Banner/comments';
import { LikeFollow } from './../Banner/like_follow';
import { LikeVideo } from './../Banner/like_video';
import { compose, withProps } from "recompose";
import { BASE_URL, IMG_URL, REVIEWDAYS, REVIEWMSG, RATING_GOOGLE } from '../../constant';
import { ContactInfo } from './../Banner/contact_information';
import Gallery from 'react-grid-gallery';
import { Player } from 'video-react';
import ReactTooltip from "react-tooltip";
import { iplocation, partnerSocialLinks } from '../../common';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import Slider from "react-slick";
const RAZORPAY_LIVE = 'rzp_live_B1Rc514TPFi72L';
const RAZORPAY_TEST = 'rzp_test_4LSxJTaCiKuCim';
import Autosuggest from 'react-autosuggest';
import Moment from 'moment';
import { Helmet } from 'react-helmet';
import { TwitterIcon, FacebookIcon, WhatsappIcon, LinkedinIcon, EmailIcon } from "react-share";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton
  //   EmailShareButton
} from 'react-share';

import {
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
//const IMGPATH = 'http://13.250.31.248:5005/';
const IMGPATH = 'https://api.bewise.in/';

const defaultBanner = 'https://api.bewise.in/uploads/campdefaultbanner.png';


class SummerCamp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      utc_offset:'',
      countrylocated: 'IN',
      utc_offset:'',
      partnerprofile: '',
      countryName: '',
      countryCode: '',
      openRegisterModalLogin: false,
      openLTermisModal: false,
      breadcrumb: '',
      similarList: [],
      suggestions: [],
      location_list: [],
      citysuggestions: [{
        id: "bengaluru",
        text: "Bengaluru"
      },
      {
        id: "coimbatore",
        text: "Coimbatore"
      }],
      value: '',
      rating: [],
      review_option: false,
      contact_info: false,
      authe: false,
      average_fees: true,
      error: false,
      campinfo: null,
      socialShare: false,
      showing: false,
      noofreview: 0,
      phone_number: "Click to View/Call",
      readmore: true,
      schoolType: localStorage.getItem('sub_type'),
      sports_type: localStorage.getItem('type'),
      shareurl: window.location.href,
      readmore: true,
      partnerlink: '',
      photo_path: [],
      suggestions: [],
      program: '',
      contact_person: '',
      contact_phonenumber: '',
      contact_email_id: '',
      partner: '',
      program_id: '',
      camp_id: '',
      showsuggest: false,
      showsuggestcity: false,
      totalchildren: 1,
      childrennames: '',
      childrenages: '',
      school: '',
      amount: 0.00,
      currency: 'INR',
      othersummercamps: [],
      partner: '',
      agree1: false,
      agree2: false,
      authe: localStorage.getItem('logged_auth') ? localStorage.getItem('logged_auth') : false,
      user_ip_address: '',
      user_country: '',
      user_location: '',
      camp_page_id: 0,
      page_partner: 0,
    };

    this.handleRegistration = this.handleRegistration.bind(this);
    this.handleAcceptance = this.handleAcceptance.bind(this);
  }



  componentDidMount() {

    let auth = localStorage.getItem("logged_auth");
    let data_auth = (localStorage.getItem("myData"));
    let userid, pid = '';
    if (data_auth) {
      let auth_data = JSON.parse(data_auth)
      this.setState({
        logged_name: auth_data.first_name + ' ' + auth_data.last_name,
        authe: true,
        user_id: auth_data.user_id,
      });
      userid = auth_data.user_id;
    } else {
      userid = '';
    }

    this.getCamps(this.props.match.params.name);

  }


  getPartner(vendor_id) {

    let InputData = JSON.stringify({
      partner_id: vendor_id
    });


    getpartnerprofileinfo(InputData).then((response) => {
      if (response.status == 1) {
        this.setState({
          partnerlink: response.result
        })
      }
      else {
        this.setState({
          partnerlink: ''
        })

      }

    });

  }

  getCamps(path_term) {

    let InputData = JSON.stringify({
      path_term: path_term
    });

    getCuratedCampFront(InputData).then((response) => {
      if (response.status == 1) {

        let ExcludedData = JSON.stringify({
          camp_id: response.result.camp_id
        });
        this.fetchIpLocation(response.result);
        this.setState({
          campinfo: response.result
        })

        this.getPartner(response.result.vendor_id);

        getLearningSummerCampsExcluded(ExcludedData).then((response2) => {
          if (response2.status == 1) {
            this.setState({
              othersummercamps: response2.data
            })
          }

        });


      }

    });

  }

  LocationDataList = async () => {
    try {
      const res = await LocationData();
      if (res.status == 1) {
        this.setState({
          location_list: res.results,
          error: false
        });
      }

    } catch (error) {
      this.setState({ error: true });
    }
  };

  openreg = (e) => {
    $(".buyform").toggleClass('hidden');
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      school: newValue,
      value: newValue,
      showsuggest: false
    });
  };


  addmorerow = (e) => {

    let cnt = this.state.totalchildren + 1;
    var newrow = $(".childrenrow").clone().removeClass('childrenrow').find("input").val("").end()
    newrow.find('input').each(function () {
      this.name = this.name.replace('1', cnt);
      //$(this).attr("onChange",'{this.handleChange}');
    });

    newrow.insertBefore(".btnrow");
    this.setState({
      totalchildren: cnt
    });


  }

  handleRegistration(e) {
    e.preventDefault();
    //console.log("f",this.state.totalchildren);
    var names = [];
    var ages = [];
    var totalcount = this.state.totalchildren;
    for (var i = 1; i <= totalcount; i++) {
      if ($('input[name="child_name' + i + '"]').val() != '')
        names.push($('#campform').find('input[name="child_name' + i + '"]').val());
      if ($('input[name="child_age' + i + '"]').val() != '')
        ages.push($('#campform').find('input[name="child_age' + i + '"]').val());
    }


    this.setState({
      childrennames: names.toString(),
      childrenages: ages.toString()
    })


    $("#campform").addClass('hidden');
    $("#preview").removeClass('hidden');
  }

  fetchIpLocation(camp) {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        // console.log('***------------', data);
        let ua = navigator.userAgent;
        let device = 'desktop';

        if (/(tablet|iPad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
          device = "tablet";
        } else if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          ua
        )
        ) {
          device = "mobile";
        }
        this.setState({ countrylocated: data.country, utc_offset:data.utc_offset });

        if (data.country == 'IN') {
          this.setState({ currency: 'INR' });
        } else {
          this.setState({ currency: 'USD' });
        }

        let client_data = {
          ip_address: data.ip,
          country: data.country,
          location: data.city,
          device: device,
          user_id: this.state.user_id,
          vendor_id: camp.vendor_id,
          category: 999,
          sub_ref_id: 0,
          page_id: camp.camp_id
        };
        this.setState({
          user_ip_address: data.ip,
          user_device: device,
          user_country: data.country,
          user_location: data.city,
          page_partner: camp.vendor_id,
          camp_page_id: camp.camp_id
        })
        storeUserAgent(JSON.stringify(client_data)).then((response) => {
        });
        client_data.type = 1;
        storeUserAgentCurated(JSON.stringify(client_data)).then((response) => {
        });
      }

      )
      .catch(error => {
        console.log('************', error)
        this.setState({ error, isLoading: false })
      });
  }

  socialShare() {
    this.setState(prevState => ({
      socialShare: !prevState.socialShare,
    }));
  }

  checkboxHandler1 = (e) => {

    if (e.target.checked) {
      this.setState({ agree1: true });
    }
    else
      this.setState({ agree1: false });
  }

  checkboxHandler2 = (e) => {
    if (e.target.checked) {
      this.setState({ agree2: true });
    } else {
      this.setState({ agree2: false });
    }
  }

  processPay(program_id) {

    //alert(program_id);
    this.LocationDataList();
    this.setState({
      openRegisterModalLogin: true
    })

  }
  processRegPay(program_name,
    program_id,
    camp_id,
    inrfees,
    usdfees,
    contact_person,
    contact_phonenumber,
    contact_email_id,
    partner,
    partner_title,
    path_term
  ) {


    if (this.state.authe) {

      let data_auth = (localStorage.getItem("myData"));
      let auth_data = JSON.parse(data_auth);

      this.setState({
        first_name: auth_data.first_name,
        last_name: auth_data.last_name,
        email_id: auth_data.email_id
      });

    }

    let country = this.state.countrylocated;
    if (country == 'IN') {
      let amount = inrfees;
      amount = amount * 100;
      this.setState({
        amount: amount
      });
    } else {
      let amount = usdfees;
      amount = amount * 100;
      this.setState({
        amount: amount
      });
    }

    //alert(program_id);
    this.LocationDataList();
    this.setState({
      camp_id: camp_id,
      program: program_name,
      contact_person: contact_person,
      contact_phonenumber: contact_phonenumber,
      contact_email_id: contact_email_id,
      partner: partner,
      program_id: program_id,
      path_term: path_term,
      openRegisterModalLogin: true
    })
    let client_data = {
      ip_address: this.state.user_ip_address,
      country: this.state.user_country,
      location: this.state.user_location,
      device: this.state.user_device,
      user_id: this.state.user_id,
      vendor_id: this.state.page_partner,
      type: 2,
      sub_ref_id: program_id,
      page_id: this.state.camp_page_id
    };
    storeUserAgentCurated(JSON.stringify(client_data)).then((response) => {
    });
  }

  closepopterms = (e) => {
    this.setState({
      openLTermisModal: false
    })
  }

  closepop = (e) => {
    this.setState({
      openRegisterModalLogin: false
    })
  }


  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAcceptance = (e) => {

    console.log(e);
    this.setState({ [e.target.name]: e.target.value });

    var acc2 = this.state.agreedterms2;
    var acc1 = this.state.agreedterms1;
    if (acc1 == 1 && acc2 == 1) {
      $(".paybtn").removeClass('hidden');
    }
    else
      $(".paybtn").addClass('hidden');
  };

  shouldRenderSuggestions = (value, reason) => {
    return value.trim().length > 2;
  };

  shouldRenderCitySuggestions = (value, reason) => {
    return value.trim().length > 2;
  };

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSuggestionsCity = async (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0 ? [] : this.state.citysuggestions
  };

  getSuggestions = async (value) => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    let list = await this.getSearchListFetch(escapedValue);

    var final = [];
    if (list.status != 0) {
      final = list.list
      this.setState({
        searchList: list.list
      })
    } else {
      final = [];
    }
    return final;
  }

  getSearchListFetch = (val) => {
    if (val.trim().length > 2) {
      let data = JSON.stringify({
        keyword: val,
        city: this.state.city,
      })
      return getSearchList(data).then(response => response);
    }

  }

  getSuggestionValue(suggestion) {
    return suggestion.name;
  }

  renderSuggestion(suggestion) {
    return (
      <span>{suggestion.name}</span>
    );
  }


  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value).then(response => {
      this.setState({
        suggestions: response
      });
    })
  };


  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  openTerms = async (e) => {
    this.setState({
      openLTermisModal: true
    })
  }

  displayRazorpay = async (e) => {
    e.preventDefault();

    $(".successmessage").html('processing....');
    if (1) {
      const res = await this.loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      let ins = JSON.stringify({
        amount: 1500
      });

      this.payinfo();

    } else {
      this.validator.showMessages();
      //this.forceUpdate();
    }
  }

  loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }


  locationList(location_select = '') {
    let locate = this.state.location_list !== undefined ? this.state.location_list : [];

    let rows = "";
    if (locate.length > 0) {
      rows = locate.map((locate, index) => {
        return (
          <option key={index} value={locate.location} >{locate.location}</option>
        );
      });

    } else {
      rows = "";
    }
    return rows;
  }


  handlePaySubmit(response) {
    let razorpay_payment_id = response.razorpay_payment_id;
    console.log("razor pay response", razorpay_payment_id);
    if (razorpay_payment_id != '') {
      let ins = '';

      ins = JSON.stringify({
        camp_id: this.state.camp_id,
        program_id: this.state.program_id,
        program_name: this.state.program,

        contact_person: this.state.contact_person,
        contact_phonenumber: this.state.contact_phonenumber,
        contact_email_id: this.state.contact_email_id,
        partner: this.state.partner,
        path_term: this.state.path_term,
        user_id: this.state.user_id,
        firstname: this.state.first_name,
        lastname: this.state.last_name,
        phonenumber: this.state.mobile_number,
        email_id: this.state.email_id,
        childrennames: this.state.childrennames,
        childrenages: this.state.childrenages,
        created_date: this.state.program_id,
        razorpay_payment_id: razorpay_payment_id,
        amount: this.state.amount,
        city: this.state.city,
        school: this.state.school,
        razorpay_signature: response.razorpay_signature,
        ip_address: this.state.user_ip_address,
        country: this.state.user_country,
        location: this.state.user_location,
        device: this.state.user_device,
        vendor_id: this.state.page_partner,
        type: 3,
        page_id: this.state.camp_page_id
      });
      console.log("postngdata", ins);

      campRegisterationApi(ins).then((res) => {
        let toastr = '';
        $(".successmessage").html('');
        $(".errormessage").html('');
        if (res.status === 1 && res.message == 'success') {

          $(".messageafterpayment").removeClass('hidden');
          $(".paybtn").addClass('hidden');
          $(".successmessage").html('Thanks!, Your payment is successfull. Soon you will receive your transaction and camp information via email!.')

        } else {
          let errmesg = res.errors[0].msg
          $(".messageafterpayment").removeClass('hidden');
          $(".errormessage").html('sorry!' + errmesg);
        }
      });

    } else {
      let toastr = '';
      toastr = window.toastr.error; //warning
      toastr('', 'Failed to Pay', {
        positionClass: "toast-bottom-right",
        containerId: "toast-bottom-right"
      });
    }
  }

  payinfo() {
    var This = this;
    var payment_data;
    const options = {
      key: RAZORPAY_LIVE, // Enter the Key ID generated from the Dashboard
      amount: this.state.amount.toString(),
      currency: this.state.currency,
      name: this.state.first_name,
      description: this.state.program,
      // image: { logo },
      // order_id: this.state.order_id,
      handler: async function (response) {
        console.log('response', response);
        This.handlePaySubmit(response);
        payment_data = response.razorpay_payment_id;
        const data = {
          // orderCreationId: this.state.order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        //  const result = await axios.post("/payment/success", data);
      },
      prefill: {
        name: this.state.first_name,
        email: this.state.email_id,
        contact: this.state.phone_number,
      },
      theme: {
        color: "#61dafb",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

  }

  renderCampsMobiles() {


    let rows = '';
    let THIS = this;
    rows = this.state.othersummercamps.map((content, inx) => {

      let tile_image = String(content.gallery).split(',');
      let tile_imagefirstimage = tile_image[0];
      tile_imagefirstimage = tile_imagefirstimage ? tile_imagefirstimage : content.default_img;

      return (

        <div class="campbox mobileblock">
          <div class="blogDet">

            <div class="row">
              <div class="col-7 camptitle">
                {content.camp_name}
              </div>
              <div class="col-5">
                <img src={IMGPATH + tile_imagefirstimage} class="camp-author-img" alt="Camp Image" />
              </div>
              <div class="col-lg-12 col-xs-12 col-sm-12 campdetail">
                <div className="otherrow">From <strong>{Moment(content.program_dates_from).format("DD-MM-YYYY")}</strong> to <strong>{Moment(content.program_dates_to).format("DD-MM-YYYY")}</strong></div>
                <div className="otherrow"><strong>{content.program_duration}</strong> Hour(s)</div>
                <div className="otherrow"><strong>{content.program_days}</strong> day(s) per week</div>
                <div className="otherrow"><strong>
                  {this.state.countrylocated == 'IN' && (
                    <div>{'₹' + Number(content.inrfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                  )}
                  {this.state.countrylocated != 'IN' && (
                    <div>{'$' + Number(content.usdfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                  )}
                </strong> for the program </div>
              </div>
              <div class="col-lg-8 col-xs-8 col-sm-8 viewmoretext">
                For more information and registration, click view more
              </div>
              <div class="col-lg-4 col-xs-4 col-sm-4 viewmore">
                <a href={"/learningcamps/" + content.path_term} className="btn btn-primary btn-sm viewmorelink">View More</a>
              </div>
            </div>

          </div>
        </div>
      )
    });
    return rows;
  }


  renderCamps() {
    let rows = '';
    let THIS = this;
    rows = this.state.othersummercamps.map((content, inx) => {

      let tile_image = String(content.gallery).split(',');
      let tile_imagefirstimage = tile_image[0];
      tile_imagefirstimage = tile_imagefirstimage ? tile_imagefirstimage : content.default_img;

      return (

        <div class="campbox">
          <div class="blogDet">

            <div class="row">
              <div class="col-lg-7 col-xs-7 col-sm-7 camptitle">
                {content.camp_name}
              </div>
              <div class="col-lg-5 col-xs-5 col-sm-5">
                <img src={IMGPATH + tile_imagefirstimage} class="camp-author-img" alt="Camp Image" />
              </div>
              <div class="col-lg-12 col-xs-12 col-sm-12 campdetail">
                <div className="otherrow">From <strong>{Moment(content.program_dates_from).format("DD-MM-YYYY")}</strong> to <strong>{Moment(content.program_dates_to).format("DD-MM-YYYY")}</strong></div>
                <div className="otherrow"><strong>{content.program_duration}</strong> Hour(s)</div>
                <div className="otherrow"><strong>{content.program_days}</strong> day(s) per week</div>
                <div className="otherrow"><strong>
                  {this.state.countrylocated == 'IN' && (
                    <div>{'₹' + Number(content.inrfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                  )}
                  {this.state.countrylocated != 'IN' && (
                    <div>{'$' + Number(content.usdfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                  )}
                </strong> for the program </div>
              </div>
              <div class="col-lg-8 col-xs-8 col-sm-8 viewmoretext">
                For more information and registration, click view more
              </div>
              <div class="col-lg-4 col-xs-4 col-sm-4 viewmore">
                <a href={"/learningcamps/" + content.path_term} className="btn btn-primary btn-sm viewmorelink">View More</a>
              </div>
            </div>

          </div>
        </div>

        /* <div className="single-app-features pb-20 mb-20 col-12" key={inx}>
         <div className="app-features-content insight-home-custome">
           <a href={'/summercamps/' + content.path_term} target='_blank' className="" >
             <div className='row'>
               <div className='col-md-3'>
                 {(tile_imagefirstimage != '') &&
                   (<img src={IMG_URL + tile_imagefirstimage} className="insight-home-custome-img" alt='Banner Image' />)
                 }
               </div>
               <div className='col-md-9'>
                 <h5 title={content.camp_name}>{content.camp_name}</h5>
               </div>
             </div>
           </a>
         </div>
       </div> */

      )
    });
    return rows;
  }

  render() {

    const othercampssettings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 2,
      slidesToScroll: 2,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
            slidesToScroll: 1
          }
        }
      ]
    };

    const gallerysettings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 5,
      className: 'summercamps',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
            slidesToScroll: 1
          }
        }
      ]
    };

    const programssettings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      className: 'summercamps',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
            slidesToScroll: 1
          }
        }
      ]
    };

    const { campinfo, partnerlink, error, value, citysuggestions, suggestions, partner, partnerprofile } = this.state;


    if (campinfo === null) {
      return (
        <React.Fragment>
          <div class="overlay-custom"></div>
          <Header />
          <div class='spinner' role='status'>
            <span class='sr-only'>Loading...</span>
          </div>
          <div className='preloadDiv'></div>
          <Footer />
        </React.Fragment>
      );
    }


    let scl_value = "";

    let programs = [];

    let summercamps = [];

    let gallery = this.state.campinfo.gallery.split(',');

    programs = this.state.campinfo.program_names;

    for (var i = 0; i < programs.length; i++) {
      //item.program_name,item.camp_id,item.program_id, item.inrfees, item.usdfees
      summercamps.push({
        program_name: this.state.campinfo.program_names[i],
        program_dates_from: this.state.campinfo.program_fromdates[i],
        program_dates_to: this.state.campinfo.program_todates[i],
        program_starttime: this.state.campinfo.program_starttime[i],
        program_endtime: this.state.campinfo.program_endtime[i],

        contact_person: this.state.campinfo.contact_person,
        contact_phonenumber: this.state.campinfo.phone_number,
        contact_email_id: this.state.campinfo.contact_email_id,
        partner: this.state.campinfo.partner_name_title + "." + this.state.campinfo.partner_name,

        inrfees: this.state.campinfo.program_feeinr[i],
        usdfees: this.state.campinfo.program_feeusd[i],
        camp_id: this.state.campinfo.camp_id,
        program_id: this.state.campinfo.program_ids[i]

      });

    }

    //let bannerimage = 'http://13.250.31.248:5005/uploads/fqqri8_1648042591855.jpg';
    let provider_logo = 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/testing-logo-design-template-ce84480d61b3db9a8e1522a99875832f_screen.jpg?ts=1615794516';

    const inputPropsD = {
      placeholder: "Choose your school by typing name",
      value,
      className: 'form-control',
      id: 'searchd',
      name: 'school',
      autoComplete: 'off',
      onChange: this.onChange,
      onClick: () => {
        this.setState({
          showsuggest: true
        })
      }
    };

    var meta_title = '';
    var meta_description = '';

    if (this.props.match.params.name == 'drawing-bewise-summer-camp') {
      meta_title = 'Bewise Drawing Summer Camp';
      meta_description = 'Bewise is here with the best online drawing learning summer camps, Join the virtual summer drawing classes for kids and teens. Learn different pencil drawing with shading techniques & more.';
    }


    if (this.props.match.params.name == 'french-language-summer-camp-2022') {
      meta_title = 'Bewise French Language Summer Camp';
      meta_description = 'Bewise is here with the best online French learning summer camps, Join the virtual summer, French language classes, for kids and teens. Learn Foundation, vocabulary, grammar, respond with simple conversations & more.';
    }


    if (this.props.match.params.name == 'kannada-bewise-summer-camp') {
      meta_title = 'Bewise Kannada Language Summer Camp';
      meta_description = 'Bewise is here with the best online Kannada learning summer camps, Join the virtual summer Kannada language classes for kids and teens. Learn Foundation, vocabulary, Grammar, reading, writing, & more.';
    }


    if (this.props.match.params.name == 'coding-bewise-summer-camp') {
      meta_title = 'Bewise Storytelling Summer Camp';
      meta_description = 'Bewise is here with the best online storytelling skills learning summer camps, Join the virtual summer storytelling art classes for kids and teens. Build your children emotional intelligence & cognitive ability with the art of storytelling.';
    }

    if (this.props.match.params.name == 'storytelling-bewise-summer-camp') {
      meta_title = 'Bewise Storytelling Summer Camp';
      meta_description = 'Bewise is here with the best online storytelling skills learning summer camps, Join the virtual summer storytelling art classes for kids and teens. Build your children emotional intelligence & cognitive ability with the art of storytelling.';
    }

    let tile_image = String(campinfo.gallery).split(',');
    let tile_imagefirstimage = tile_image[0];
    tile_imagefirstimage = tile_imagefirstimage ? tile_imagefirstimage : 'https://www.bewise.in/assets/images/banner-logo.jpg';

    let video = (this.state.campinfo.video==null)?'':this.state.campinfo.video;

    return (
      <React.Fragment>

        <Helmet>
          <title>{meta_title}</title>
          <meta property="og:url" content={this.state.shareurl} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={meta_title} />
          <meta property="og:image" content={tile_imagefirstimage} />
          <meta property="og:description" content={meta_description} />
        </Helmet>

        <div className="body-custom-class summercampdetail">
          <Header login={this.state.openModalLogin} />

          <section className='campdetails container'>
            <div className="campbannersection" style={{ backgroundImage: campinfo.banner!='' ? `url("`+IMGPATH+campinfo.banner+`")` : `url(${defaultBanner})` }}>
              <div className="bannercampname">
               { /* <h3>{campinfo.camp_name}</h3> */}
              </div>

             

            </div>
          </section>

          <section className='campdetails container'>


            <div class="row mtop10px">
              <div className='col-md-12 no-padding'>

                <div className="hidden">Your Location : {this.state.countrylocated} </div>
              
              </div>


              {summercamps.length > 0 && (

                <div class="campdesc">

                  <h4>Program Information <span class="smallnoteright desktoponly">Call <b>{campinfo.contact_person}</b> at +91{campinfo.phone_number} for further clarifications NOW!</span></h4>
                  <div class="mobileonly"><span class="smallnoteright">Call <b>{campinfo.contact_person}</b> at +91{campinfo.phone_number} for further clarifications NOW!</span></div>
                  <div class="programinfo_item mobileonly">

                   
                      {summercamps ? (summercamps.map((item) => (


                        <div class="programrowmobile displayblock">
                          <div class="programname">{item.program_name}</div>
                          <div><label>From:</label>{Moment(item.program_dates_from).format("DD-MM-YYYY")}</div>
                          <div><label>To:</label>{Moment(item.program_dates_to).format("DD-MM-YYYY")}</div>
                          <div><label>Timings:</label>Flexible</div>
                          {this.state.countrylocated != 'IN' && (
                            <div>(local time:{Moment(item.program_dates_from+" "+item.program_starttime).utcOffset(this.state.utc_offset).format('LT')})</div>
                          )}
                          <div><label>End Time:</label>{Moment(item.program_endtime, "hh:mm").format('LT')}</div>
                          {this.state.countrylocated != 'IN' && (
                            <div>(local time:{Moment(item.program_dates_to+" "+item.program_endtime).utcOffset(this.state.utc_offset).format('LT')})</div>
                          )}
                          {this.state.countrylocated == 'IN' && (
                            <div>{'₹' + Number(item.inrfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                          )}
                          {this.state.countrylocated != 'IN' && (
                            <div>{'$' + Number(item.usdfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</div>
                          )}
                          <div>

                            <button className="btn btn-primary buybuttontable" onClick={() => this.processRegPay(item.program_name,
                              item.camp_id,
                              item.program_id,
                              item.inrfees,
                              item.usdfees,
                              campinfo.contact_person,
                              campinfo.phone_number,
                              campinfo.email_id,
                              campinfo.partner_name, campinfo.partner_name_title, campinfo.path_term)}>Click to join</button>

                            {/* item.buy_option==1 && (

                             <button className="btn btn-primary buybuttontable" onClick={() => this.processRegPay(item.program_name,
                            item.camp_id,
                            item.program_id,
                            item.inrfees,
                            item.usdfees,
                            campinfo.contact_person,
                            campinfo.phone_number,
                            campinfo.email_id,
                            campinfo.partner_name, campinfo.partner_name_title, campinfo.path_term)}>Click to Join</button>
                            )}

                          {item.buy_option==0 && (

                            <strong>CLOSED</strong>

                            )
                           */}

                          </div>
                        </div>

                      ))) : ''}

                   
                  </div>
                  <div class="programinfo_item desktop">
                   { campinfo.camp_id==6 && 
                   
                   <table class="table table-bordered listofprogram">

                      <tr>
                        <th>Program</th>
                        <th>Date From</th>
                        <th>Date To</th>
                        <th>Timings</th>
                        <th>Fees</th>
                        <th>Book now</th>
                      </tr>

                      {summercamps ? (summercamps.map((item) => (

                        <tr class="programrow">
                          <td>{item.program_name}</td>
                          <td>{Moment(item.program_dates_from).format("DD-MM-YYYY")}</td>
                          <td>{Moment(item.program_dates_to).format("DD-MM-YYYY")}</td>
                         
                          <td>Flexible</td>

                          {this.state.countrylocated == 'IN' && (

                            <td>{'₹' + Number(item.inrfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                          )}
                          {this.state.countrylocated != 'IN' && (

                            <td>{'$' + Number(item.usdfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>

                          )}

                          <td>

                            <button className="btn btn-primary buybuttontable" onClick={() => this.processRegPay(item.program_name,
                              item.camp_id,
                              item.program_id,
                              item.inrfees,
                              item.usdfees,
                              campinfo.contact_person,
                              campinfo.phone_number,
                              campinfo.email_id,
                              campinfo.partner_name, campinfo.partner_name_title, campinfo.path_term)}>Click to join</button>


                          </td>
                        </tr>

                      ))) : ''}

                    </table>
                   
                   }

                  { campinfo.camp_id!=6 && 
                    <table class="table table-bordered listofprogram">

                      <tr>
                        <th>Program</th>
                        <th>Date From</th>
                        <th>Date To</th>
                        <th>Time From</th>
                        <th>Time To</th>
                        <th>Fees</th>
                        <th>Book now</th>
                      </tr>

                      {summercamps ? (summercamps.map((item) => (

                        <tr class="programrow">
                          <td>{item.program_name}</td>
                          <td>{Moment(item.program_dates_from).format("DD-MM-YYYY")}</td>
                          <td>{Moment(item.program_dates_to).format("DD-MM-YYYY")}</td>
                          <td>{Moment(item.program_starttime, "hh:mm").format('LT')} <br/> {this.state.countrylocated != 'IN' && (
                            <div style={{fontSize:"9px"}}>(local time:{Moment(item.program_dates_from+" "+item.program_starttime).utcOffset(this.state.utc_offset).format('LT')})</div>
                          )}</td>
                          <td>{Moment(item.program_endtime, "hh:mm").format('LT')}<br/> {this.state.countrylocated != 'IN' && (
                            <div style={{fontSize:"9px"}}>(local time:{Moment(item.program_dates_to+" "+item.program_endtime).utcOffset(this.state.utc_offset).format('LT')})</div>
                          )}</td>

                          {this.state.countrylocated == 'IN' && (

                            <td>{'₹' + Number(item.inrfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>
                          )}
                          {this.state.countrylocated != 'IN' && (

                            <td>{'$' + Number(item.usdfees).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</td>

                          )}

                          <td>

                            <button className="btn btn-primary buybuttontable" onClick={() => this.processRegPay(item.program_name,
                              item.camp_id,
                              item.program_id,
                              item.inrfees,
                              item.usdfees,
                              campinfo.contact_person,
                              campinfo.phone_number,
                              campinfo.email_id,
                              campinfo.partner_name, campinfo.partner_name_title, campinfo.path_term)}>Click to join</button>


                          </td>
                        </tr>

                      ))) : ''}

                    </table>
                    }
                  </div>
                </div>
              )}

              <div>Please review the brochure for the course content</div>

              <div class="campdesc">
                <h4>Contact</h4>
                <div className='banner-btn-main col-md-2'>
                  <div>{campinfo.contact_person}</div>
                  <div>Ph: <span><a href={"tel:" + campinfo.phone_number}>{campinfo.phone_number}</a></span></div>
                </div>
              </div>


              {campinfo.prerequisites != null && (
                <div class="campdesc">
                  <h4>Pre-requisites </h4>
                  <div class="card-text prerequisiteinfo" dangerouslySetInnerHTML={{ __html: campinfo.prerequisites }} />
                </div>
              )}
              {(campinfo.brochure != 'undefined' && campinfo.brochure != '' && campinfo.brochure != null) && (
                <div class="campdesc">
                  <h4>Download Brochure </h4>
                  <div class="brochureinfo">
                  { campinfo.camp_id!=6 && 
                  
                  <a href={IMG_URL + campinfo.brochure}><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png' className="brochurepdficon" /></a>
                  }
                  { campinfo.camp_id==6 &&
                    <a href={IMG_URL + campinfo.brochure}><img src={IMG_URL+'uploads/diyapdficon.png'} width="150px" className="brochurepdficon" /></a>
                  }
                    
                  </div>

                </div>
              )}

              {gallery.length > 0 && (
                <div class="campdesc">

                  <h4>Gallery </h4>

                  <div class="galleryphotos">

                    <Slider {...gallerysettings} >
                      {gallery.map((item, inx) => {

                        return (

                          <img src={IMG_URL + item} className="individualgallery" />


                        )

                      })
                      }
                    </Slider>



                  </div>

                </div>
              )}

              {video !='' && (
                <div class="campdesc">

                  <h4>Video </h4>

                  <div class="videoplayer">

                    <Player
                      playsInline
                      src={IMG_URL + video+"#t=0.1"}
                    />



                  </div>

                </div>
              )}

              <div class="campdesc">
                     <div class="campabout" dangerouslySetInnerHTML={{ __html: `${campinfo.about}` }} />
              </div>

              <div class="campdesc">

                {partnerlink != '' && (
                  <h4>About Camp Organiser <a href={partnerlink}>(profile)</a> </h4>
                )
                }
                {partnerlink == '' && (
                  <h4>About Camp Organiser</h4>
                )
                }

                <div class="partnerinfo">
                  <div class="card mb-3" >
                    <div class="row no-gutters">
                      <div class="col-md-2">
                        <img src={IMG_URL + campinfo.profile_image} class="card-img sc-img" />
                      </div>
                      <div class="col-md-10">
                        <div class="card-body">
                          <h5 class="card-title">{campinfo.partner_name_title + "."}{campinfo.partner_name}</h5>
                          <div class="card-text" dangerouslySetInnerHTML={{ __html: campinfo.about_partner }} />

                        </div>
                      </div>
                    </div>
                  </div>

                </div>


              </div>

            

              <div class="campdesc">
                <h4>Share in Social Media</h4>
                <div className='banner-btn-main col-md-2'>

                  <div className="social-icons-container">
                    <FacebookShareButton
                      url={this.state.shareurl}
                      quote={campinfo.program_name}>
                      <FacebookIcon size={32} round={true}></FacebookIcon>
                    </FacebookShareButton>
                    <WhatsappShareButton
                      url={this.state.shareurl}
                      subject={campinfo.program_name}
                      quote={campinfo.program_name}>
                      <WhatsappIcon size={32} round={true}></WhatsappIcon>
                    </WhatsappShareButton>

                    <TwitterShareButton
                      url={this.state.shareurl}
                      title={campinfo.program_name}>
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      url={this.state.shareurl}
                      title={campinfo.program_name}>
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                  </div>

                </div>
              </div>

              <div class="dividercamppage"><hr /></div>


            </div>

          </section>

          <section className="container">

            <div class="row desktoponly">
              <div className="col-md-12 p-0 pb-5">
                <h5>More BeWise Curated Camps</h5>
                {this.state.othersummercamps.length > 0 ? (
                  <Slider>
                    {this.renderCamps()}
                  </Slider>
                ) : 'No Other camps'}

              </div>
            </div>
            <div class="row mobileonly">
              <div className="col-md-12 p-0 pb-5">
                <h5>More BeWise Curated Camps</h5>
                {this.state.othersummercamps.length > 0 ? (
                  this.renderCampsMobiles()
                ) : 'No Other camps'}

              </div>
            </div>
          </section>

          <Modal className='ipad-lg' isOpen={this.state.openLTermisModal} centered size='md'>
            <ModalBody className='p-0'>
              <button
                className='close-btn'
                data-dismiss='modal'
                aria-label='Close'
                onClick={this.closepopterms}
              >
                <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
              </button>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">Learning Camps Terms</h5>
                </div>
                <div className="modal-body">

                  <ul className="termslist">
                    <li>BeWise Terms and Conditions & the privacy policy have been understood clearly.   </li>
                    <li>BeWise will only accept mandated number of participants for each program / course to ensure quality learning & implementation. </li>
                    <li>All pre-requisites/ requirements shared will be made available for your child during the online classes. (Includes internet connectivity, computers and headphones). </li>
                    <li>Student registration process is not finished until full payment is received.  A mail confirmation on acceptance with invoice will be sent to the mail id provided. </li>
                    <li>All communications for the program including the log in details and progress will be driven by our select partners for the respective courses. Respective camps are run by select and curated partners through our platform.  </li>
                    <li>Once registration for a program is completed & payments received, you will be automatically be enrolled to BeWise platform as an active user, besides the confirmations to your selected learning camp.  </li>
                    <li>Attendance is responsibility of the parent and student. No alternative – class request will be entertained for any student.  Attendance is critical for learning given this is a focussed group class.  </li>
                    <li>In case of any unfortunate circumstances or emergency where any of the camps has to be cancelled (includes the Covid-19 situation), the particular responsible teacher/ institution / partner for the summer camp will offer a replacement of the teacher or align for classes on a later date based on the case.  No refunds permitted. </li>
                    <li>Payment for the services provided shall be made through our chosen payment mode in the BeWise platform and is already supportive of most payment gateways.  </li>
                    <li>In the unfortunate event, if a learner cancels their participation after the start of program, BeWise does not guarantee refunds & a decision will be made on a case-by-case basis by the BeWise team in working with our program partners. (could include participation for a next program at a later date if advance notice is provided) </li>
                    <li>BeWise / Wisdom tech solutions Ltd or our partners hold the right of entry to a child to the class, in case of disruptive behaviour, that puts the child & others at risk. </li>

                  </ul>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal className='ipad-lg' isOpen={this.state.openRegisterModalLogin} centered size='lg'>
            <ModalBody className='p-0'>
              <button
                className='close-btn'
                data-dismiss='modal'
                aria-label='Close'
                onClick={this.closepop}
              >
                <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
              </button>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">Register</h5>
                </div>
                <form method='post' id="campform" onSubmit={this.handleRegistration}>
                  <div className="modal-body campregistrationform">
                    <div className='row'>
                      <div className='col-md-12'>
                        <span>Your are registering for : {this.state.program}</span>
                      </div>
                      <div className='col-md-6'>
                        <label>First Name (parent)</label>
                        <input type="text" name="first_name" className="form-control" onChange={this.handleChange} value={this.state.first_name} required />
                      </div>

                      <div className='col-md-6'>
                        <label>Last Name (parent)</label>
                        <input type="text" name="last_name" className="form-control" onChange={this.handleChange} value={this.state.last_name} required />
                      </div>

                      <div className='col-md-6'>
                        <label>Email Id</label>
                        <input type="text" name="email_id" className="form-control" onChange={this.handleChange} required value={this.state.email_id} />
                      </div>

                      <div className='col-md-6'>
                        <label>Mobile Number</label>
                        <input type="text" name="mobile_number" className="form-control" onChange={this.handleChange} required />
                      </div>

                      <div className='col-md-6'>
                        <label>City</label>

                        <select className='form-control' name="city" onChange={this.handleChange}>
                          <option value="">Select Your City</option>
                          {this.locationList()}
                          <option value={'International'}>International</option>
                          <option value={'Others - India'}>Others - India</option>
                        </select>

                      </div>

                      <div className='col-md-6'>
                        <label>School (Reference)</label>

                        <Autosuggest

                          suggestions={suggestions}
                          shouldRenderSuggestions={this.shouldRenderSuggestions}
                          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                          getSuggestionValue={this.getSuggestionValue}
                          renderSuggestion={this.renderSuggestion}
                          alwaysRenderSuggestions={this.state.showsuggest}
                          inputProps={inputPropsD} />

                      </div>

                      <div className='col-md-12'>

                        <div class="card" style={{ marginTop: "25px" }}>
                          <div class="card-body">
                            <h5 class="card-title">Child Participants</h5>

                            <div class="row childrenrow">

                              <div class="col-md-6">
                                <label>Child Name</label>
                                <input type="text" class="form-control" name="child_name1" onChange={this.handleChange} required />
                              </div>

                              <div class="col-md-6">
                                <label>Child Age</label>
                                <input type="text" class="form-control" name="child_age1" onChange={this.handleChange} required />
                              </div>

                            </div>

                            <div class="container row btnrow">
                              <div className='col-md-12'>
                                <br />
                                <input type="hidden" name="totalchildren" value={this.state.totalchildren} />
                                <button class="btn btn-primary btn-sm" type="button" onClick={() => this.addmorerow()}>Add +</button>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer mob-btn-row">
                    <input
                      type='submit'
                      name='submit'
                      value='Submit'
                      className='btn btn-primary btn-sm'
                    />
                  </div>
                </form>

                <div id="preview" class="container hidden">
                  <p>Please verify your information and proceed to payment</p>
                  <div class="row formrowpaddbottom">
                    <div class="col-md-12"><strong>{this.state.program}</strong></div>
                  </div>
                  <div class="row formrowpaddbottom">
                    <div class="col-md-6">First name (parent): <strong>{this.state.first_name}</strong></div>
                    <div class="col-md-6">Last name (parent): <strong>{this.state.last_name}</strong></div>
                  </div>
                  <div class="row formrowpaddbottom">
                    <div class="col-md-6">Email Id: <strong>{this.state.email_id}</strong></div>
                    <div class="col-md-6">Mobile Number: <strong>{this.state.mobile_number}</strong></div>
                  </div>

                  <div class="row formrowpaddbottom">
                    <div class="col-md-6">City: <strong>{this.state.city}</strong></div>
                    <div class="col-md-6">School: <strong>{this.state.school}</strong></div>
                  </div>

                  <div class="row formrowpaddbottom">
                    <div class="col-md-6">Children Names: <strong>{this.state.childrennames}</strong></div>
                    <div class="col-md-6">Children Ages: <strong>{this.state.childrenages}</strong></div>
                  </div>

                  <div class="row formrowpaddbottom">
                    <div class="col-md-12">
                      <input type="checkbox" name="agreedterms1" value="1" onChange={this.checkboxHandler1} className="form-control-sm checkboxheight5" /> I accept <a href="javascript:window.open('/termsconditions', 'termsconditions', 'width=auto,height=auto')">terms and conditions</a> and <a href="javascript:window.open('/privacypolicy', 'privacypolicy', 'width=auto,height=auto')">privacy policy</a>
                    </div>
                    <div class="col-md-12">
                      <input type="checkbox" name="agreedterms2" value="1" onChange={this.checkboxHandler2} className="form-control-sm checkboxheight5" />  I agree to terms of <span class="accepterms" onClick={() => this.openTerms()}>this learning camp</span>
                    </div>
                  </div>

                  <div class="row formrowpaddbottom paybtn">
                    <div class="col-md-12">
                      <button type="button" disabled={!this.state.agree1 || !this.state.agree2} name="payment" class="btn btn-primary btn-sm paybtn" onClick={this.displayRazorpay}>Confirm and Pay</button>
                    </div>
                  </div>

                  <div class="row messageafterpayment hidden" style={{ "marginBottom": '20px' }}>
                    <div class="col-md-12">
                      <div class="successmessage"></div>
                      <div class="errormessage"></div>
                    </div>
                  </div>
                </div>

              </div>
            </ModalBody>
          </Modal>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default SummerCamp;
