import { commonAPI } from './API/FetchCall';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
export const IMG_URL = process.env.REACT_APP_IMG_URL;
export const SCREEN_SIZE = window.innerWidth;
export const MAXDIS_FIL = 30;
export const MAXDIS_FILP = '30+';
export const MINDIS_FIL = 0;
export const MINFEE_FIL = 0;
export const MAXFEE_FIL_PLAY = 2000;
export const MAXFEE_FIL = 100000;
export const MAXFEE_FILP = '10000+';
export const MAXFEE_FILPlay1 = 10000;
export const MAXFEE_FILPlay = '100000+';
export const DEFAULT_DIS = 30;
export const DEFAULT_FEE = 100000;
export const DEFAULT_FEE_OTHER = 10000;
export const DEFAULT_FEE_PLAY = 2000;
export const FIL_DIS = 'Location (KM)';
export const FIL_FEE = 'Average Fee From (INR/A)';
export const FIL_FEEP = 'Fee Starts From (INR/M)';
export const FIL_FEEEvents = 'Average Price (INR)';
export const CENTERLAT = 12.9716;
export const CENTERLNG = 77.5946;
export const MAXFEE_VAL = 100000;
export const MAXFEE_LAB = '100000+';
export const REVIEWDAYS = 120;
export const REVIEWMSG = 'Thank you.  A fresh review for any institution is enabled after 4 Months period from the previous one.';
export const PAGI_PERPAGE = 15;
export const RATING_GOOGLE = 'Google Ratings';
export const PAGINATION_LBL = {
    first: 'FIRST',
    last: 'LAST',
    previous: 'PRE',
    next: 'NEXT'
};
//Created Oauth2 Goolgle Using bewisega@gmail.com 
export const GOOGLE_LOGIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
export const GOOGLE_LOGIN_SECRET_ID = process.env.REACT_APP_GOOGLE_LOGIN_SECRET_ID;

//Created Map auto complete and captcha Using bewisega@gmail.com 
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const GOOGLE_CAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY;
export const GOOGLE_CAPTCHA_SECRET_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET_KEY;

//Created using bewise account
export const LINKEDIN_CLIENT = process.env.REACT_APP_LINKEDIN_CLIENT;
export const LINKEDIN_SECRET = process.env.REACT_APP_LINKEDIN_SECRET;

//Created using bewise account
export const FACBEOOK_APP_ID = process.env.REACT_APP_FACBEOOK_APP_ID;
export const FACBEOOK_APP_SECRET = process.env.REACT_APP_FACBEOOK_APP_SECRET;

export const INSTAGRAM_APP_ID = process.env.REACT_APP_INSTAGRAM_APP_ID;
export const INSTAGRAM_APP_SECRET = process.env.REACT_APP_INSTAGRAM_APP_SECRET;

//Default City slug
export const DEFAULT_CITY_TERM = 'bengaluru';

// export const REDIRECT = async () => {
//     let req = JSON.stringify({
//         url: 'redirect_urls'
//     })
//     return await commonAPI(req).then((res) => {
//         return res.list
//     })
// };

export const CITIES = [
    {"id":27,"location":"Hosur","latitude":"12.7409","longitude":"77.8253","status":1,"sort_order":4},
    {"id":1,"location":"Bengaluru","latitude":"12.9716","longitude":"77.5946","status":1,"sort_order":1},
    {"id":12,"location":"Chennai","latitude":"13.0569","longitude":"80.2425","status":1,"sort_order":2},
    {"id":11,"location":"Coimbatore","latitude":"11.0168","longitude":"76.9558","status":1,"sort_order":3},
    {"id":2,"location":"Hyderabad","latitude":"17.3850","longitude":"78.4867","status":1,"sort_order":4},
    {"id":24,"location":"Madurai","latitude":"9.9252","longitude":"78.1198","status":1,"sort_order":5},
    {"id":13,"location":"Mumbai","latitude":"19.0760","longitude":"72.8777","status":1,"sort_order":7},
    {"id":15,"location":"Kolkata","latitude":"22.5726","longitude":"88.3639","status":1,"sort_order":8},
    {"id":16,"location":"New Delhi","latitude":"28.6139","longitude":"77.2090","status":1,"sort_order":9},
    {"id":17,"location":"Jaipur","latitude":"26.9124","longitude":"75.7873","status":1,"sort_order":10},
    {"id":18,"location":"Chandigarh","latitude":"30.7333","longitude":"76.7794","status":1,"sort_order":11},
    {"id":19,"location":"Pune","latitude":"18.5204","longitude":"73.8567","status":1,"sort_order":12},
    {"id":20,"location":"Kochi","latitude":"9.9312","longitude":"76.2673","status":1,"sort_order":13},
    {"id":21,"location":"Noida","latitude":"28.5355","longitude":"77.3910","status":1,"sort_order":14},
    {"id":22,"location":"Agra","latitude":"27.1767","longitude":"78.0081","status":1,"sort_order":15},
    {"id":23,"location":"Puducherry","latitude":"11.9416","longitude":"79.8083","status":1,"sort_order":16},
    {"id":25,"location":"Tiruchirappalli","latitude":"10.7905","longitude":"78.7047","status":1,"sort_order":17}
];

export const verticalSmallDesc_preschool = `<b>All Pre-schools, Montessori, Kindergarten, Playschool, Daycare & Special needs Schools in your city</b>`;
export const verticalSmallDesc_school = `<b>All CBSE, ICSE, IB, Stateboard, Boarding, Special Needs schools, E-schools or Alternative schools in your city</b>`;
export const verticalSmallDesc_tuition = `<b>All tutors, coaching classes, and personalized tuition services for all subjects in your city</b>`;
export const verticalSmallDesc_afterschool = `<b>All your After School support classes for Sports, Music, Dance, Theatre, Mind Skills, Hobbies or Arts in your city</b>`;
export const verticalSmallDesc_camp = `<b>Short duration Learning Camps and Workshops in your city that help you explore your child's passion & interests.</b>`;
export const verticalSmallDesc_community = `<b>Career / Higher Edu. Counselors in your city #Leverage value from our curated partners & Gain insights from content curated for you</b>`;
export const verticalSmallDesc_kidsplayarea = `<b>Indoor and Outdoor Play Areas in your city - to help you/ your child have more fun besides help develop motoring and social skills</b>`;  
export const verticalSmallDesc_events = `<b>Venues / Food / Gift options in your city, to celebrate your child's birthdays /milestones and other big occasions</b>`;

export const REDIRECT = [
    {
        old_url: '/schoollinks',
        new_url: '/bengaluru/schools/private-schools'
    },
    {
        old_url: '/preschoollinks',
        new_url: '/bengaluru/pre-schools/montessori'
    },
    {
        old_url: '/kidsplayarealinks',
        new_url: '/bengaluru/kids-play-area/indoor'
    },
    {
        old_url: '/tutionlinks',
        new_url: '/bengaluru/tuitions/maths'
    },
    {
        old_url: '/bengaluru/tuitions/mathematics',
        new_url: '/bengaluru/tuitions/maths'
    },
    {
        old_url: '/language/hindi',
        new_url: '/bengaluru/tuitions/languages/indian-languages'
    },
    {
        old_url: '/birthdaylinks',
        new_url: '/bengaluru/birthday-event/venues'
    },
    {
        old_url: '/camplinks',
        new_url: '/bengaluru/learning-camps/below-4-years'
    },
    {
        old_url: '/schoolactivitylinks',
        new_url: '/bengaluru/after-school-activity/sports'
    },
    {
        old_url: '*/after-school-activity/sports/martial-arts',
        new_url: '/bengaluru/after-school-activity/sports/martial-arts-karate'
    },
    {
        old_url: '*/after-school-activity/music/vocal',
        new_url: '/bengaluru/after-school-activity/music/singing-classes'
    },
    {
        old_url: '/insights-article/all-you-need-to-know-about-preschool-teaching-methodologies',
        new_url: '/articles-blogs/all-you-need-to-know-about-preschool-teaching-methodologies'
    },
    {
        old_url: '/insights-article/*',
        new_url: '/bengaluru/community-support/articles-blogs'
    },
    {
        old_url: '/bengaluru/schools/private-schools/silver-oaks-school-whitefield',
        new_url: '/bengaluru/schools/private-schools/one-world-international-school-whitefield'
    },
    {
        old_url: '/bengaluru/schools/private-schools/silver-oaks-school-sarjapur',
        new_url: '/bengaluru/schools/private-schools/one-world-international-school-sarjapur'
    },
]