import React, { Component } from "react";
import "./main.css";
import { BASE_URL, ADMIN_URL, SCREEN_SIZE, IMG_URL } from "../constant";
import { postQuery } from '../API/FetchCall';
import Rating from "react-star-rating-component";
import { WebCateogry } from './Footer_category';
import { BottomMenu } from './Login_pop';
import config from "../API/DomainName";
import facebook from "../../src/assets/icons/new_facebook_icon.png";
import instagram from "../../src/assets/icons/new_instagram_icon.png";
import twitter from "../../src/assets/icons/new_twitter_icon.png";
import linkedin from "../../src/assets/icons/new_linkedin_icon.png";
import mailus from "../../src/assets/icons/new_email_icon.png";
import shareIcon from "../../src/assets/icons/icons/share_icon.png";
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import SocialMediaButtons from '../components/SocialShare/socialShare';
import ChatBotNew from "./Chatbot";
import wayup from "../../src/assets/icons/wayup.png";
import chatbot from "../../src/assets/icons/chatbot.png";
import {getAds, saveOnclicks} from '../API/FetchCall';
import axios from 'axios'

import Cookies from 'universal-cookie';

const cookies = new Cookies();


import $ from "jquery";
class NewFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ads:[],
            IsSlide:false,
            userrole:'',
            slideIndex:0,
            adclosed:0,
            email_id: '',
            user_id: '',
            authe: false,
            category: [],
            currenturl: window.location.href,
            location: localStorage.getItem('current_location') ? localStorage.getItem('current_location') : 'Bengaluru'
        };

        this.showSlides = this.showSlides.bind(this);
        this.showtoggle = this.showtoggle.bind(this);
        this.showtoggleAd = this.showtoggleAd.bind(this);
        this.ads();
        

        if (!cookies.get('chatclosed')) {
            this.setChat();
        }
             
        
        
        if (!cookies.get('adclosed')) {
            //setTimeout(this.showtoggleAd, 75000);
        } 

        //localStorage.setItem( 'adclosed', 0 );
    }



    showtoggle = (e) => {
      $("#slideout_inner").toggleClass('hidden'); 
      localStorage.setItem( 'adclosed', 1 );
      if ($(".adclickbtn").text() == "Close Ad"){
        $(".adclickbtn").text('View Ad');
      }else{
        this.setState({slideIndex:0});
        $(".adclickbtn").text('Close Ad');
      }
      
    };



    showSlides(){

        let i;
        let slides = document.getElementsByClassName("mySlides");
        let tempindex = this.state.slideIndex;          
        if(slides.length>0){     

        this.setState({IsSlide:true});
                
        for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
        }

        tempindex++;
        this.setState({slideIndex:tempindex});

        if (tempindex > slides.length) {
            tempindex = 1;
            this.setState({slideIndex:tempindex});
        }    
        
        slides[tempindex-1].style.display = "block";  
        setTimeout(this.showSlides, 15000); // Change image every 15 seconds

        }else{
            this.setState({IsSlide:false});
        }

        /*   
        var slideIndex = this.state.slideIndex;
        let slides = document.getElementsByClassName("mySlides");
        if(slides.length>0){ 
            for (var i = 0; i < slides.length; i++) {
             slides[i].style.display = "none";
            }
          slideIndex++;
          if (slideIndex > slides.length) {
            slideIndex = 1
          }
          this.setState({IsSlide:true});
          this.setState({slideIndex:slideIndex});
          slides[slideIndex-1].style.display = "block";
          setTimeout(this.showSlides, 5000); // Change image every 2 seconds
        }else{
            this.setState({IsSlide:false});
        }
        
        */
    }

    
    ads() {

        getAds().then((response) => {
          if (response.status === 1) {
            this.setState({
                ads: response.list
            })
            this.setState({IsSlide:true});
            this.showSlides();

          }
        });

    }
    /*
    componentWillUnmount(){
        sessionStorage.clear();
        localStorage.clear();
    }*/

    componentDidMount() {

        sessionStorage.setItem('introclosed', 0);

        let path = window.location.pathname;
        if (path == '/') {

        }

        let data_auth = (localStorage.getItem("myData"));
        let userid;
        if (data_auth) {
            let auth_data = JSON.parse(data_auth)
            this.setState({
                logged_name: auth_data.first_name + ' ' + auth_data.last_name,
                authe: true,
                user_id: auth_data.user_id,
            });
            userid = auth_data.user_id;
        } else {
            userid = '';
        }
        var mybutton = document.getElementById("totop-sec");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function () { scrollFunction() };

        function scrollFunction() {
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                mybutton.style.display = "flex";
            } else {
                mybutton.style.display = "none";
            }
        }

        const concernedElement = document.querySelector(".qc-cmp-cleanslate");

        document.addEventListener("mousedown", (event) => {
          if (concernedElement.contains(event.target)) {
            this.showtoggleAd();
          } 
        });
        if (!localStorage.getItem('introclosed')) {
            setTimeout(this.intro, 5000);
        }
        
        var adclosed = this.state.adclosed;
        
        console.log(document.cookie);
        


        if (sessionStorage.getItem('adclosed')==1) {
            $(".gy").removeClass('hidden');
            $(".adbtn").hide();
            $(".vadsdiv").removeClass('hidden');
        }

        //this.setChat();

    }

    intro = (e) => {

        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            //nothing doing
        }else{
            introJs().setOptions({showButtons: false,showBullets:false}).onbeforeexit(function () {
                localStorage.setItem('introclosed', 1);
              }).start();
           
        }

        //introJs().start();
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    showtoggleAd = async (e) => {

      var whataction ='';  

      

      $(".css-11wtop9").toggleClass('hidden'); 

      if (sessionStorage.getItem('adclosed')==0 || !sessionStorage.getItem('adclosed')) {
        sessionStorage.setItem('adclosed', 1);
        $(".gy").removeClass('hidden');
        $(".vadsdiv").removeClass('hidden');
        
      }


      if ($(".adbtn").text() == "Close Ad"){


        console.log("cookies", cookies.get('adclosed'));

        const expirationDate = new Date();
        // (Just change 7 for the number of days you want to let this cookie exist)
        expirationDate.setDate(expirationDate.getDate() + 365);

        cookies.set('adclosed', 'closed', { path: '/',  expires: expirationDate });

        $(".adbtn").removeClass('hightlighted');
        //$(".adbtn").hide('View Ad');
        $(".adbtn").hide();
        $(".vadsdiv").hide();
        whataction = 'viewad';
      }else{
        this.setState({slideIndex:0});
        $(".adbtn").addClass('hightlighted');
        $(".adbtn").text('Close Ad');
        whataction = 'closead';
      }

        const res = await axios.get('https://geolocation-db.com/json/')

        let DetailData = JSON.stringify({
            vertical_city: localStorage.getItem('current_location'),
            ip: res.data.IPv4,
            city: res.data.city,
            state: res.data.state,
            country: res.data.country_name,
            user_id:(this.state.user_id=='')? 0 : this.state.user_id,
            page:window.location.href,
            event:whataction,
            counting:1
        });

        saveOnclicks(DetailData);

    };

    setChatNoAnalytics = async (e) => {
        
        $("#chatbotblock").removeClass('hidden'); 

        //closing if ad popup is open
        $(".css-11wtop9").addClass('hidden');
        $(".adbtn").text('View Ad');
        $(".adbtn").removeClass('hightlighted');
        const res = await axios.get('https://geolocation-db.com/json/');

        var whataction = 'openchat';

        let DetailData = JSON.stringify({
            vertical_city: localStorage.getItem('current_location'),
            ip: res.data.IPv4,
            city: res.data.city,
            state: res.data.state,
            country: res.data.country_name,
            user_id:(this.state.user_id=='')? 0 : this.state.user_id,
            page:window.location.href,
            event:whataction,
            counting:1
        });

        saveOnclicks(DetailData);


    };    

    setChat = async (e) => {
        
        const res = await axios.get('https://geolocation-db.com/json/')

        let DetailData = JSON.stringify({
            city: localStorage.getItem('current_location'),
            ip: res.data.IPv4,
            user_id:(this.state.user_id=='')? 0 : this.state.user_id,
            page:window.location.href,
            event:'chatopen',
            counting:1
        });

        saveOnclicks(DetailData).then((response) => {   
                if(response.status==1){
                   $("#chatbotblock").removeClass('hidden'); 
                }
        });

    
        //this.setState({showChatBot: true});
    };
    moveTop = (e) => {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
    };
    closeChat = () => {
        this.setState({
            chatopen: false
        })
        const expirationDate = new Date();
        // (Just change 7 for the number of days you want to let this cookie exist)
        expirationDate.setDate(expirationDate.getDate() + 365);

        cookies.set('chatclosed', 'closed', { path: '/',  expires: expirationDate });

    }

    stopAd = (e) => {

            //e.preventDefault();
            
            $(".gy").addClass('hidden');    

            const { cookies } = this.props;

            console.log("cookies", cookies.get(adclosed));

            const expirationDate = new Date();
            // (Just change 7 for the number of days you want to let this cookie exist)
            expirationDate.setDate(expirationDate.getDate() + 365);

            cookies.set('adclosed', JSON.stringify(data), { path: '/',  expires: expirationDate });

            //setCookie("adclosed", 365);

    }

    render() {
        const { rating, rating1, category, page_menu } = this.state;
        let isMobile = SCREEN_SIZE < 767 ? true : false;

        //const IMG_URL = 'http://localhost:5005/';
        return (
            <React.Fragment>
                { /* this.state.IsSlide && 
                <div id="slideout">
                    <aside >
                    {this.state.adclosed==1 && 
                    <h3 class="adclickbtn" onClick={this.showtoggle}>View Ads</h3>
                    }
                    {this.state.adclosed==0 && 
                    <h3 class="adclickbtn" onClick={this.showtoggle}>Close Ads</h3>
                    }
                    <h3 class="feedbackkbtn" data-toggle="modal" data-target="#feedBackModal">Feedback</h3>
                    </aside>
                    <div id="slideout_inner" className={`slideshow-container ${this.state.adclosed==1 ? 'hidden' :''}`} >
                    {this.state.ads.length>0 && this.state.ads.map((ad, i) => {
                        return (
                            <div class="mySlides fade2">
                                <a href={ad.link} target="_blank"><img src={IMG_URL+"uploads/" + ad.adfilepath} /></a>
                            </div>
                            )
                    })}
                    </div>
                </div>
                */ }
                <div class="rightsideFixedBlock hidden">
                    <aside>
                        <h3 class="chatclickbtn" onClick={() => this.setChat()}>Chat with Us</h3>
                    </aside>
                </div>

                <footer className=" footer-color site-footer" id='userFooter'>
                    <div className="footer-new-sec">
                        <WebCateogry /> 
               
                     
                        <div className="row align-items-center">
                            <div className="col-md-4 col-xs-12 ft-links">
                                <div className="row foot-subsc-form">
                                    <div className="col-lg-6 col-6">
                                        <input
                                            className="form-control"
                                            placeholder="Enter email address"
                                            type="email"
                                            name="email_id"
                                            value={this.state.email_id}
                                            onChange={this.handleChange}
                                            title="Subscribe"
                                        />
                                    </div>
                                    <div className="col-lg-6 col-6">
                                        <button className="btn blue-btn btn-sm" onClick={this.handleSubscribe}>
                                            Subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-xs-12 ft-links">
                                <div className="row foot-social-link">
                                    <div className="col-xs-2">
                                        <a href="https://www.facebook.com/bewise.in" target='_blank'>
                                            <img src={facebook} className="social-icons" alt="Facebook Icon" />
                                        </a>
                                    </div>
                                    <div className="col-xs-2">
                                        <a href="https://www.linkedin.com/company/bewise-in" target='_blank'>
                                            <img src={linkedin} className="social-icons" alt="Linkedin Icon" />
                                        </a>
                                    </div>
                                    <div className="col-xs-2">
                                        <a href="https://www.instagram.com/bewise_in/" target='_blank'>
                                            <img src={instagram} className="social-icons" alt="Instagram Icon" />
                                        </a>
                                    </div>
                                    { /* <div className="col-xs-2">
                                        <a href="https://twitter.com/BewiseIn" target='_blank'>
                                            <img src={twitter} className="social-icons" alt="Twitter Icon" />
                                        </a>
                                    </div> */ }
                                    <div className="col-xs-2">
                                        <a href="mailto:contact@bewise.in">
                                            <img src={mailus} className="social-icons" alt="E-Mail Icon" />
                                        </a>
                                    </div>
                                    <div className="col-xs-2">
                                        <a href="javascript:;" id="socialShareIcons">
                                            <img src={shareIcon} className="social-icons" alt="Share" />
                                            
                                        </a>
                                    </div>
                                    <div className="main-social-icons">
                                        <UncontrolledPopover trigger="legacy" placement="auto" target="socialShareIcons">
                                            <PopoverBody>
                                                <div className="share-icon-social">
                                                    <SocialMediaButtons url={this.state.currenturl} />
                                                </div>
                                            </PopoverBody>
                                        </UncontrolledPopover>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 offset-md-9 col-xs-12">
                            <small className="ft-copy-rts">© Copyright 2023 BeWise. All Rights Reserved</small>
                        </div>
                    </div>
                    <div class="sticky-container" id="totop-sec">
                        <ul class="sticky p-0">
                            <li class="totop" onClick={() => this.moveTop()}>
                                <img src="/assets/images/back-to-top.png" />
                            </li>
                           
                        </ul>
                    </div>
                    <div class="sticky-container-chat hidden">
                        <ul class="sticky p-0">
                         {<li class="tochat desktoponly" onClick={() => this.setChat()}><img src={chatbot} />
                                
                            </li>}
                        </ul>
                    </div>
                    <ChatBotNew onChatClose={this.closeChat} />

                    <div class="qc-cmp-cleanslate css-11wtop9 hidden">

                    <div id="qc-cmp2-ui" role="dialog" aria-label="qc-cmp2-ui" aria-backdrop="static"  aria-modal="true" tabindex="0" class="css-xwo50m">
                        <span onClick={this.stopAd}>close</span>
                        {this.state.ads.length>0 && this.state.ads.map((ad, i) => {
                        return (
                            <div class="mySlides fade2">
                                <a href={ad.link} target="_blank"><img src={IMG_URL+"uploads/" + ad.adfilepath} /></a>
                            </div>
                            )
                        })}
                    </div>

                    </div>
                     
                    
                </footer>
                <div class="db ot o dx ux acc acd">
                        <div class="uz dz sr va vb o ao c">
                            
                            {/* <div class="o ao gy">
                                <div class="pw-multi-vote-count l od oe of og oh oi oj">
                                    <div>
                                        <div class="ci adbtn" onClick={this.showtoggleAd}>
                                            View Ad
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="vd ve vf l vadsdiv desktoponly"></div>
                            */}
                            
                            <div>
                                <div class="ci chatbtn desktoponly" onClick={() => this.setChatNoAnalytics()}>
                                        Chat with Us
                                </div>
                            </div>
                            
                            <div class="hidden">
                                <div class="ci feeedbackbtn" data-toggle="modal" data-target="#feedBackModal">
                                        Feedback
                                </div>
                            </div>
                        </div>
                    </div>
                <BottomMenu menuupdate={this.state.chatopen} />
                <div
                    className='modal fade review-modal'
                    id='postQueryModal'
                >
                    <div className='modal-dialog model-box modal-md' >
                        <form method='post'
                            onSubmit={this.handleQuery}
                            autoComplete='off'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h3 className='modal-title r-text'>
                                        Contact Us
                                    </h3>
                                    <button
                                        type='button'
                                        className='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                    >
                                        <span aria-hidden='true'>&times;</span>
                                    </button>
                                </div>
                                <div className='modal-body'>

                                    <div className="row contactus-form">
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className='login-label r-text'>
                                                    Name<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input className='form-control'
                                                    value={this.state.q_name}
                                                    name="q_name"
                                                    onChange={this.handleChange}
                                                    type='text' placeholder="Enter your name" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className='login-label r-text'>
                                                    Query Type<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <select name="q_type" onChange={this.handleChange} className="form-control contactus-formli">
                                                    <option value="">Select</option>
                                                    <option value="1">Have a query</option>
                                                    <option value="2">Have a suggestion</option>
                                                    <option value="3">Need a help</option>
                                                    <option value="4">Others</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className='login-label r-text'>
                                                    Email<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input className='form-control' type='text'
                                                    value={this.state.q_email_id}
                                                    name="q_email_id"
                                                    onChange={this.handleChange}
                                                    placeholder="Enter your email" />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className='login-label r-text'>
                                                    Phone Number
                                                </label>
                                                <input className='form-control' type='text'
                                                    value={this.state.q_phone_no}
                                                    name="q_phone_no"
                                                    onChange={this.handleChange}
                                                    placeholder="Enter your Phone Number " />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className='login-label r-text'>
                                                    Query From<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <select name="user_type" className="form-control contactus-formli"
                                                    onChange={this.handleChange}>
                                                    <option value="">Select</option>
                                                    <option value="1">Partner</option>
                                                    <option value="2">Parent</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className='login-label r-text'>
                                                    Subject<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input className='form-control' type='text'
                                                    value={this.state.q_subject}
                                                    name="q_subject"
                                                    onChange={this.handleChange}
                                                    placeholder="Enter subject" />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className='form-group'>
                                                <label className='r-text '>Comments<span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <textarea
                                                    className='form-control'
                                                    id='message-text'
                                                    name="q_comments"
                                                    value={this.state.q_comments}
                                                    onChange={this.handleChange}
                                                    rows='4'
                                                    cols='50'
                                                ></textarea>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className='modal-footer' style={{ border: 'none' }}>
                                    <button type='button' className='btn btn-default' data-dismiss='modal' aria-label='Close'>

                                        Cancel
                                    </button>
                                    <button type='submit' className='btn btn3 btn-primary' >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div
                    className='modal fade review-modal'
                    id='feedBackModal'
                >
                    <div className='modal-dialog model-box modal-md' >
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h3 className='modal-title r-text'>Feedback</h3>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <form method='post'
                                autoComplete='off'
                                onSubmit={this.handleQueryfeedback}>
                                <div className='modal-body'>
                                    <div className='rating-box '>
                                        <div className="row">
                                            <div className='col-md-12'>
                                                <div className='col'>
                                                    <p className='rating r-text'>
                                                        As a parent - do you think this product will help you ?
                                                    </p>
                                                </div>
                                                <div className='col'>
                                                    <Rating
                                                        name=""
                                                        starCount={5}
                                                        value={rating1}
                                                        onStarClick={this.onStarClick1feedback.bind(this)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='col'>
                                                    <p className='rating r-text'>
                                                        How easy was it to navigate ? Flow / Categories / Layout /
                                                    </p>
                                                </div>
                                                <div className='col'>
                                                    <Rating
                                                        name=""
                                                        value={rating}
                                                        onStarClick={this.onStarClickfeedback.bind(this)}
                                                        starCount={5}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        What other feature will help even more?                     </label>
                                                    <input
                                                        required
                                                        onChange={(e) => this.handleChangefeedback(e)}
                                                        name="feature" className='form-control'
                                                        type='text' />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        What more should we include to help your decision making ?                 </label>
                                                    <input
                                                        required
                                                        name="decision"
                                                        onChange={(e) => this.handleChangefeedback(e)}
                                                        className='form-control' type='text' />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        What other verticals / Needs we should add ?               </label>
                                                    <input
                                                        required
                                                        name="verticals" onChange={(e) => this.handleChangefeedback(e)}
                                                        className='form-control' type='text' />
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        What is the one suggestion you will give for founders of BeWise?            </label>
                                                    <input
                                                        required
                                                        name="suggession" onChange={(e) => this.handleChangefeedback(e)}
                                                        className='form-control' type='text'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='r-text '>What other topics in "review" will help you in making an even better decision?  </label>
                                                    <textarea
                                                        required
                                                        className='form-control'
                                                        id='message'
                                                        rows='4'
                                                        cols='50'
                                                        name="message"
                                                        onChange={(e) => this.handleChangefeedback(e)}

                                                    ></textarea>
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </div>
                                <div className='modal-footer' style={{ border: 'none' }}>
                                    <button type='button' className='btn  btn-default' data-dismiss='modal' aria-label='Close'>
                                        Cancel </button>
                                    <input
                                        type='submit'
                                        name='submit'
                                        value='Submit'
                                        className='btn btn-primary'
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="floatlefttray">
                    <a href="javascript:;" id="socialShareIcons" title="Share">
                        <i class="fa fa-share-alt fa-share-nodes"></i>
                    </a>
                </div>
            </React.Fragment >
        );
    }


    handleSubscribe = () => {

        if (this.state.email_id != '') {


            let subscribe = JSON.stringify({
                email_id: this.state.email_id,
                type: 2
            });

            let result = fetch(config.register + 'user/auth/subscribe', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                body: subscribe,
            });

            let toastr = '';
            toastr = window.toastr.success;
            toastr('', 'Thank You for your subscription! </br>(Check your SPAM folder too)', {
                positionClass: "toast-bottom-center",
                containerId: "toast-bottom-center"
            });

            this.setState({
                email_id: ""
            });

        } else {

            let toastr = '';
            toastr = window.toastr.error;

            toastr('', 'Enter Email Id', {
                positionClass: "toast-bottom-center",
                containerId: "toast-bottom-center"
            });

        }


    };
    handleQueryfeedback = (e) => {
        e.preventDefault();
        var emailtext = JSON.stringify({
            rating: this.state.rating,
            rating1: this.state.rating1,
            feature: this.state.feature,
            decision: this.state.decision,
            verticals: this.state.verticals,
            suggession: this.state.suggession,
            message: this.state.message
        });
        //console.log("email text",emailtext)
        let result = fetch(config.register + 'user/auth/sendfeedback', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: emailtext,
        });
        let toastr = '';
        toastr = window.toastr.success;

        toastr('', 'Thanks for your Feedback!..', {
            positionClass: "toast-bottom-center",
            containerId: "toast-bottom-center"
        });
        window.$('#feedBackModal').modal('hide');
        $('.modal-backdrop').removeClass("show");
        setTimeout(function () {
            window.location.reload();
        }, 1000);
    }

    handleQuery = (e) => {
        e.preventDefault();
        if (
            this.state.q_comments != '' && this.state.q_name != '' && this.state.q_type != '' &&
            this.state.q_subject != '') {
            let regData = JSON.stringify({
                user_id: this.state.user_id ? this.state.user_id : 0,
                q_type: this.state.q_type,
                name: this.state.q_name,
                email_id: this.state.q_email_id,
                subject: this.state.q_subject,
                phone_no: this.state.q_phone_no,
                comments: this.state.q_comments,
                user_type: this.state.user_type,
            });
            // let result = fetch(config.register + 'user/auth/contactus', {
            //   method: 'POST',
            //   mode: 'cors',
            //   headers: {
            //     'Content-Type': 'application/json; charset=UTF-8'
            //   },
            //   body: regData,
            // });
            postQuery(regData).then((response) => {
                let toastr = '';
                if (response.status == 1) {
                    $('#postQueryModal').hide('');
                    $('.modal-backdrop fade').removeClass('show');
                    this.setState({
                        name: '',
                        email_id: '',
                        subject: '',
                        phone_no: '',
                        comments: '',
                    })
                    toastr = window.toastr.success;
                    toastr('', 'Thanks for your query!..', {
                        positionClass: "toast-bottom-center",
                        containerId: "toast-bottom-center"
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                } else {
                    toastr = window.toastr.error; //warning
                    toastr('', 'Required!...', {
                        positionClass: "toast-bottom-center",
                        containerId: "toast-bottom-center"
                    });
                }
            });
        } else {
            let toastr = '';
            if (this.state.q_name == '') {
                toastr = window.toastr.error; //warning
                toastr('Error', 'Name Required!...', {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            } else if (this.state.q_type == '') {
                toastr = window.toastr.error; //warning
                toastr('Error', 'Query type Required!...', {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            } else if (this.state.q_email_id == '') {
                toastr = window.toastr.error; //warning
                toastr('Error', 'Email. Required!...', {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            } else if (this.state.q_subject == '') {
                toastr = window.toastr.error; //warning
                toastr('Error', 'Subject Required!...', {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            } else if (this.state.q_comments == '') {
                toastr = window.toastr.error; //warning
                toastr('Error', 'Comments Required!...', {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            }

        }
    };
    handleChangefeedback = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        //console.log(e.target.name,e.target.value)
    };
    onStarClickfeedback(nextValue, prevValue, name) {
        this.setState({ rating: nextValue });
    }
    onStarClick1feedback(nextValue, prevValue, name) {
        this.setState({ rating1: nextValue });
    }
}


export default NewFooter;
