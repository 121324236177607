import React, { Component } from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import { comments, getComments, editComments } from '../../API/FetchCall';
import { BASE_URL } from '../../constant';
// import Rating from 'react-star-rating-component';

class Comments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            comments: '',
            comments_shows: 0,
            user_id: '',
            commentsList: [],
            auth: false,
            showPop: false,
            currentComment: '',
            currentCommentId: ''
        };
    }

    componentDidMount() {
        //const { id } = this.props.paramId.id;
        // console.log('getPlayareaDetails', this.props.paramId.id);


        let auth = localStorage.getItem("logged_auth");
        let data_auth = (localStorage.getItem("myData"));
        let user_id = '';
        if (data_auth) {
            let auth_data = JSON.parse(data_auth)
            this.setState({
                logged_name: auth_data.first_name + ' ' + auth_data.last_name,
                authe: true,
                user_id: auth_data.user_id,
            });
            user_id = auth_data.user_id;
        }
        this.getComments(user_id);
    }
    getComments = (user_id) => {

        let reviewData = JSON.stringify({
            user_id: user_id,
            review_id: this.props.paramId.id,
            category_id: this.props.paramId.category_id
        });

        getComments(reviewData).then((response) => {
            let toastr = '';
            if (response.status == 1) {
                this.setState({
                    commentsList: response.results,
                })
            } else {
                // toastr = window.toastr.error; //warning
                // toastr('Error', 'Something went wrong. try again!...', {
                //     positionClass: "toast-bottom-center",
                //     containerId: "toast-bottom-center"
                // });
            }
        });
    }
    getCommentCurrent = (THIS, id) => {
        let list = this.state.commentsList;
        let result = list.filter((item) => item.cid == id);
        if (result.length > 0) {
            THIS.setState({
                showPop: true,
                currentComment: result[0].comments,
                currentCommentId: result[0].cid,
            });
        }
    }
    CommentsList() {
        console.log('commentsList', this.state.commentsList)
        let THIS = this;
        if (this.state.commentsList.length > 0) {
            // console.log('sasas')
            return this.state.commentsList.map((item, i) => {
                var date = new Date(item.created_date);
                return (
                    <li key={i}>
                        <div class="commenterImage">
                            <i class="fa fa fa-user"></i>
                        </div>
                        <div class="commentText">
                            <p class="">
                                {item.comments}&nbsp;
                                {item.is_edit == 1 &&
                                    (<i
                                        style={{ cursor: 'pointer' }}
                                        class="fa fa-pencil" aria-hidden="true"
                                        onClick={() => this.getCommentCurrent(THIS, item.cid)}></i>)}
                            </p>
                            <span class="date sub-text">{date.toDateString()}</span>
                        </div>
                    </li>
                )
            })
        } else {
            return (
                <li >
                    {/* <div class="commenterImage">
                        <img src="http://lorempixel.com/50/50/people/6" />
                    </div> */}
                    <div class="commentText text-center">
                        <p class="">No Comments....</p>
                    </div>
                </li>
            );
        }
    }

    handleClick = (e) => {
        // console.log(e.target.name)
        const propName = e.target.name;
        this.setState(prevState => ({
            [propName]: !prevState[propName]
        }));
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    closepop = (e) => {
        this.setState({
            showPop: false
        })
    }
    editCommentPost = (e) => {
        e.preventDefault();
        let insData = JSON.stringify({
            comments: this.state.currentComment,
            cid: this.state.currentCommentId,
            user_id: this.state.user_id,
        });
        let toastr = '';
        editComments(insData).then(res => {
            if (res.status === 1) {
                toastr = window.toastr.success;
                toastr('', 'Comment updated!..', {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
                this.setState({
                    showPop: false
                })
                this.getComments(this.state.user_id);
            } else {
                toastr = window.toastr.error; //warning
                toastr('', res.message, {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            }
        })
    }

    render() {
        console.log('probs-------------', this.props)
        return (
            <React.Fragment>
                <div className="row">
                    <div class="detailBox">
                        <div class="titleBox">
                            <label>Comments</label>
                        </div>
                        <div class="actionBox">
                            <ul class="commentList">
                                {this.CommentsList()}
                            </ul>
                        </div>
                    </div>
                </div>
                <Modal className="ipad-lg" isOpen={this.state.showPop} centered size='lg'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={this.closepop}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Edit Comment</h5>
                            </div>
                            <form method='post'
                                onSubmit={this.editCommentPost}>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <textarea className='form-control'
                                                rows='4'
                                                name='currentComment'
                                                onChange={this.handleChange} value={this.state.currentComment}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer mob-btn-row">
                                    <input
                                        type='submit'
                                        name='submit'
                                        value='Post'
                                        className='btn btn-primary'
                                    />
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}
export { Comments };
