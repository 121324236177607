import React from 'react'
// import styled from 'styled-components'
import { TwitterIcon, FacebookIcon, WhatsappIcon,LinkedinIcon, EmailIcon } from "react-share";

import TwitterNewIcon from './twitternew.png';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  EmailShareButton
} from 'react-share';

const href = window.location.href;

const SocialMediaButtons = (props) => (

  
  <div className="social-icons-container">
    <FacebookShareButton
      href={props.url}
      url={props.url}
      quote={props.text}>
      <FacebookIcon size={32} round={true}></FacebookIcon>
    </FacebookShareButton>
    <WhatsappShareButton
      url={props.url}
      subject={props.text}
      quote={props.text}>
      <WhatsappIcon size={32} round={true}></WhatsappIcon>
    </WhatsappShareButton>
  {/*
    <TwitterShareButton
      url={props.url}
      title={props.text}>
      <img src={TwitterNewIcon} width={32} />
</TwitterShareButton> */}
    <LinkedinShareButton
      url={props.url}
      title={props.text}>
      <LinkedinIcon size={32} round={true} />
    </LinkedinShareButton>
    <EmailShareButton
      subject="You may like to see"
      body={props.text} url={href}>
        <EmailIcon size={32} round={true}></EmailIcon>
    </EmailShareButton>
    
  </div>
)

export default SocialMediaButtons
