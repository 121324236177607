import React, { Component } from 'react';
import SuccessIcon from './../../assets/images/tick.png';
import ErrorIcon from './../../assets/images/error.png';

class OrderConfirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadable: true
        }
    }



    render() {
        return (

            <React.Fragment>
                <div className="container-fluid order-confrimation email-verification-page">

                    <div className="successs-message">
                        <div className="row">
                            <div class="jumbotron text-center col-md-12 mb-0">
                                <div className="content-center">
                                    <div className="icon-action">
                                        <img className="mt-1 w14 width-50" src={SuccessIcon} />
                                    </div>
                                    <h1 class="display-3">Email Verified!</h1>
                                    <p class="lead"> Your email address succesfully verified. </p>
                                    <p class="lead">
                                        <a class="btn btn btn-success" href="/" role="button">Continue to homepage</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="error-message">
                        <div className="row">
                            <div class="jumbotron text-center col-md-12 mb-0">
                                <div className="content-center">
                                    <img className="mt-1 w14 width-50" src={ErrorIcon} />
                                    <h1 class="display-3">Email verification failed!</h1>
                                    <p class="lead"> Your email address verification failed link already expired. </p>

                                    <p class="lead">
                                        <a class="btn btn-danger" href="/" role="button">Try Again</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}


export default OrderConfirmation;