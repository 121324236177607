import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import {CookiesProvider} from "react-cookie";

/* import * as Sentry from "@sentry/react";*/

/*
Sentry.init({
  dsn: "https://53c224f24903882301fd998c2b15ef1b@o4507100177039360.ingest.de.sentry.io/4507100441935952",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["bewise.in", /^https:\/\/api.bewise\.in\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
*/


ReactDOM.render(
  <CookiesProvider>
  <App />
  </CookiesProvider>,
  document.getElementById('root')
);

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate( <CookiesProvider>
    <App />
    </CookiesProvider>, rootElement);
} else {
  ReactDOM.render( <CookiesProvider>
    <App />
    </CookiesProvider>, rootElement);
}
