import React, { lazy, Suspense } from 'react';
import './App.css';
import './toast.css';
import './assets/css/custom.css';
import './assets/css/customV1.css';
import './assets/css/mobile.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { REDIRECT } from './constant';

import SummerCamp from './components/SeasonalCamp/summercamp';

import Blogs_article from './components/Page/Blogs_article';

import CommunitySupports from './components/Page/CommunitySupports';

import LongLoading from './components/NewHome/longLoading';

import EmailVerification from "./components/others/EmailVerification";

/*
import { Provider, ErrorBoundary } from '@rollbar/react' // Provider imports 'rollbar'

const rollbarConfig = {
  accessToken: '9b70d0f5c8ac4ca296905b85f8f52cad',
  environment: 'testenv',
}

function TestError() {
  const a = null
  return a.hello()
}
*/


function App() {

  return (

    <Router>

       
        <div className='App'>
          <Switch>
            {REDIRECT.map((item, i) => {
              return (
                <Route exact path={item.old_url}>
                  <Redirect to={item.new_url} />
                </Route>
              )

            })}

              
               <Suspense fallback={<div class="loader"></div>}>

                <Route exact path="/" component={lazy(() => import('./components/NewHome/home'))} />
                {/* <Route exact path="/homewithcamp" component={lazy(() => import('./components/NewHome/homecamp'))} />                

                <Route exact path="/ga4" component = {lazy(() => import('./components/ga4/test'))} />*/}

                {/*<Route exact path="/homev4" component={lazy(() => import('./components/NewHome/homeV4'))} />*/}
              
                <Route exact path="/herochange" component={lazy(() => import('./components/NewHome/homehero'))} />      

                { /* undefined and redirection and 404 pages */}

                <Switch>
                      <Route path='/schools/:var1/:var2' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                      <Route path='/undefined/:ver/:subver/:schoolname' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                      <Route path="/:city/schools/:subcategory/:schooname" exact component={lazy(() => import('./components/Page/verticalDetail/SingleSchoolPage'))} />
                </Switch>
                
                <Switch>
                      <Route path='/tuitions/:var/:var2' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                      <Route path='/undefined/tuitions/:var/:var2' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                      <Route path='/undefined/tuitions/maths' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/> 
                      <Route path="/:city/tuitions/:subcategory" exact component={lazy(() => import('./components/Page/TuitionPageV2'))}/>
                </Switch>

                <Switch>
                      <Route path='/undefined/tuitions/languages/french' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/> 
                      <Route path='/undefined/tuitions/languages/hindi' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                      <Route path="/:city/tuitions/languages/:category" exact component={lazy(() => import('./components/Page/TuitionLanguagePage'))}/>
                </Switch> 

                <Switch>
                      <Route path='/undefined/schools/:subver' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                      <Route path='/undefined/schools/international-schools' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                      <Route path='/undefined/schools/school-special-needs' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                      <Route path="/:city/schoolstest/:subcategory" exact component={lazy(() => import('./components/Page/school_page'))}/>
                      <Route path="/:city/schools/:subcategory" exact component={lazy(() => import('./components/Page/school_page'))}/>
                </Switch>

                

                <Route path='/community-supports' exact component={lazy(() => import('./components/Community-Support/index'))}/>

                { /* vertical landing pages and its sub*/}
                
                
                <Route path="/:city/pre-schools/:subcategory" exact component={lazy(() => import('./components/Page/PreSchoolPage'))}  />
                <Route path="/:city/pre-schools/:subcategory/:preschooname" exact component={lazy(() => import('./components/Page/PreSchoolDetailsV1'))} />
                
                
                <Route path="/:city/tuitions/languages/:category/:name" exact  component={lazy(() => import('./components/Page/TuitionSecondSinglePageV1'))}/>
                <Route path="/:city/tuitions/:subcategory/:name" exact component={lazy(() => import('./components/Page/TuitionSinglePageV1'))}/>
                <Route path="/:city/kids-play-area/:subcategory" exact  component={lazy(() => import('./components/Page/KidsPlayAreaV2'))}/>
                <Route path="/:city/kids-play-area/:subcategory/:name" exact component={lazy(() => import('./components/Page/KidsPlayAreaSinglePageV1'))}/>
                <Route path="/:city/birthday-event/:subcategory" exact component={lazy(() => import('./components/Page/BirthDayEventsV1'))}/>
                <Route path="/:city/birthday-event/:subcategory/:name" exact  component={lazy(() => import('./components/Page/BirthDayEventsSinglePageV1'))}/>
                
                <Switch>
                  <Route exact path="/:city/community-support/growth-partnerships-for-you"  component={lazy(() => import('./components/Page/ExclusivePartnerShips'))}/>
                  <Route exact path="/:city/community-support/articles-blogs" component={Blogs_article} />
                  <Route exact path="/:city/community-support/:subcategory" component={lazy(() => import('./components/Page/CommunitySupportsV1'))} />
                </Switch>


                <Switch>
                      <Route path="/learning-camps/:subcategory" exact  component={lazy(() => import('./components/Page/LearningCampsGeneral'))} />
                      <Route path="/:city/learning-camps/:subcategory" exact component={lazy(() => import('./components/Page/LearningCampsV1'))}/>
                      <Route path="/:city/learning-camps/:subcategory/:name" exact component={lazy(() => import('./components/Page/LearningCampsSinglePageV1'))}/>

                </Switch>

                <Route path="/:city/community-support/:subcategory/:name" exact component={lazy(() => import('./components/Page/CommunitySupportsSinglePageV1'))} />
                <Route path="/:city/after-school-activity/:subcategory?fbclid=:fbclid" exact component={lazy(() => import('./components/Page/AfterSchoolActivities'))}/>
                <Route path="/:city/after-school-activity/:subcategory" exact component={lazy(() => import('./components/Page/AfterSchoolActivities'))}/>
                <Route path="/:city/after-school-activity/yoga/:name" exact component={lazy(() => import('./components/Page/AfterSchoolActivitiesSinglePageYoga'))}/>
                <Route path="/:city/after-school-activity/:subcategory/:subcategory2" exact component={lazy(() => import('./components/Page/AfterSchoolActivitiesCategoriesV1'))}/>
                
                            
                <Switch>

                { /* https://www.bewise.in/undefined/after-school-activity/sports/tennis/peter-tennis-academy */ }
                      <Route path='/undefined/after-school-activity/:maincat/:subcat/:name' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                      <Route path="/:city/after-school-activity/:subcategory/:subcategory2/:name" exact component={lazy(() => import('./components/Page/AfterSchoolActivitiesSinglePageV1'))}/>
                </Switch>


                <Switch>
                <Route path='/login' exact component={lazy(() => import('./components/NewHome/newhome'))}  />
                <Route path='/signup' exact component={lazy(() => import('./components/Home/landing'))} />
                <Route path='/newsletter' exact component={lazy(() => import('./components/Banner/subscription'))}  />
                <Route path='/unsubscribe/:token' component={lazy(() => import('./layouts/unsubscribe'))}  />
                <Route path='/advancedsearch' component={lazy(() => import('./components/Search/AdvancedSearch'))}/>
                
                <Route path='/about' exact component={lazy(() => import('./components/others/aboutV1'))}/>
                <Route path='/faqs' exact  component={lazy(() => import('./components/others/faqs'))}/>
                <Route path='/funlearning' exact component={lazy(() => import('./components/others/funLearning'))}/>
                <Route path='/wishlist' exact  component={lazy(() => import('./components/others/wishlist'))}/>
                <Route path='/wishlistv1' exact  component={lazy(() => import('./components/others/wishlistV1'))}/>
                <Route path='/privacypolicy' exact  component={lazy(() => import('./components/others/PrivacyPolicy'))}/>
                <Route path='/termsconditions' exact  component={lazy(() => import('./components/others/TermsConditions'))}/>

                <Route path='/articles-blogs/:slug' exact component={lazy(() => import('./components/NewHome/articleandblogs'))}/>
                <Route path='/exclusive-partnerships/:slug' exact component={lazy(() => import('./components/NewHome/exclusivepartnershipdemoview'))}/>

                <Route path='/partners/:uuid/exclusive-partnerships/:slug' exact component={lazy(() => import('./components/NewHome/exclusivepartnershipviewpartner'))}/>

                <Route path='/promotes/:slug' exact component={lazy(() => import('./components/NewHome/specialpromotionview'))}/>

                <Route path='/signup_parent' exact component={lazy(() => import('./layouts/signup_parent'))}/>
                <Route path='/signin' exact component={lazy(() => import('./layouts/signIn'))}/>
                <Route path='/edit_profile' exact  component={lazy(() => import('./layouts/edit_profile'))}/>
                <Route path='/forgot' exact component={lazy(() => import('./layouts/forgetPassword'))}/>
                <Route path="/forgot/:ref/:token" exact component={lazy(() => import('./layouts/forgetPassword'))}/>
                <Route path="/forgot/:ref/:token/new" exact component={lazy(() => import('./layouts/forgetPassword'))}/>
                <Route path="/compare" exact component={lazy(() => import('./components/Compare/comparev2'))}/>
                <Route path="/search/:search" exact component={lazy(() => import('./components/Search/indexV1'))}/>
                <Route path="/compare-list" exact component={lazy(() => import('./components/Compare/compare_list'))} />
                <Route path="/compare2" exact component={lazy(() => import('./components/Compare/comparev2'))}/>

                <Route path='/terms-and-conditions' exact component={lazy(() => import('./components/others/TermsConditions'))}/>
                <Route path='/privacy-policy' exact  component={lazy(() => import('./components/others/PrivacyPolicy'))}/>
                <Route path='/art-of-storytelling' exact  component={lazy(() => import('./components/Bewise-Events/child_development'))}/>
                <Route path='/events/:slug' exact component={lazy(() => import('./components/Bewise-Events/event_module'))}/>
                <Route path='/contact-us' exact component={lazy(() => import('./components/CMS/contact_us'))}/>
                <Route path='/sign-up-parent' exact  component={lazy(() => import('./layouts/signup_parent'))}/>
                <Route path='/sign-in' exact  component={lazy(() => import('./layouts/signIn'))}/>

               
                <Route path='/best-tuitions-near-me' exact component={lazy(() => import('./components/Leads/leadForm'))} />

                <Route path='/best-schools-near-me' exact component={lazy(() => import('./components/Leads/leadForm_school'))}/>
                
                <Route path='/best-preschools-near-me' exact component={lazy(() => import('./components/Leads/leadForm_preschool'))}/>

                <Route path='/best-sports-classes-near-me' exact  component={lazy(() => import('./components/Leads/LeadFormSports'))}/>
                <Route path='/best-music-classes-near-me' exact  component={lazy(() => import('./components/Leads/LeadFormMusic'))}/>
                <Route path='/best-dance-classes-near-me' exact  component={lazy(() => import('./components/Leads/LeadFormDance'))}/>
                <Route path='/best-arts-classes-near-me' exact component={lazy(() => import('./components/Leads/LeadFormArts'))}/> 
                <Route path='/best-skill-enrichment-classes-near-me' exact  component={lazy(() => import('./components/Leads/LeadFormMind'))}/> 
                <Route path='/best-hobby-passion-enriching-classes-near-me' exact component={lazy(() => import('./components/Leads/LeadFormHobbies'))}/>

                <Route path='/learningcamps/:name' exact component={SummerCamp} />

                <Route path='/partnerotpverification' exact component={lazy(() => import('./layouts/partnerOtpVerify'))} />
                <Route path='/customerotpverification' exact component={lazy(() => import('./layouts/customerOtpVerify'))} />
                
                <Route path='/email-verification' exact component={EmailVerification} />

                <Route path='/:somerandom' exact component={lazy(() => import('./components/NotFound/notfound'))}  status={404}/>
                { /*  component={lazy(() => import('./components/NotFound/notfound'))}  status={404} */ }
                
          
                </Switch>
                
                </Suspense>

          </Switch>
        </div>
          { /*  
        <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <TestError />
      </ErrorBoundary>
    </Provider> */ }
      
    </Router>
  );
}
export default App;
