import React, { Component } from 'react';
import { getTrending, getinsightcontent, getBannersListing } from '../../API/FetchCall';
import { BASE_URL, IMG_URL } from '../../constant';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
//import fs from 'fs';
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class TopTrending extends React.Component {
    constructor(props) {
        super(props);
        console.log('this.props', window.location.pathname)
        this.state = {
            user_id: '',
            similarList: [],
            contentList: [],
            mobilebannerlisting:[],
            auth: false,
            currentTitle: '',
            currentDetail: '',
            location: localStorage.getItem('current_location') ? localStorage.getItem('current_location') : 'Bengaluru',
            showPop: false,
            trend_type: 2,
            isCurated: this.props.param.isCurated ? this.props.param.isCurated : false,
            trend_name: 'Top Visits'
        };
    }

    componentDidMount() {
        let THIS = this;
        let auth = localStorage.getItem("logged_auth");
        let data_auth = (localStorage.getItem("myData"));
        if (data_auth) {
            let auth_data = JSON.parse(data_auth)
            this.setState({
                logged_name: auth_data.first_name + ' ' + auth_data.last_name,
                authe: true,
                user_id: auth_data.user_id,
            });
        }
        this.SResult();
        
        this.getBannersListing();
        window.jQuery(document).on('click', '.more-pop', function () {
            let content = THIS.state.contentList;
            let filterData = content.filter((item) => item.id == window.jQuery(this).attr('id'));
            console.log(filterData);
            let data = filterData[0];
            THIS.setState({
                currentDetail: data.detail_content,
                currentTitle: data.title,
                showPop: true
            });
        });
    }
    handleTrend = (type, name) => {
        this.setState({
            trend_type: type,
            trend_name: name
        })
        this.SResult(type);
    }
    SResult = async (type) => {
        try {

            let req = JSON.stringify({
                category: this.props.param.category_id,
                sub_category: this.props.param.sub_category,
                category_name: this.props.param.category_name,
                subcategory_term: this.props.param.subcategory_term,
                subcategory_term2: this.props.param.subcategory2,
                city: this.props.param.city,
                location: this.state.location,
                type: type ? type : this.state.trend_type,
                url: this.state.isCurated ? 'topTrendsCurated' : 'topTrends'
            })
            const res = await getTrending(req);

            if (res.status == 1) {
                this.setState({
                    similarList: res.result,
                })
            }

        } catch (error) {
            this.setState({ error: true });
        }
    };
    topics = async () => {
        try {
            let req = JSON.stringify({
                role: 4,
                category: this.props.param.category_id
            })
            const response = await getinsightcontent(req);
            if (response.status === 1) {
                this.setState({ contentList: response.list });
            } else {
                this.setState({ contentList: [] });
            }

        } catch (error) {
            this.setState({ error: true });
        }
    };
    getBannersListing = async () => {

        getBannersListing().then((response) => {

          this.setState({
            mobilebannerlisting: response.data
          });

        });

    };

    CheckImage(path) {
        axios
          .get(path)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
    }

    readMore(id) {
        let content = this.state.contentList;
        let filterData = content.filter((item) => item.id == id);
        console.log(filterData);
        let data = filterData[0];
        this.setState({
            currentDetail: data.detail_content,
            currentTitle: data.title,
            showPop: true
        })
    }
    closepop = (e) => {
        this.setState({ showPop: false });
    };
    render() {

        var leadad = '';
        var leadlink = '';

        if(window.location.pathname.indexOf("/schools/") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/School.png';
               leadlink = '/best-schools-near-me';
        }

        if(window.location.pathname.indexOf("/pre-schools/") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/Pre School.png';
               leadlink = '/best-preschools-near-me';
        }

        if(window.location.pathname.indexOf("/tuitions/") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/Tuitions.png';
               leadlink = '/best-tuitions-near-me';
        }


        if(window.location.pathname.indexOf("/after-school-activity/sports/") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/Sports.png';
               leadlink = '/best-sports-classes-near-me';
        }

        if(window.location.pathname.indexOf("/after-school-activity/sports") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/Sports.png';
               leadlink = '/best-sports-classes-near-me';
        }

        if(window.location.pathname.indexOf("after-school-activity/music") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/Music Classes.png';
               leadlink = '/best-music-classes-near-me';
        }


        if(window.location.pathname.indexOf("/after-school-activity/dance-and-theatre") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/Dance.png';
               leadlink = '/best-dance-classes-near-me';
        }


        if(window.location.pathname.indexOf("after-school-activity/creative-arts") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/Art.png';
               leadlink = '/best-arts-classes-near-me';
        }


        if(window.location.pathname.indexOf("/after-school-activity/mind-and-skill-enrichment") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/Enrichment.png';
               leadlink = '/best-skill-enrichment-classes-near-me';
        }


        if(window.location.pathname.indexOf("/after-school-activity/hobbies-and-passion") >= 0){
               leadad = 'https://api.bewise.in/uploads/listing/Hobby classes.png';
               leadlink = '/best-hobby-passion-enriching-classes-near-me';
        }

        const settingsMobile = {
            dots: false,
            infinite: false,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000
        };

        //const fs = require('fs'); 

        return (
            <React.Fragment>
                <div>
                    { /* leadad!='' && 
                    <div className="left-side-box listingad">
                            
                                <a href={leadlink}><img src={leadad} /></a>
                            
                    </div>
                    */ }

                    {/* related Topics start */}
                    {!window.location.pathname.includes('search') &&
                        <div className="left-side-box">
                            <div className="left-head trend-head" id="toptrending_section">
                                Trending Now - {this.state.trend_name}
                            </div>
                            <div className="left-side-body">
                                {!this.state.isCurated && (
                                    <div className='col-12 trending-custom p-0'>
                                        <span onClick={() => this.handleTrend(2, 'Top Visits')}
                                            class={this.state.trend_type == 2 ? `badge badge-light active` : 'badge badge-light'}>
                                            Top Visits
                                        </span>
                                        &nbsp;<span>|</span>&nbsp;
                                        <span onClick={() => this.handleTrend(3, 'Top Followers')}
                                            class={this.state.trend_type == 3 ? `badge badge-light active` : 'badge badge-light'}>
                                            Top Followers
                                        </span>
                                        &nbsp;<span>|</span>&nbsp;
                                        <span onClick={() => this.handleTrend(5, 'Top Likes')}
                                            class={this.state.trend_type == 5 ? `badge badge-light active` : 'badge badge-light'}>
                                            Top Likes
                                        </span>
                                    </div>
                                )}

                                {this.state.similarList.map((item, i) => {
                                     
                                     let thumbImage = item.image;
                                    /*
                                    const checkImage = item =>
                                    new Promise(resolve => {
                                        const img = new Image();
                                        let thumbImage = item.image;
                                        img.onload = () => resolve({thumbImage, status: 'ok'});
                                        img.onerror = () => resolve({thumbImage, status: 'error'});
                                        img.src = thumbImage;
                                        img.height = '43';
                                        img.width = '43';
                                        img.alt = 'Trending - BeWise';
                                    });
                                     */
                                    

                                   
                                    if (item.name)
                                        return (
                                            <div className='sim-school'>
                                                <div className='p8n5f'>
                                                    <img
                                                        src={this.CheckImage(thumbImage) ? thumbImage : '/assets/images/school-default.png'}
                                                        width='43'
                                                        height='43'
                                                        alt='img'
                                                    /> 
                                                     
                                                </div>
                                                <div className='c8n5dg'>
                                                    <a href={item.link}>
                                                        <span className="text-overflow-similar">{item.name}</span>
                                                    </a>
                                                    <span className='text-overflow-similar m8vql9'>{!this.state.isCurated ? item.location : (<>&nbsp;</>)}</span>
                                                </div>
                                            </div>
                                        );
                                })}

                            </div>
                        </div>
                    }

                    <div className="left-side-box mobileonly">
                        <Slider {...settingsMobile}>
                            {this.state.mobilebannerlisting?.map((item, i) => {
                                return (
                                    <div className="">
                                        <a href={item.link}>
                                            <div className="text-center ">
                                                <div className="landing-right">
                                                    <img src={IMG_URL+"uploads/"+item.adfilepath} alt='banner' class="rsadwidth100"/>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                        </Slider>    

                    </div>

                </div>
                <Modal className="ipad-lg" isOpen={this.state.showPop} centered size='lg'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={this.closepop}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle"> {this.state.currentTitle}</h5>
                            </div>
                            <div className="modal-body popup-insight-scroll" dangerouslySetInnerHTML={{ __html: this.state.currentDetail }}>

                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}
export { TopTrending };
