import React, { Component } from 'react';
import { AddWistData } from '../../API/FetchCall';
import { BASE_URL } from '../../constant';

class AddWishlist extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: '',
            similarList: [],
            auth: false,
        };
    }

    componentDidMount() {

        let auth = localStorage.getItem("logged_auth");
        let data_auth = (localStorage.getItem("myData"));
        if (data_auth) {
            let auth_data = JSON.parse(data_auth)
            this.setState({
                logged_name: auth_data.first_name + ' ' + auth_data.last_name,
                authe: true,
                user_id: auth_data.user_id,
            });
        }
    }
    AddWist(ids) {
        let reviewData = JSON.stringify({
            menuid: this.props.paramId.category_id,
            category: this.props.paramId.category,
            productid: this.props.paramId.id,
            rating: '4.1*',
            userid: this.state.user_id,
            vendor_id: this.props.paramId.vendor_id
        });

        AddWistData(reviewData).then((response) => {
            let toastr = '';
            if (response.status == 1) {
                toastr = window.toastr.success; //warning
                toastr('', response.message, {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            }
        });

    }


    render() {
        return (
            <React.Fragment>
                <div>
                    <div className="banner-btn" onClick={() => this.AddWist(this.state.user_id)}>
                        <div className="btn-icon">
                            <img src={BASE_URL + 'assets/icons/wishlist.png'} alt='img' />
                        </div>
                        <div className="text-btn" onclick="ga(‘send’, ‘event’, ’Preschools’, 'Add to Wistlist',Add to Wistlist , ’start’, {‘NonInteraction’: 1});">
                            Add to Wishlist
                      </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
export { AddWishlist };
