import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../../src/assets/js/menuCustom.js';
import { BASE_URL } from '../constant';
import ReactTooltip from "react-tooltip";
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import $ from 'jquery';

class Mainmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_city: localStorage.getItem("current_location") ? localStorage.getItem("current_location").toLowerCase() : 'bengaluru',
      menu_data: {},
      third_menu: '',
      show_third_menu: false,
      mobilemenu_toggle: false,
      screen_size: window.innerWidth
    };
    this.mbMenuToogle = this.mbMenuToogle.bind(this);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
  }
  componentDidUpdate() {
    $(function () {
      $('#main-menu, .new-main-menu-ul').smartmenus({
        subMenusSubOffsetX: 1,
        subMenusSubOffsetY: 5
      });
    });
  }
  mbMenuToogle(foo) {
    this.setState({
      mobilemenu_toggle: !this.state.mobilemenu_toggle
    });
    if (foo)
      setTimeout(() => {
        this.inputRef.current.click();
      }, 100);

  }

  componentWillReceiveProps(nextProps) {
    console.log('test props', nextProps)
    if (nextProps.menu_data) {
      this.setState({
        menu_data: nextProps.menu_data
      })
    }
  }

  render() {
    const { menu_data, screen_size, third_menu } = this.state;
    let isMobile = false;
    if (screen_size <= 767) {
      isMobile = true;
    }
    return (
      <React.Fragment>
        {isMobile == false && (
          <nav id="main-nav" className="navbar navbar-expand-lg navbar-light bg-light new-main-menu">
            <div className='row align-items-center'>
              <div className='col-9'>
                <span className="menu-title">
                  {menu_data.category &&
                    (
                      <p className="sortby-text mob-land-breadcrumbs">
                        <span className='mb4c'>
                          <a style={{ 'cursor': 'pointer' }} href='/'>Home</a>&nbsp;<i class="fa fa-angle-right"></i>&nbsp;
                          <a style={{ 'cursor': 'pointer' }} href={`${menu_data.category_link}`}>{menu_data.category}</a>&nbsp;<i class="fa fa-angle-right"></i>&nbsp;
                          {menu_data.subcategory_two ?
                            (
                              <React.Fragment>
                                <a style={{ 'cursor': 'pointer' }} href={`${menu_data.subcategory_one_link}`}>{menu_data.subcategory_one}</a>&nbsp;<i class="fa fa-angle-right"></i>&nbsp;
                              </React.Fragment>
                            ) :
                            (
                              <React.Fragment>
                                <span>{menu_data.subcategory_one}</span> &nbsp;
                              </React.Fragment>
                            )}
                          {menu_data.subcategory_one_desc && (
                            <React.Fragment><i class="fa fa-info-circle" data-tip data-for="catetipM"></i>
                              <ReactTooltip id="catetipM" place="bottom" effect="solid" className=''>
                                <p style={{ textAlign: "left" }}>{menu_data.subcategory_one_desc}</p>
                              </ReactTooltip>
                            </React.Fragment>)}
                          {menu_data.subcategory_two &&
                            (
                              <React.Fragment>
                                <span>{menu_data.subcategory_two}</span> &nbsp;
                              </React.Fragment>
                            )}
                        </span>
                      </p>
                    )}

                </span>
              </div>
              <div className='col-3 mb-1'>
                {!isMobile ? (
                  <button className="navbar-toggler pull-right mob-nav-toggle" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                ) :
                  (
                    <React.Fragment>
                      <button className="navbar-toggler pull-right mob-nav-toggle" type="button" onClick={() => this.mbMenuToogle(true)} id="Popover-mob1">
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div style={{ display: 'none' }}>
                        <button ref={this.inputRef} className="navbar-toggler pull-right mob-nav-toggle" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                          <span className="navbar-toggler-icon"></span>
                        </button>
                      </div>
                      <Popover className='filter-pop-over' placement="left" isOpen={this.state.mobilemenu_toggle} target="Popover-mob1">
                        <PopoverBody className>
                          <nav id="main-nav" className="navbar navbar-expand-lg navbar-light bg-light new-main-menu">
                            <div className='col-md-12'>
                              <i class="fa fa-times pull-right" onClick={() => this.mbMenuToogle(false)} aria-hidden="true"></i>
                            </div>
                            {this.mainMenuList(isMobile)}
                          </nav>
                        </PopoverBody>
                      </Popover>
                    </React.Fragment>
                  )}

              </div>
            </div>
            {!isMobile && this.mainMenuList(isMobile)}
          </nav>
        )}


      </React.Fragment>
    );
  }
  mainMenuList = (isMobile) => {
    const { menu_data } = this.state;
    return (
      <div className="collapse navbar-collapse mobile-show-menu" id="navbarNavDropdown">
        <ul id="main-menu" className={'sm sm-blue mainmenu-contain container new-main-menu-ul'}>
          <li
            className="menu-custom"
          >
            <a className={menu_data.active_main == 'pre-schools' && 'active'}
              href={isMobile ? 'javascript:;' : '/' + this.state.current_city + '/pre-schools/kindergarten'}>Pre-Schools</a>
            <ul className='dropdown-menu-main'>
              <li>
                <a className={menu_data.active_menu == 'montessori' && 'active'}
                  href={'/' + this.state.current_city + '/pre-schools/montessori'}>Montessori</a>
              </li>
              <li>
                <a className={menu_data.active_menu == 'kindergarten' && 'active'}
                  href={'/' + this.state.current_city + '/pre-schools/kindergarten'}>Kindergarten</a>
              </li>
              <li>
                <a className={menu_data.active_menu == 'play-group' && 'active'}
                  href={'/' + this.state.current_city + '/pre-schools/play-group'}>Play Group</a>
              </li>
              <li>
                <a className={menu_data.active_menu == 'special-needs' && 'active'}
                  href={'/' + this.state.current_city + '/pre-schools/special-needs'}>Special Needs</a>
              </li>
              <li>
                <a className={menu_data.active_menu == 'day-care-or-creche' && 'active'}
                  href={'/' + this.state.current_city + '/pre-schools/day-care-or-creche'}>Day care or Creche</a>
              </li>
            </ul>
          </li>

          <li className='menu-custom'>
            <a className={menu_data.active_main == 'schools' && 'active'}
              href={isMobile ? 'javascript:;' : '/' + this.state.current_city + '/schools/private-schools'}>
              Schools
            </a>
            <ul className='dropdown-menu-main'>
              <li><a className={menu_data.active_menu == 'private-schools' && 'active'}
                href={'/' + this.state.current_city + '/schools/private-schools'}>Private School</a></li>
              <li><a className={menu_data.active_menu == 'public-schools' && 'active'}
                href={'/' + this.state.current_city + '/schools/public-schools'}>Public School</a></li>
              <li><a className={menu_data.active_menu == 'boarding-schools' && 'active'}
                href={'/' + this.state.current_city + '/schools/boarding-schools'}>Boarding School</a></li>
              <li><a className={menu_data.active_menu == 'international-schools' && 'active'}
                href={'/' + this.state.current_city + '/schools/international-schools'}>International School</a></li>
              <li><a className={menu_data.active_menu == 'school-special-needs' && 'active'}
                href={'/' + this.state.current_city + '/schools/school-special-needs'}>Special Needs</a></li>
              <li><a className={menu_data.active_menu == 'alternative-schools' && 'active'}
                href={'/' + this.state.current_city + '/schools/alternative-schools'}>Alternative School</a></li>
              <li><a className={menu_data.active_menu == 'open-schools' && 'active'}
                href={'/' + this.state.current_city + '/schools/open-schools'}>Open School</a></li>
              <li><a className={menu_data.active_menu == 'e-schools' && 'active'}
                href={'/' + this.state.current_city + '/schools/e-schools'}>E-School</a></li>

            </ul>
          </li>


          <li
            className={'menu-custom'}
          >
            <a className={menu_data.active_main == 'kids-play-area' && 'active'}
              href={isMobile ? 'javascript:;' : '/' + this.state.current_city + '/kids-play-area/indoor'}>Kids Play Areas
            </a>
            <ul className='dropdown-menu-main'>
              <li>
                <a className={menu_data.active_menu == 'indoor' && 'active'}
                  href={'/' + this.state.current_city + '/kids-play-area/indoor'}>Indoor</a>
              </li>
              <li>
                <a className={menu_data.active_menu == 'outdoor' && 'active'}
                  href={'/' + this.state.current_city + '/kids-play-area/outdoor'}>Outdoor</a>
              </li>

            </ul>
          </li>

          <li
            className={'menu-custom'}
          >
            <a className={menu_data.active_main == 'after-school-activity' && 'active'}
              href={isMobile ? 'javascript:;' : '/' + this.state.current_city + '/after-school-activity/sports'}
            >
              After School Activity
            </a>
            <ul className='dropdown-menu-main'>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'sports' && 'active'}
                  href={'/' + this.state.current_city + '/after-school-activity/sports'}>
                  Sports
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'music' && 'active'}
                  href={'/' + this.state.current_city + '/after-school-activity/music'}>
                  Music
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'dance-and-theatre' && 'active'}
                  href={'/' + this.state.current_city + '/after-school-activity/dance-and-theatre'}>
                  Dance and Theatre
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'creative-arts' && 'active'}
                  href={'/' + this.state.current_city + '/after-school-activity/creative-arts'}>
                  Creative arts
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'mind-and-skill-enrichment' && 'active'}
                  href={'/' + this.state.current_city + '/after-school-activity/mind-and-skill-enrichment'}>
                  Mind & Skills Enrichment
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'hobbies-and-passion' && 'active'}
                  href={'/' + this.state.current_city + '/after-school-activity/hobbies-and-passion'}>
                  Hobbies & Passion
                </a>
              </li>
            </ul>
          </li>

          <li className={'menu-custom'} >
            <a className={menu_data.active_main == 'tuitions' && 'active'}
              href={isMobile ? 'javascript:;' : '/' + this.state.current_city + '/tuitions/maths'}
            >Tuitions
            </a>
            <ul className='dropdown-menu-main'>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'maths' && 'active'}
                  href={'/' + this.state.current_city + '/tuitions/maths'}>
                  Maths
                </a>
              </li>

              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'science' && 'active'}
                  href={'/' + this.state.current_city + '/tuitions/science'}>
                  Science/ EVS
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'economics' && 'active'}
                  href={'/' + this.state.current_city + '/tuitions/economics'}>
                  Economics
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'business-studies' && 'active'}
                  href={'/' + this.state.current_city + '/tuitions/business-studies'}>
                  Business Studies
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'languages' && 'active'}
                  href={'/' + this.state.current_city + '/tuitions/languages'}>
                  Languages
                </a>
              </li>

            </ul>
          </li>
          <li
            className={'menu-custom'}
          >
            <a className={menu_data.active_main == 'learning-camps' && 'active'}
              href={isMobile ? 'javascript:;' : '/'+this.state.current_city +'/learning-camps/below-4-years'}
            >Learning Camps
            </a>
            <ul className='dropdown-menu-main'>
              
              <li>
                <a className={menu_data.active_menu == 'below-4-years' && 'active'}
                  href={'/' + this.state.current_city + '/learning-camps/below-4-years'}>Below 4 Years</a>
              </li>
              <li>
                <a className={menu_data.active_menu == '5-7-years' && 'active'}
                  href={'/' + this.state.current_city + '/learning-camps/5-7-years'}>5 - 7 Years</a>
              </li>
              <li>
                <a className={menu_data.active_menu == '8-10-years' && 'active'}
                  href={'/' + this.state.current_city + '/learning-camps/8-10-years'}>8 - 10 Years</a>
              </li>
              <li>
                <a className={menu_data.active_menu == '11-12-years' && 'active'}
                  href={'/' + this.state.current_city + '/learning-camps/11-12-years'}>11 - 12 Years</a>
              </li>
              <li>
                <a className={menu_data.active_menu == '13-15-years' && 'active'}
                  href={'/' + this.state.current_city + '/learning-camps/13-15-years'}>13 - 15 Years</a>
              </li>
             
              { /* <li>
                <a className={menu_data.active_menu == 'curated-learning-camps' && 'active'}
                  href={'/learning-camps/curated-learning-camps'}>BeWise Curated Learning Camps</a>
              </li> */ }
              { /*
               <li>
                <a className={menu_data.active_menu == '/bewise-summer-camps' && 'active'}
                  href={'/learning-camps//bewise-summer-camps'}>BeWise Summer Camps</a>
              </li>
              */ }

            </ul>
          </li>


          <li
            className={'menu-custom'}
          >
            <a className={menu_data.active_main == 'community-support' && 'active'}
              href={isMobile ? 'javascript:;' : '/' + this.state.current_city + '/community-support/counselor'}
            >Community Support
            </a>
            <ul className='dropdown-menu-main'>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'counselor' && 'active'}
                  href={'/' + this.state.current_city + '/community-support/counselor'}>
                  Counselor
                </a>

              </li>
              <li>
                <a className={menu_data.active_menu == 'articles-blogs' && 'active'}
                  href={'/' + this.state.current_city + '/community-support/articles-blogs'}>Articles and Blogs</a>
              </li>
              <li>
                <a className={menu_data.active_menu == 'growth-partnerships-for-you' && 'active'}
                  href={'/' + this.state.current_city + '/community-support/growth-partnerships-for-you'}>Growth Partnerships </a>
              </li>
            </ul>
          </li>

          <li className={'menu-custom'} >
            <a className={menu_data.active_main == 'birthday-event' && 'active'}
              href={isMobile ? 'javascript:;' : '/' + this.state.current_city + '/birthday-event/venues'}
            >Birthdays & Events
            </a>
            <ul className='dropdown-menu-main'>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'venues' && 'active'}
                  href={'/' + this.state.current_city + '/birthday-event/venues'}>
                  Venues
                </a>

              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'bakery-or-cake-shops' && 'active'}
                  href={'/' + this.state.current_city + '/birthday-event/bakery-or-cake-shops'}>
                  Bakery or Cake shops
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'entertainers-or-mcs' && 'active'}
                  href={'/' + this.state.current_city + '/birthday-event/entertainers-or-mcs'}>
                  Entertainers or MCs
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'party-planners' && 'active'}
                  href={'/' + this.state.current_city + '/birthday-event/party-planners'}>
                  Party Planners
                </a>
              </li>
              <li className='dropdown-submenu'>
                <a className={menu_data.active_menu == 'gift-and-supportives' && 'active'}
                  href={'/' + this.state.current_city + '/birthday-event/gift-and-supportives'}>
                  Gifts & Supportives
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    )
  }
}

export { Mainmenu };

