import React, { Component } from 'react';


import loadingsvg from '../../../src/assets/images/loading.gif';

import logo from '../../../src/assets/images/bewiselogo.png';

class longLoading extends Component {

  constructor(props) {
    super(props);
    this.state = {
     
    }

  }


  render() {


      return (
        <React.Fragment>
          <div class="container">
              <div class="loadinggif centered">
                <object data={loadingsvg} width="200" height="200"> </object>
                <div class="logocentered"><img src={logo} /></div>
              </div>
              
          </div>
        </React.Fragment >
      )
   }
}
export { longLoading as default }