import React, { Component } from 'react';
import { SimilarResults } from '../../API/FetchCall';
import { AdsOne, AdsTwo } from '../../components/others/ads';
import { RelatedTopics } from '../../components/Banner/relatedTopics';

class Similar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: '',
            similarList: [],
            auth: false,
            category_name: ''
        };
    }

    componentDidMount() {
        this.SResult();
    }
    SResult() {

        let reviewData = JSON.stringify({
            module: this.props.parmid.module,
            ref_id: this.props.parmid.ref_id,
            category: this.props.parmid.category,
        });

        SimilarResults(reviewData).then((response) => {
            if (response.status == 1) {
                this.setState({
                    similarList: response.results,
                    category_name: response.category_name,
                })
            }
        });
    }


    render() {
        let topTrending = {
            category_id: this.props.parmid.category,
            sub_category: 0
        }
        return (
            <React.Fragment>
                {/* <div className='trend-list'>
                    {this.state.similarList.map((item, index) => {
                        return (
                            <div className='sim-school'>
                                <div className='p8n5f'>
                                    <img
                                        src={item.images}
                                        width='43'
                                        height='43'
                                        alt='img'
                                    />
                                </div>
                                <div className='c8n5dg'>
                                    <a href={'/preschool-details/' + item.category + '/' + item.id}>
                                        <span className="text-overflow-similar">{item.organisation_name}</span>
                                    </a>
                                    <span className='m8vql9'>{item.location}</span>
                                </div>
                            </div>
                        );
                    })
                    }
                </div> */}

                <div className='d56bf5-right'>
                    {/* Top Trending start */}
                    <div className='h78dcs'>
                        <div className='info'>
                            <h6 className='head-p10'>Similar {this.state.category_name}</h6>
                            <div className='entry-colors'>
                                <div className='color_col_4'></div>
                            </div>
                            <div className='trend-list'>
                                {this.state.similarList.map((item, index) => {
                                    return (
                                        <div className='sim-school'>
                                            <div className='p8n5f'>
                                                <img
                                                    src={item.images}
                                                    width='43'
                                                    height='43'
                                                    alt='img'
                                                />
                                            </div>
                                            <div className='c8n5dg'>
                                                <a href={item.link}>
                                                    <span className="text-overflow-similar">{item.name}</span>
                                                </a>
                                                <span className='m8vql9'>{item.location ? item.location : (<br />)}</span>
                                            </div>
                                        </div>
                                    );
                                })
                                }
                            </div>
                        </div>
                    </div>
                    <RelatedTopics param={topTrending}></RelatedTopics>
                    <AdsOne />
                </div>

            </React.Fragment >
        );
    }
}
export { Similar };
