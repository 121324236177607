module.exports = {
    get_meta_data: function (page, cate, city) {
        let data = {
            meta_title: `An educational search engine to nurture your child's growth | BeWise`,
            meta_desc: 'BeWise offers a service to enable, inspire, empower and immerse your child across the entire spectrum of learning and fun.',
        }
        let check_meta = meta_data.filter(item => item.city == city && item.page == page && item.category == cate);
        if (check_meta.length > 0) {
            data.meta_title = check_meta[0].data[0];
            data.meta_desc = check_meta[0].data[1];
        } else {
            let check_meta = meta_data.filter(item => item.city == city && item.page == page && item.category == 'default');
            if (check_meta.length > 0) {
                data.meta_title = check_meta[0].data[0];
                data.meta_desc = check_meta[0].data[1];
            }   
        }
        return data;
    },
    get_curated_meta_data: function (keyword) {
        let data = {
            meta_title: `An educational search engine to nurture your child's growth | BeWise`,
            meta_desc: 'BeWise offers a service to enable, inspire, empower and immerse your child across the entire spectrum of learning and fun.',
        }
        let check_meta = meta_data_curated.filter(item => item.keyword == keyword);
        if (check_meta.length > 0) {
            data.meta_title = check_meta[0].data[0];
            data.meta_desc = check_meta[0].data[1];
        }
        return data;
    }
}
const meta_data_curated =
    [
        {
            keyword: 'drawing-6-12-years',
            data: [
                `Bewise Drawing Learning Camp`,
                `Bewise is here with the best online drawing learning camps, Join the virtual Learning drawing classes for kids and teens. Learn different pencil drawing with shading techniques & more.`
            ]
        },
        {
            keyword: 'french-language-6-12-years',
            data: [
                `Bewise French Language Learning Camp`,
                `Bewise is here with the best online French learning camps, Join the virtual learning, French language classes, for kids and teens. Learn Foundation, vocabulary, grammar, respond with simple conversations & more.`
            ]
        },
        {
            keyword: 'kannada-bewise-summer-camp',
            data: [
                `Bewise Kannada Language Summer Camp`,
                `Bewise is here with the best online Kannada learning camps, Join the virtual summer Kannada language classes for kids and teens. Learn Foundation, vocabulary, Grammar, reading, writing, & more.`
            ]
        },
        {
            keyword: 'coding-6-14-years',
            data: [
                `Bewise Coding Learning Camp`,
                `Bewise is here with the best online coding skills learning camps, Join the virtual learning coding classes for kids and teens. Build your children emotional intelligence & cognitive ability with the art of coding.`
            ]
        },
        {
            keyword: 'robotics-and-coding-6-14-years',
            data: [
                `Best Robotics Classes for Kids - For Kids Aged 6-14`,
                `The best robotics and coding camps for children aged 6 to 14 are being offered by Bewise for your child to attend.`
            ]
        },
        {
            keyword: 'storytelling-6-12-years',
            data: [
                `Bewise Storytelling Learning Camp`,
                `Bewise is here with the best online storytelling skills learning camps, Join the virtual learning storytelling art classes for kids and teens. Build your children emotional intelligence & cognitive ability with the art of storytelling.`
            ]
        },
    ];
const meta_data =
    [
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'default',
            data: [
                `BeWise`,
                `Find, compare - fees, facilities, reviews, ratings and other key information about Private Schools for your child near you at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'private-schools',
            data: [
                `Best Private Schools in Bangalore - Fees & Reviews | BeWise`,
                `BeWise provides a list of the top and best private schools in Bangalore. Find private CBSE and ICSE schools near me in Bangalore with contact, address, and admission details.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'public-schools',
            data: [
                `Best Public Schools in Bangalore - Fees & Reviews | BeWise`,
                `BeWise provides a list of the top and best Public schools in Bangalore. Find public CBSE and ICSE schools near me in Bangalore with contact, address, and admission details.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'board-schools',
            data: [
                `Best Boarding Schools in Bangalore - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best Boarding schools in Bangalore. Find residential schools near me in Bangalore with contact, address, and admission details.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'boarding-schools',
            data: [
                `Best Boarding Schools in Bangalore - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best Boarding schools in Bangalore. Find residential schools near me in Bangalore with contact, address, and admission details.`
            ]
        },
        
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'international-schools',
            data: [
                `Best International Schools Bangalore - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best International schools in Bangalore. Find IG schools near me in Bangalore with contact, address, and admission details.`
            ]
        },
        
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'school-special-needs',
            data: [
                `Best Special Needs schools Bangalore - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best special needs schools near me in Bangalore. Find schools with contact, address, and admission details.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'special-needs',
            data: [
                `Best Special Needs schools Bangalore - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best special needs schools near me in Bangalore. Find schools with contact, address, and admission details.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'alternative-schools',
            data: [
                `Best Alternative Schools Bangalore - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best Alternative schools in Bangalore. Find Alternative schools in Bangalore with contact, address, and admission details.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'open-schools',
            data: [
                `Best Open Schools Bangalore - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best Open schools in Bangalore. Find Open schools near me in Bangalore with contact, address, and admission details.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'scl',
            category: 'e-schools',
            data: [
                `Best E-Schools Bangalore - Facilities, Fees & Reviews | BeWise`,
                `BeWise provides a list of the top and best E-Schools in Bangalore. Find E-Schools near me in Bangalore with contact, address, and admission details.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'pre-scl',
            category: 'montessori',
            data: [
                `Best Montessori in Bangalore - Fees, Reviews & Rating| BeWise`,
                `Find, compare, and choose from the list of best and top PreSchool Montessori for your child nearby you, based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'pre-scl',
            category: 'default',
            data: [
                `BeWise`,
                ''
            ]
        },
        {
            city: 'bengaluru',
            page: 'pre-scl',
            category: 'kindergarten',
            data: [
                `Best Kindergarten in Bangalore - Best Pre Schools | BeWise`,
                `Find, compare, and choose from the list of best and top PreSchool Kindergarten for your child nearby you; based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'pre-scl',
            category: 'play-group',
            data: [
                `Best Playgroups in Bangalore - Fees & Reviews | BeWise`,
                `Find, compare, and choose from the list of best and top PreSchool PlaySchools for your child nearby you; based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'pre-scl',
            category: 'special-needs',
            data: [
                `Best Special Needs Pre-Schools Bangalore | BeWise`,
                `Find, compare, and choose from the list of best and top PreSchool Special needs for your child nearby you; based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'pre-scl',
            category: 'day-care-or-creche',
            data: [
                `Best Day Care & Creche in Bangalore | BeWise`,
                `Find, compare, and choose from the list of best and top Day Care and Creche for your child nearby you; based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'pre-scl',
            category: 'others',
            data: [
                `Best Pre-Schools in Bangalore - Fees & Reviews | BeWise`,
                `List of Best Pre Schools in Bangalore. At BeWise get Fee Structure, Reviews, Distance from Your Home, Address, and Contact Number for All Pre Schools in Bangalore`
            ]
        },
        {
            city: 'bengaluru',
            page: 'kids-play',
            category: 'default',
            data: [
                `BeWise`,
                `Find indoor kids play areas near your home at BeWise, a fun and learning experience to help your child learn and grow. Explore and compare fees, reviews, location, facilities and engage your child in fun and play based learning.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'kids-play',
            category: 'indoor',
            data: [
                `Best Indoor Kids Fun & Play Areas Bangalore | BeWise`,
                `Find indoor playgrounds for kids near you on BeWise. A fun and educational experience that helps children learn and grow. Compare prices, ratings, and locations.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'kids-play',
            category: 'outdoor',
            data: [
                `Best Outdoor Kids Fun & Play Areas Bangalore | BeWise`,
                `Find outdoor playgrounds for kids near you on BeWise. A fun and educational experience that helps children learn and grow. Compare prices, ratings, and locations.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'default',
            data: [
                `BeWise`,
                `Find, compare and make decisions on Bharatnatyam training academies, coaches near your home, for kids, across fees, facilities, reviews and other key information at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'yoga',
            data: [
                `Best Yoga Classes/Instructors For Kids Bangalore | BeWise`,
                `Looking for the best yoga classes/Instructors near me? Learn from the best yoga teachers in Bangalore. At BeWise choose from the list of top yoga classes/Instructors.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'abacus',
            data: [
                `Best Abacus Training/Classes For Kids Bangalore | BeWise`,
                `Looking for the best abacus training/classes near me? Learn from the best Abacus teachers in Bangalore. At BeWise choose from the list of top Abacus Training/Classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'kumon',
            data: [
                `Best Kumon Training Classes For Kids Bangalore | BeWise`,
                `Looking for the best Kumon training classes near me? Learn from the best Kumon teachers in Bangalore. At BeWise choose from the list of top Kumon Training classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'balvihar',
            data: [
                `Best Balvihar Classes, Value Based Education For Kids Bangalore | BeWise`,
                `Looking for the best Balvihar classes, Value-Based education near me? Learn from the best Balvihar teachers in Bangalore. At BeWise choose from the list of top Value Based Education classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'vedic-math',
            data: [
                `Best Vedic Math Classes/Teachers For Kids Bangalore | BeWise`,
                `Looking for the best Vedic math classes/Teachers near me? Learn from the best Vedic math teachers in Bangalore. At BeWise choose from the list of top Vedic Math Classes/Teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'shloka-classes',
            data: [
                `Best Shloka, Sanskrit Shloka Classes For Kids Bangalore | BeWise`,
                `Looking for the best Shloka, Sanskrit Shloka classes near me? Learn from the best Shloka teachers in Bangalore. At BeWise choose from the list of top Shloka teachers/ classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'coding',
            data: [
                `Best Coding Classes for kids in Bangalore | BeWise`,
                `Looking for the best coding classes near me? Learn from the best coding teachers in Bangalore. At BeWise choose from the list of top coding classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'cooking-and-baking',
            data: [
                `Best Cooking and Baking Classes for kids in Bangalore | BeWise`,
                `Looking for the best cooking and baking classes near me? Learn from the best cooking teachers in Bangalore. At BeWise choose from the list of top cooking and baking classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'story-telling',
            data: [
                `Best Storytelling Classes for kids in Bangalore | BeWise`,
                `Looking for the best storytelling classes near me? Learn from the best storytelling teachers in Bangalore. At BeWise choose from the list of top storytelling classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'quizzes',
            data: [
                `Best quizzes for kids in Bangalore | BeWise`,
                `Looking for the best quizzes near me? Learn from the best quizzes in Bangalore. At BeWise choose from the list of top quizzes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'bharatnatyam',
            data: [
                `Best Bharatnatyam Dance Classes in Bangalore | BeWise`,
                `Looking for the best Bharatnatyam Dance classes near me? Learn from the best dance teachers in Bangalore. At BeWise choose from the list of top Bharatnatyam teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'kuchipudi',
            data: [
                `Best Kuchipudi Dance Classes in Bangalore | BeWise`,
                `Looking for the best Kuchipudi Dance classes near me? Learn from the best dance teachers in Bangalore. At BeWise choose from the list of top Kuchipudi teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'odissi',
            data: [
                `Best Odissi Classical Dance Classes in Bangalore | BeWise`,
                `Looking for the best Odissi Dance classes near me? Learn from the best dance teachers in Bangalore. At BeWise choose from the list of top Odissi teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'kathak',
            data: [
                `Best Kathak Classical Dance Classes in Bangalore | BeWise`,
                `Looking for the best Kathak Dance classes near me? Learn from the best dance teachers in Bangalore. At BeWise choose from the list of top Kathak teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'bollywood',
            data: [
                `Best Bollywood Dance Classes in Bangalore | BeWise`,
                `Looking for the best Bollywood Dance classes near me? Learn from the best dance teachers in Bangalore. At BeWise choose from the list of top Bollywood teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'salsa-and-similar-dance',
            data: [
                `Best Salsa Western Dance Classes in Bangalore | BeWise`,
                `Looking for the best Salsa Western Dance classes near me? Learn from the best dance teachers in Bangalore. At BeWise choose from the list of top Salsa Western teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'hip-hop',
            data: [
                `Best Hip Hop Western Dance Classes in Bangalore | BeWise`,
                `Looking for the best Hip Hop Dance classes near me? Learn from the best dance teachers in Bangalore. At BeWise choose from the list of top Hip Hop teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'other-dance-forms',
            data: [
                `Best Dance Academy, Performing Art in Bangalore | BeWise`,
                `Looking for the best Dance classes near me? Learn from the best dance teachers in Bangalore. At BeWise choose from the list of top dance teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'theatre',
            data: [
                `Best Theatre, Drama Acting Training Center Bangalore | BeWise`,
                `Looking for the best theatre, drama acting classes near me? Learn from the best drama acting teachers in Bangalore. At BeWise choose from the list of top drama acting teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'ballet',
            data: [
                `Best Ballet Western Dance Class/Teachers Bangalore | BeWise`,
                `Looking for the best ballet Dance classes near me? Learn from the best dance teachers in Bangalore. At BeWise choose from the list of top ballet teachers.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'singing-classes',
            data: [
                `Best Singing Classes for Vocal in Bangalore | BeWise`,
                `Looking for vocal singing classes near me? Learn from the best singing teachers in Bangalore taking lessons for all ages. Choose from the list of top vocal trainers at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'instrumental',
            data: [
                `Best Music Instruments Training Classes in Bangalore | BeWise`,
                `Looking for music instrument training classes near me? Learn from the best instrument teachers in Bangalore taking lessons for all ages. Choose from the list of top music instrument trainers at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'drawing-painting',
            data: [
                `Best Drawing, Painting, Fine Arts Classes For Kids Bangalore | BeWise`,
                `Looking for the best drawing, painting, and fine arts classes near me? Learn from the best drawing teachers in Bangalore. At BeWise choose from the list of top drawing, painting, and fine arts classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'pottery',
            data: [
                `Best Pottery Classes For Kids Bangalore | BeWise`,
                `Looking for the best pottery classes near me? Learn from the best poettry teachers in Bangalore. At BeWise choose from the list of top pottery classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'arts-crafts',
            data: [
                `Best Arts & Crafts Classes For Kids Bangalore | BeWise`,
                `Looking for the best arts & crafts classes near me? Learn from the best arts and crafts teachers in Bangalore. At BeWise choose from the list of top arts & crafts classes.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'football',
            data: [
                `Best Football Training Academies/Coaches Bangalore | BeWise`,
                `Looking for the best football clubs and academies in Bangalore? At BeWise find & compare the list of top football coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'tennis',
            data: [
                `Best Tennis Academies/Coaches Bangalore | BeWise`,
                `Looking for the best tennis clubs and academies in Bangalore? At BeWise find & compare the list of top tennis coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'cricket',
            data: [
                `Best Cricket Club/Academies/Coaches Bangalore | BeWise`,
                `Looking for the best cricket clubs and academies in Bangalore? At BeWise find & compare the list of top cricket coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'swimming',
            data: [
                `Best Swimming Classes/Instructors Bangalore | BeWise`,
                `Looking for the best swimming classes and academies in Bangalore? At BeWise find & compare the list of top swimming coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'hockey',
            data: [
                `Best Hockey Academies/Coaches/Club Bangalore | BeWise`,
                `Looking for the best hockey clubs and academies in Bangalore? At BeWise find & compare the list of top hockey coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'badminton',
            data: [
                `Best Badminton Coaching Classes Bangalore | BeWise`,
                `Looking for the best badminton clubs and academies in Bangalore? At BeWise find & compare the list of top badminton coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'gymnastics',
            data: [
                `Best Gymnastics Training Center/Classes Bangalore | BeWise`,
                `Looking for the best gymnastics clubs and academies in Bangalore? At BeWise find & compare the list of top gymnastics coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'athletics-running',
            data: [
                `Best Athletics, Running Training/Coaching Bangalore | BeWise`,
                `Looking for the best athletics clubs and academies in Bangalore? At BeWise find & compare the list of top athletics coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'boxing-wrestling',
            data: [
                `Best Boxing, Wrestling Training Classes/Coaches Bangalore | BeWise`,
                `Looking for the best boxing clubs and academies in Bangalore? At BeWise find & compare the list of top wrestling coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'martial-arts-karate',
            data: [
                `Best Martial Arts Classes/Instructors Bangalore | BeWise`,
                `Looking for the best martial arts clubs and academies in Bangalore? At BeWise find & compare the list of top karate coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'trekking-rock-climbing',
            data: [
                `Best Trekking & Rock Climbing Training Camps Bangalore | BeWise`,
                `Looking for the best trekking clubs and academies in Bangalore? At BeWise find & compare the list of top trekking coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'all-others',
            data: [
                `Best Horse Riding, Kabbadi Training Classes Bangalore | BeWise`,
                `Looking for the best kabbadi clubs and academies in Bangalore? At BeWise find & compare the list of top kabbadi coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'chess',
            data: [
                `Best Chess Training, Coaching Institutes Center Bangalore | BeWise`,
                `Looking for the best chess clubs and academies in Bangalore? At BeWise find & compare the list of top chess coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'basketball',
            data: [
                `Best Basketball Training Academies/Coaches Bangalore | BeWise`,
                `Looking for the best basketball clubs and academies in Bangalore? At BeWise find & compare the list of top basketball coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'golf',
            data: [
                `Best Golf Coaching, Training, Clubs, Classes, Centers Bangalore | BeWise`,
                `Looking for the best golf clubs and academies in Bangalore? At BeWise find & compare the list of top golf coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'archery',
            data: [
                `Best Archery Coaching Classes Training Bangalore | BeWise`,
                `Looking for the best archery clubs and academies in Bangalore? At BeWise find & compare the list of top archery coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'archery',
            data: [
                `Best Archery Coaching Classes Training Bangalore | BeWise`,
                `Looking for the best archery clubs and academies in Bangalore? At BeWise find & compare the list of top archery coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'go-karting',
            data: [
                `Best Go Karting Training Classes Bangalore | BeWise`,
                `Looking for the best go karting clubs and academies in Bangalore? At BeWise find & compare the list of top go karting coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'skating',
            data: [
                `Best Skating Training Classes Bangalore | BeWise`,
                `Looking for the best go karting clubs and academies in Bangalore? At BeWise find & compare the list of top go karting coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'table-tennis',
            data: [
                `Best Table Tennis Club/Academies/Coaches Bangalore | BeWise`,
                `Looking for the best table tennis clubs and academies in Bangalore? At BeWise find & compare the list of top table tennis coaches near me with fees, reviews & more.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui',
            category: 'default',
            data: [
                `BeWise`,
                `Find Maths tutors or tuition teachers near you. Compare and get information on the fees, facilities, reviews, ratings, and other key information suitable for your child at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui',
            category: 'maths',
            data: [
                `Best Maths Tuitions/Tutor in Bangalore - Fees, Reviews | BeWise`,
                `Looking for Best Maths Tuition teachers near me. At BeWise, you will find lists of offline and online top maths teachers in bangalore for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui',
            category: 'science',
            data: [
                `Best Science Tuitions/Tutor Bangalore- Fees, Reviews | BeWise`,
                `Looking for Best Science Tuition teachers near me. At BeWise, you will find lists of offline and online top Science teachers in bangalore for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui',
            category: 'economics',
            data: [
                `Best Economics Tuitions/Tutor Bangalore | BeWise`,
                `Looking for Best Economics Tuition teachers near me. At BeWise, you will find lists of offline and online top Economics teachers in bangalore for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui',
            category: 'business-studies',
            data: [
                `Best Business Studies Tuitions/Tutor Bangalore | BeWise`,
                `Looking for Best Business Studies Tuition teachers near me. At BeWise, you will find lists of offline and online top Business Studies teachers in bangalore for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui',
            category: 'languages',
            data: [
                `Best Language Tuitions/Tutor Bangalore - Fees & Reviews | BeWise`,
                `Find the best language teacher for your kid near you in Bangalore. Here we are with the list of top Language tuition centers in Bangalore with details of fees, reviews, ratings, and more.`
            ]
        },
        
        {
            city: 'bengaluru',
            page: 'tui-1',
            category: 'default',
            data: [
                `BeWise`,
                `Find English tutors or tuition teachers near you. Compare and get information on the fees, facilities, reviews, ratings, and other key information suitable for your child at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui-1',
            category: 'french',
            data: [
                `Best French Tuitions/Tutor Bangalore - Fees, Reviews | BeWise`,
                `Looking for Best French Tuition teachers near me. At BeWise, you will find lists of offline and online top French teachers in bangalore for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },        
        {
            city: 'bengaluru',
            page: 'tui-1',
            category: 'english',
            data: [
                `English Tuitions/Teachers Bangalore - Fees, Reviews | BeWise`,
                `Find English tutors or tuition teachers near you. Compare and get information on the fees, facilities, reviews, ratings, and other key information suitable for your child at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui-1',
            category: 'sanskrit',
            data: [
                `Sanskrit Tuitions/Teachers Bangalore - Fees, Reviews | BeWise`,
                `Find Sanskrit tutors or tuition teachers near you. Compare and get information on the fees, facilities, reviews, ratings, and other key information suitable for your child at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui-1',
            category: 'mandarin',
            data: [
                `Mandarin Tuitions/Teachers Bangalore - Fees, Reviews | BeWise`,
                `Find Mandarin tutors or tuition teachers near you. Compare and get information on the fees, facilities, reviews, ratings, and other key information suitable for your child at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui-1',
            category: 'spanish',
            data: [
                `Spanish Tuitions/Tutor Bangalore - Fees, Reviews | BeWise`,
                `Looking for Best Maths Tuition teachers near me. At BeWise, you will find lists of offline and online top maths teachers in bangalore for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
       
        {
            city: 'bengaluru',
            page: 'tui-1',
            category: 'german',
            data: [
                `Best German Tuitions/Tutor Bangalore - Fees, Reviews | BeWise`,
                `Looking for Best German Tuition teachers near me. At BeWise, you will find lists of offline and online top German teachers in bangalore for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'tui-1',
            category: 'indian-languages',
            data: [
                `Best Indian Tuitions/Tutor Bangalore- Fees, Reviews | BeWise`,
                `Looking for Best Indian Language Tuition teachers near me. At BeWise, you will find lists of offline and online top Language teachers in bangalore for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'com-sup',
            category: 'default',
            data: [
                `BeWise`,
                `Find relevant information and make decisions on child counselors, psychologists, therapists. Explore options for child care and wellbeing at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'com-sup',
            category: 'counselor',
            data: [
                `Best Child Counselors or Psychologists in Bangalore | BeWise`,
                `Find relevant information and make decisions on child counselors, psychologists, therapists. Explore options for child care and wellbeing at BeWise`
            ]
        },
        {
            city: 'bengaluru',
            page: 'learn',
            category: 'default',
            data: [
                `BeWise`,
                `Find relevant information and make decisions on summer and winter learning camps near your home, for toddlers, kids for Below 4 Years, based on fees, reviews, location, facilities and more, at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'learn',
            category: 'below-4-years',
            data: [
                `Best Summer/Winter Camps For Child Below 4 Years | BeWise`,
                `Find a list of the top summer and winter learning camps near your home, for toddlers and kids below 4 Years, based on fees, location and more at BeWise`
            ]
        },
        {
            city: 'bengaluru',
            page: 'learn',
            category: '5-7-years',
            data: [
                `Best Summer/Winter Camps For Child 5 - 7 Years | BeWise`,
                `Find a list of the topt summer and winter learning camps near your home, for toddlers and kids for 5 -7 years, based on fees, location, and more at BeWise`
            ]
        },
        {
            city: 'bengaluru',
            page: 'learn',
            category: '8-10-years',
            data: [
                `Best Summer/Winter Camps For Child 8 - 10 Years | BeWise`,
                `Find a list of the top summer and winter learning camps near your home, for toddlers and kids for 8 -10 years, based on fees, location, and more at BeWise`
            ]
        },
        {
            city: 'bengaluru',
            page: 'learn',
            category: '11-12-years',
            data: [
                `Best Summer/Winter Camps For Child 11 - 12 Years | BeWise`,
                `Find a list of the top summer and winter learning camps near your home, for toddlers and kids for 13 -15 years, based on fees, location, and more at BeWise`
            ]
        },
        {
            city: 'bengaluru',
            page: 'learn',
            category: '13-15-years',
            data: [
                `Best Summer/Winter Camps For Child 13 - 15 Years | BeWise`,
                `Find a list of the top summer and winter learning camps near your home, for toddlers and kids for 13 -15 years, based on fees, location, and more at BeWise`
            ]
        },
        {
            city: 'bengaluru',
            page: 'birth-event',
            category: 'venues',
            data: [
                `List of Best Kids Birthday Party Venues in Bangalore | BeWise`,
                `Bangalore's top party halls, birthday party halls, venues, and prices. Compare and decide based on BeWise's location, ratings, and other facilities.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'birth-event',
            category: 'default',
            data: [
                `BeWise`,
                `Find Cakes/Bakeries near your home for kids. Compare and make decisions based on location, reviews and other facilities, at BeWise, a fun and learning experience to help your child learn and grow`
            ]
        },
        {
            city: 'bengaluru',
            page: 'birth-event',
            category: 'bakery-or-cake-shops',
            data: [
                `Best Cakes/Bakeries For Kids Birthday Party Bangalore | BeWise`,
                `Bangalore's Best Cakes/Bakeries For Kids Birthday Party. Compare and decide based on location, ratings, and other facilities available at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'birth-event',
            category: 'entertainers-or-mcs',
            data: [
                `Best MCs & Performers For Kids Birthday Party Bangalore | BeWise`,
                `Bangalore's Top Entertainers/MCs/Performers For Kids Birthday Party. Compare and decide based on location, ratings, and other facilities at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'birth-event',
            category: 'party-planners',
            data: [
                `Best Event Planners For Your Kids Birthday Bangalore | BeWise`,
                `Bangalore's Top Party, Event Planners Your Kids Birthday Party. Compare and decide based on location, ratings, and other facilities at BeWise.`
            ]
        },
        {
            city: 'bengaluru',
            page: 'birth-event',
            category: 'gift-and-supportives',
            data: [
                `Best gift corners For Your Kids' Birthday Bangalore | BeWise`,
                `Bangalore's Top Gift Corners for Your Kids' Birthday Party. Compare and decide based on location, ratings, and other facilities at BeWise.`
            ]
        },
        
        {
            city: 'chennai',
            page: 'pre-scl',
            category: 'montessori',
            data: [
                `Best Montessori in Chennai - Fees, Reviews & Rating| BeWise`,
                `Find, compare, and choose from the list of best and top PreSchool Montessori for your child nearby you, based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'chennai',
            page: 'pre-scl',
            category: 'kindergarten',
            data: [
                `Best Kindergarten in Chennai - Best Pre Schools | BeWise`,
                `Find, compare, and choose from the list of best and top PreSchool Kindergarten for your child nearby you; based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'chennai',
            page: 'pre-scl',
            category: 'play-group',
            data: [
                `Best Playgroups in Chennai - Fees & Reviews | BeWise`,
                `Find, compare, and choose from the list of best and top PreSchool PlaySchools for your child nearby you; based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'chennai',
            page: 'pre-scl',
            category: 'special-needs',
            data: [
                `Best Special Needs Pre-Schools Chennai | BeWise`,
                `Find, compare, and choose from the list of best and top PreSchool Special needs for your child nearby you; based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'chennai',
            page: 'pre-scl',
            category: 'day-care-or-creche',
            data: [
                `Best Day Care & Creche in Chennai | BeWise`,
                `Find, compare, and choose from the list of best and top Day Care and Creche for your child nearby you; based on costs, features, reviews, and ratings at BeWise.`
            ]
        },
        {
            city: 'chennai',
            page: 'scl',
            category: 'private-schools',
            data: [
                `Best Private Schools in Chennai - Fees & Reviews | BeWise`,
                `BeWise provides a list of the top and best private schools in Chennai. Find private CBSE and ICSE schools near me in Chennai with contact, address, and admission details.`
            ]
        },
        {
            city: 'chennai',
            page: 'scl',
            category: 'public-schools',
            data: [
                `Best Public Schools in Chennai - Fees & Reviews | BeWise`,
                `BeWise provides a list of the top and best Public schools in Chennai. Find public CBSE and ICSE schools near me in Chennai with contact, address, and admission details.`
            ]
        },
        {
            city: 'chennai',
            page: 'scl',
            category: 'boarding-schools',
            data: [
                `Best Boarding Schools in Chennai - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best Boarding schools in Chennai. Find residential schools near me in Chennai with contact, address, and admission details.`
            ]
        },
        {
            city: 'chennai',
            page: 'scl',
            category: 'international-schools',
            data: [
                `Best International Schools Chennai - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best International schools in Chennai. Find IG schools near me in Chennai with contact, address, and admission details.`
            ]
        },
        {
            city: 'chennai',
            page: 'scl',
            category: 'school-special-needs',
            data: [
                `Best Special Needs schools Chennai - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best special needs schools near me in Chennai. Find schools with contact, address, and admission details.`
            ]
        },
        {
            city: 'chennai',
            page: 'scl',
            category: 'alternative-schools',
            data: [
                `Best Alternative Schools Chennai - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best Alternative schools in Chennai. Find Alternative schools in Chennai with contact, address, and admission details.`
            ]
        },
        {
            city: 'chennai',
            page: 'scl',
            category: 'open-schools',
            data: [
                `Best Open Schools Chennai - Fees & Details | BeWise`,
                `BeWise provides a list of the top and best Open schools in Chennai. Find Open schools near me in Chennai with contact, address, and admission details.`
            ]
        },
        {
            city: 'chennai',
            page: 'scl',
            category: 'e-schools',
            data: [
                `Best E-Schools Chennai - Facilities, Fees & Reviews | BeWise`,
                `BeWise provides a list of the top and best E-Schools in Chennai. Find E-Schools near me in Chennai with contact, address, and admission details.`
            ]
        },
        {
            city: 'chennai',
            page: 'kids-play',
            category: 'indoor',
            data: [
                `Best Indoor Kids Fun & Play Areas Chennai | BeWise`,
                `Find indoor playgrounds for kids near you on BeWise. A fun and educational experience that helps children learn and grow. Compare prices, ratings, and locations`
            ]
        },
        {
            city: 'chennai',
            page: 'kids-play',
            category: 'outdoor',
            data: [
                `Best Outdoor Kids Fun & Play Areas Chennai | BeWise`,
                `Find outdoor playgrounds for kids near you on BeWise. A fun and educational experience that helps children learn and grow. Compare prices, ratings, and locations`
            ]
        },

        {   
            city: 'chennai',
            page: 'tui',
            category: 'maths',
            data: [
                `Best Maths Tuitions/Tutor in Chennai - Fees, Reviews | BeWise`,
                `Looking for Best Maths Tuition teachers near me. At BeWise, you will find lists of offline and online top maths teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui',
            category: 'science',
            data: [
                `Best Science Tuitions/Tutor Chennai- Fees, Reviews | BeWise`,
                `Looking for Best Science Tuition teachers near me. At BeWise, you will find lists of offline and online top Science teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui',
            category: 'economics',
            data: [
                `Best Economics Tuitions/Tutor Chennai | BeWise`,
                `Looking for Best Economics Tuition teachers near me. At BeWise, you will find lists of offline and online top Economics teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui',
            category: 'business-studies',
            data: [
                `Best Business Studies Tuitions/Tutor Chennai | BeWise`,
                `Looking for Best Business Studies Tuition teachers near me. At BeWise, you will find lists of offline and online top Business Studies teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui',
            category: 'social-science',
            data: [
                `Best Social Science Tuitions/ Tutor in Chennai | BeWise`,
                `Looking for the Best Social Science Tuition teachers near me. At BeWise, you will find lists of offline and online top Social Science teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State boards.`
            ]
        },
        {   
            city: 'bengaluru',
            page: 'tui',
            category: 'social-science',
            data: [
                `Best Social-science Tuitions/Tutor Bangalore | BeWise`,
                `Looking for Best Social-science Tuition teachers near me. At BeWise, you will find lists of offline and online top Social-science teachers in Bangalore for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui',
            category: 'languages',
            data: [
                `Best Language Tuitions/Tutors in Chennai | BeWise`,
                `Looking for the Best Language Tuition teachers near me. At BeWise, you will find lists of offline and online top Language teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State boards.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui-1',
            category: 'english',
            data: [
                `Best English Tuitions/Tutor Chennai - Fees & Reviews | BeWise`,
                `Looking for Best English Tuition teachers near me. At BeWise, you will find lists of offline and online top English teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui-1',
            category: 'french',
            data: [
                `Best French Tuitions/Tutor Chennai - Fees, Reviews | BeWise`,
                `Looking for Best French Tuition teachers near me. At BeWise, you will find lists of offline and online top French teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui-1',
            category: 'spanish',
            data: [
                `Spanish Tuitions/Tutor Chennai - Fees, Reviews | BeWise`,
                `Looking for Best Maths Tuition teachers near me. At BeWise, you will find lists of offline and online top maths teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui-1',
            category: 'german',
            data: [
                `Best German Tuitions/Tutor Chennai - Fees, Reviews | BeWise`,
                `Looking for Best German Tuition teachers near me. At BeWise, you will find lists of offline and online top German teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui-1',
            category: 'mandarin',
            data: [
                `Best Mandarin Tuitions/Tutor Chennai- Fees, Reviews | BeWise`,
                `Looking for Best Mandarin Tuition teachers near me. At BeWise, you will find lists of offline and online top Mandarin teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui-1',
            category: 'sanskrit',
            data: [
                `Best Sanskrit Tuitions/Tutor Chennai- Fees, Reviews | BeWise`,
                `Looking for Best Sanskrit Tuition teachers near me. At BeWise, you will find lists of offline and online top Sanskrit teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui-1',
            category: 'indian-languages',
            data: [
                `Best Indian Tuitions/Tutor Chennai- Fees, Reviews | BeWise`,
                `Looking for Best Indian Language Tuition teachers near me. At BeWise, you will find lists of offline and online top Language teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State board.`
            ]
        },
        {   
            city: 'chennai',
            page: 'tui-1',
            category: 'other-languages',
            data: [
                `Best Tuitions/Tutor Chennai- Fees, Reviews | BeWise`,
                `Looking for the  Best  Different LanguageTuition teachers near me. At BeWise, you will find lists of offline and online top Language teachers in Chennai for grades 1 -10 for all ICSE, CBSE, & State boards.`
            ]
        },            
        {   
            city: 'bengaluru',
            page: 'learn',
            category: 'curated-learning-camps',
            data: [
                `BeWise Curated Learning Camps| BeWise`,
                `BeWise is here with a curated online learning camp for your kid near you. Check out the various learning camps available with fees, locations and more at BeWise`
            ]
        },
        {   
            city: 'chennai',
            page: 'learn',
            category: 'curated-learning-camps',
            data: [
                `BeWise Curated Learning Camps| BeWise`,
                `BeWise is here with curated online learning camp for your kid near you. Check out the various learning camps available with fees, location and more at BeWise`
            ]
        },
        {   
            city: 'chennai',
            page: 'learn',
            category: 'below-4-years',
            data: [
                `Best Summer/Winter Camps For Child Below 4 Years | BeWise`,
                `Find a list of the top summer and winter learning camps near your home, for toddlers and kids below 4 Years, based on fees, location and more at BeWise`
            ]
        },
        {   
            city: 'chennai',
            page: 'learn',
            category: '5-7-years',
            data: [
                `Best Summer/Winter Camps For Child 5 - 7 Years | BeWise`,
                `Find a list of the topt summer and winter learning camps near your home, for toddlers and kids for 5 -7 years, based on fees, location, and more at BeWise`
            ]
        },
        {   
            city: 'chennai',
            page: 'learn',
            category: '8-10-years',
            data: [
                `Best Summer/Winter Camps For Child 8 - 10 Years | BeWise`,
                `Find a list of the top summer and winter learning camps near your home, for toddlers and kids for 8 -10 years, based on fees, location, and more at BeWise`
            ]
        },
        {   
            city: 'chennai',
            page: 'learn',
            category: '11-12-years',
            data: [
                `Best Summer/Winter Camps For Child 11 - 12 Years | BeWise`,
                `Find a list of the top summer and winter learning camps near your home, for toddlers and kids for 11 -12 years, based on fees, location, and more at BeWise`
            ]
        },
        {   
            city: 'chennai',
            page: 'learn',
            category: '13-15-years',
            data: [
                `Best Summer/Winter Camps For Child 13 - 15 Years | BeWise`,
                `Find a list of the top summer and winter learning camps near your home, for toddlers and kids for 13 -15 years, based on fees, location, and more at BeWise`
            ]
        },
        {   
            city: 'chennai',
            page: 'com-sup',
            category: 'counselor',
            data: [
                `Best Child Counselors or Psychologists in Chennai | BeWise`,
                `Find relevant information and make decisions on child counselors, psychologists, therapists. Explore options for child care and wellbeing at BeWise`
            ]
        },
        

        {   
            city: 'chennai',
            page: 'birth-event',
            category: 'venues',
            data: [
                `List of Kids Birthday Party Venues in Chennai | BeWise`,
                `Chennai's top party halls, birthday party halls, venues, and prices. Compare and decide based on BeWise's location, ratings, and other facilities.`
            ]
        },
        {   
        city: 'chennai',
        page: 'birth-event',
        category: 'bakery-or-cake-shops',
        data: [
            `Best Cakes/Bakeries For Kids Birthday Party Chennai | BeWise`,
            `Chennai's Best Cakes/Bakeries For Kids Birthday Party. Compare and decide based on location, ratings, and other facilities available at BeWise.`
        ]
        },

        {   
        city: 'chennai',
        page: 'birth-event',
        category: 'entertainers-or-mcs',
        data: [
            `Best MCs & Performers For Kids Birthday Party Chennai | BeWise`,
            `Chennai's Top Entertainers/MCs/Performers For Kids Birthday Party. Compare and decide based on location, ratings, and other facilities at BeWise.`
        ]
        },

        {   
        city: 'chennai',
        page: 'birth-event',
        category: 'party-planners',
        data: [
            `Best Event Planners For Your Kids Birthday Chennai | BeWise`,
            `Chennai's Top Party, Event Planners For Your Birthday Parties. Compare and decide based on location, ratings, and other facilities at BeWise.`
        ]
        },

        {   
        city: 'chennai',
        page: 'birth-event',
        category: 'gift-and-supportives',
        data: [
            `Best Gift and Supportivies For Kids Birthday in Chennai`,
            `Find the Chennai's Top Gift Outlets, for Your kids Birthday Parties. Compare and decide the best gift store near you based on location, ratings, and more at BeWise.`
        ]
        },
        {   
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'sports',
            data: [
                `Best Sports Academies in Bangalore | BeWise`,
                `Find the best sports teacher for your kid near you in Bangalore. Here we are with the list of top sports academies in Bangalore with details of fees, reviews, ratings, and more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'sports',
            data: [
                `Best Sports Academies in Chennai | BeWise`,
                `Find the best sports teacher for your kid near you in Chennai. Here we are with list of top sports academies in Chennai with details of fees, reviews, ratings, and more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'football',
            data: [
                `   Best Football Training Academies/Coaches Chennai | BeWise`,
                `Looking for the best football clubs and academies in Chennai? At BeWise find & compare the list of top football coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'tennis',
            data: [
                `   Best Tennis Academies/Coaches Chennai | BeWise`,
                `Looking for the best tennis clubs and academies in Chennai? At BeWise find & compare the list of top tennis coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'cricket',
            data: [
                `Best Cricket Club/Academies/Coaches Chennai | BeWise`,
                `Looking for the best cricket clubs and academies in Chennai? At BeWise find & compare the list of top cricket coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'swimming',
            data: [
                `Best Swimming Classes/Instructors Chennai | BeWise`,
                `Looking for the best swimming classes and academies in Chennai? At BeWise find & compare the list of top swimming coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'hockey',
            data: [
                `Best Hockey Academies/Coaches/Club Chennai | BeWise`,
                `Looking for the best hockey clubs and academies in Chennai? At BeWise find & compare the list of top hockey coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'badminton',
            data: [
                `Best Badminton Coaching Classes Chennai | BeWise`,
                `Looking for the best badminton clubs and academies in Chennai? At BeWise find & compare the list of top badminton coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'gymnastics',
            data: [
                `Best Gymnastics Training Center/Classes Chennai | BeWise`,
                `Looking for the best gymnastics clubs and academies in Chennai? At BeWise find & compare the list of top gymnastics coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'athletics-running',
            data: [
                `Best Athletics, Running Training/Coaching Chennai | BeWise`,
                `Looking for the best athletics clubs and academies in Chennai? At BeWise find & compare the list of top athletics coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'boxing-wrestling',
            data: [
                `Best Boxing, Wrestling Training Classes/Coaches Chennai | BeWise`,
                `Looking for the best boxing clubs and academies in Chennai? At BeWise find & compare the list of top wrestling coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'martial-arts-karate',
            data: [
                `Best Martial Arts Classes/Instructors Chennai | BeWise`,
                `Looking for the best martial arts clubs and academies in Chennai? At BeWise find & compare the list of top karate coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'trekking-rock-climbing',
            data: [
                `Best Trekking & Rock Climbing Training Camps Chennai | BeWise`,
                `Looking for the best trekking clubs and academies in Chennai? At BeWise find & compare the list of top trekking coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'all-others',
            data: [
                `Best Horse Riding, Kabbadi Training Classes Chennai | BeWise`,
                `Looking for the best kabbadi clubs and academies in Chennai? At BeWise find & compare the list of top kabbadi coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'chess',
            data: [
                `Best Chess Training, Coaching Institutes Center Chennai | BeWise`,
                `Looking for the best chess clubs and academies in Chennai? At BeWise find & compare the list of top chess coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'basketball',
            data: [
                `Best Basketball Training Academies/Coaches Chennai | BeWise`,
                `Looking for the best basketball clubs and academies in Chennai? At BeWise find & compare the list of top basketball coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'golf',
            data: [
                `Best Golf Coaching, Training, Clubs, Classes, Centers Chennai | BeWise`,
                `Looking for the best golf clubs and academies in Chennai? At BeWise find & compare the list of top golf coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'archery',
            data: [
                `Best Archery Coaching Classes Training Chennai | BeWise`,
                `Looking for the best archery clubs and academies in Chennai? At BeWise find & compare the list of top archery coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'go-karting',
            data: [
                `Best Go Karting Training Classes Chennai | BeWise`,
                `Looking for the best go karting clubs and academies in Chennai? At BeWise find & compare the list of top go karting coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'skating',
            data: [
                `Best Skating Training Classes Chennai | BeWise`,
                `Looking for the best skating clubs and academies in Chennai? At BeWise find & compare the list of top skating coaches near me with fees, reviews & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'table-tennis',
            data: [
                `Best Table Tennis Club/Academies/Coaches Chennai | BeWise`,
                `Looking for the best table tennis clubs and academies in Chennai? At BeWise find & compare the list of top table tennis coaches near me with fees, reviews & more.`
            ]
        },
        
      {   
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'music',
            data: [
                `Best Music Classes in Bangalore- Fees, Reviews | BeWise`,
                `Find the best music teacher for your kid near you in Bangalore. Here we are with the list of top music academies in Bangalore with details of fees, reviews, ratings, & more.`
            ]
        },   
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'music',
            data: [
                `Best Music Classes in Chennai - Fees, Reviews | BeWise`,
                `Find the best music teacher for your kid near you in Chennai. Here we are with list of top music academies in Chennai with details of fees, reviews, ratings, & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'singing-classes',
            data: [
                `Best Singing Classes for Vocal in Chennai | BeWise`,
                `Looking for vocal singing classes near me? Learn from the best singing teachers in Chennai taking lessons for all ages. Choose from the list of top vocal trainers at BeWise.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'instrumental',
            data: [
                `Best Music Instruments Training Classes in Chennai | BeWise`,
                `Looking for music instrument training classes near me? Learn from the best instrument teachers in Chennai taking lessons for all ages. Choose from the list of top music instrument trainers at BeWise.`
            ]
        },
        {   
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'dance-and-theatre',
            data: [
                `Best Dance Classes in Bangalore - Fees, Reviews | BeWise`,
                `Find the best dance teacher for your kid near you in Bangalore. Here we are with the list of top dance academies in Bangalore with details of fees, reviews, ratings, & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'dance-and-theatre',
            data: [
                `Best Dance Classes in Chennai - Fees, Reviews | BeWise`,
                `Find the best dance teacher for your kid near you in Chennai. Here we are with list of top dance academies in Chennai with details of fees, reviews, ratings, & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'bharatnatyam',
            data: [
                `Best Bharatnatyam Dance Classes in Chennai | BeWise`,
                `Looking for the best Bharatnatyam Dance classes near me? Learn from the best dance teachers in Chennai. At BeWise choose from the list of top Bharatnatyam teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'kuchipudi',
            data: [
                `Best Kuchipudi Dance Classes in Chennai | BeWise`,
                `Looking for the best Kuchipudi Dance classes near me? Learn from the best dance teachers in Chennai. At BeWise choose from the list of top Kuchipudi teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'odissi',
            data: [
                `Best Odissi Classical Dance Classes in Chennai | BeWise`,
                `Looking for the best Odissi Dance classes near me? Learn from the best dance teachers in Chennai. At BeWise choose from the list of top Odissi teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'kathak',
            data: [
                `Best Kathak Classical Dance Classes in Chennai | BeWise`,
                `Looking for the best Kathak Dance classes near me? Learn from the best dance teachers in Chennai. At BeWise choose from the list of top Kathak teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'bollywood',
            data: [
                `Best Bollywood Dance Classes in Chennai | BeWise`,
                `Looking for the best Bollywood Dance classes near me? Learn from the best dance teachers in Chennai. At BeWise choose from the list of top Bollywood teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'salsa-and-similar-dance',
            data: [
                `Best Salsa Western Dance Classes in Chennai | BeWise`,
                `Looking for the best Salsa Western Dance classes near me? Learn from the best dance teachers in Chennai. At BeWise choose from the list of top Salsa Western teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'hip-hop',
            data: [
                `Best Hip Hop Western Dance Classes in Chennai | BeWise`,
                `Looking for the best Hip Hop Dance classes near me? Learn from the best dance teachers in Chennai. At BeWise choose from the list of top Hip Hop teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'other-dance-forms',
            data: [
                `Best Dance Academy, Performing Art in Chennai | BeWise`,
                `Looking for the best Dance classes near me? Learn from the best dance teachers in Chennai. At BeWise choose from the list of top dance teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'theatre',
            data: [
                `Best Theatre, Drama Acting Training Center Chennai | BeWise`,
                `Looking for the best theatre, drama acting classes near me? Learn from the best drama acting teachers in Chennai. At BeWise choose from the list of top drama acting teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'ballet',
            data: [
                `Best Ballet Western Dance Class/Teachers Chennai | BeWise`,
                `Looking for the best ballet Dance classes near me? Learn from the best dance teachers in Chennai. At BeWise choose from the list of top ballet teachers.`
            ]
        },
        {   
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'creative-arts',
            data: [
                `Best Creative Arts Classes in Bangalore - Fees, Reviews | BeWise`,
                `Find the best creative arts teacher for your kid near you in Bangalore. Here we are with a list of top creative arts schools in Bangalore with details of fees, reviews, & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'creative-arts',
            data: [
                `Best Creative Arts Classes in Chennai - Fees, Reviews | BeWise`,
                `Find the best creative arts teacher for your kid near you in Chennai. Here we are with list of top creative arts schools in Chennai with details of fees, reviews, & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'drawing-painting',
            data: [
                `Best Drawing, Painting, Fine Arts Classes For Kids Chennai | BeWise`,
                `Looking for the best drawing, painting, and fine arts classes near me? Learn from the best drawing teachers in Chennai. At BeWise choose from the list of top drawing, painting, and fine arts classes.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'pottery',
            data: [
                `Best Pottery Classes For Kids Chennai | BeWise`,
                `Looking for the best pottery classes near me? Learn from the best poettry teachers in Chennai. At BeWise choose from the list of top pottery classes.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'arts-crafts',
            data: [
                `Best Arts & Crafts Classes For Kids Chennai | BeWise`,
                `Looking for the best arts & crafts classes near me? Learn from the best arts and crafts teachers in Chennai. At BeWise choose from the list of top arts & crafts classes.`
            ]
        },
        
        {   
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'mind-and-skill-enrichment',
            data: [
                `Best Mind and Skill Enrichment Classes in Bangalore | BeWise`,
                `Find the best skill enrichment teacher for your kid near you in Bangalore. Here we are with the list of top skill enrichment classes in Bangalore with details of fees, reviews, & more.`
            ]
        },
        
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'mind-and-skill-enrichment',
            data: [
                `Best Skill Enrichment Classes in Chennai - Fees, Reviews | BeWise`,
                `Find the best skill enrichment teacher for your kid near you in Chennai. Here we are with the list of top skill enrichment classes in Chennai with details of fees, reviews, & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'yoga',
            data: [
                `Best Yoga Classes/Instructors For Kids Chennai | BeWise`,
                `Looking for the Best yoga classes/Instructors near me? Learn from the best yoga teachers in Chennai. At BeWise choose from the list of top yoga classes/Instructors.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'abacus',
            data: [
                `Best Abacus Training/Classes For Kids Chennai | BeWise`,
                `Looking for the best abacus training/classes near me? Learn from the best Abacus teachers in Chennai. At BeWise choose from the list of top Abacus Training/Classes.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'kumon',
            data: [
                `Best Kumon Training Classes For Kids Chennai | BeWise`,
                `Looking for the Best Kumon training classes near me? Learn from the best Kumon teachers in Chennai. At BeWise choose from the list of top Kumon Training classes.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'vedic-math',
            data: [
                `Best Vedic Math Classes/Teachers For Kids Chennai | BeWise`,
                `Looking for the Best Vedic math classes/Teachers near me? Learn from the best Vedic math teachers in Chennai. At BeWise choose from the list of top Vedic Math Classes/Teachers.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'balvihar',
            data: [
                `Best Balvihar Classes, Value Based Education For Kids Chennai | BeWise`,
                `Looking for the Best Balvihar classes, Value-Based education near me? Learn from the best Balvihar teachers in Chennai. At BeWise choose from the list of top Value Based Education classes.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'shloka-classes',
            data: [
                `Best Shloka, Sanskrit Shloka Classes For Kids Chennai | BeWise`,
                `Looking for the Best Shloka, Sanskrit Shloka classes near me? Learn from the best Shloka teachers in Chennai. At BeWise choose from the list of top Shloka teachers/ classes.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'coding',
            data: [
                `Best Coding Classes for kids in Chennai | BeWise`,
                `Looking for the Best coding classes near me? Learn from the best coding teachers in Chennai. At BeWise choose from the list of top coding classes.`
            ]
        },
         {   
            city: 'bengaluru',
            page: 'af-scl-act',
            category: 'hobbies-and-passion',
            data: [
                `Best Hobby and Passion Classes in Bangalore - Fees, Reviews | BeWise`,
                `Find the best hobby classes for your kid near you in Bangalore. Here we are with the list of top hobby classes in Bangalore with details of fees, reviews, & more.`
            ]
        },
        
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'hobbies-and-passion',
            data: [
                `Best hobby Classes in Chennai - Fees, Reviews | BeWise`,
                `Find the best hobby classes for your kid near you in Chennai. Here we are with the list of top hobby classes in Chennai with details of fees, reviews, & more.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'cooking-and-baking',
            data: [
                `Best Cooking and Baking Classes in Chennai For Kids| BeWise`,
                `Looking for the Best Cooking and Baking, Value-Based education near me? Learn from the best Cooking and Baking teachers in Chennai. At BeWise choose from the list of top Value Based Education classes.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'story-telling',
            data: [
                `Best Story Telling Classes For Kids in Chennai | BeWise`,
                `Looking for the Best Story Telling, Value-Based education near me? Learn from the best Story Telling teachers in Chennai. At BeWise choose from the list of top Value Based Education classes.`
            ]
        },
        {   
            city: 'chennai',
            page: 'af-scl-act',
            category: 'quizzes',
            data: [
                `Best Quiz/Problem Solving Classes For Kids Chennai | BeWise`,
                `Looking for Quiz/Problem Solving classes, Value-Based education near me? Learn from the Problem Solving teachers in Chennai. At BeWise choose from the list of top Value Based Education classes.`
            ]
        },        
]
