import React, { Component } from 'react';
import { saveLikePartnerVideo, getLikePartnerVideo } from '../../API/FetchCall';
// import Rating from 'react-star-rating-component';

class LikeVideo extends React.Component {
    constructor(props) {
        super(props);
        let data_auth = (localStorage.getItem("myData"));
        let auth_data = JSON.parse(data_auth);
        let id = '';
        if (auth_data) {
            id = auth_data.user_id;
        }
        this.state = {
            user_likes: 0,
            user_follow: 0,
            user_id: id,
            auth: false,
            is_follow_count: 0,
            is_like_count: 0,
        };
    }

    componentDidMount() {

        let data_auth = (localStorage.getItem("myData"));
        console.log('**', this.state.user_id)
        if (data_auth) {
            let auth_data = JSON.parse(data_auth)
            // console.log('**', auth_data.user_id)
            this.setState({
                logged_name: auth_data.first_name + ' ' + auth_data.last_name,
                authe: true,
                user_id: auth_data.user_id,
            });
        }
        this.GetDeta(this.state.user_id);


    }
    GetDeta(user_id) {

        let reviewData = JSON.stringify({
            user_id: user_id,
            subcategory: this.props.paramId.category,
            category: this.props.paramId.main_category,
            page_id: this.props.paramId.ref_id,
            vendor_id: this.props.paramId.vendor_id,
            role: 4
        });
        console.log('this.props', this.props);
        getLikePartnerVideo(reviewData).then((response) => {
            let toastr = '';
            console.log('responseresponse', response)
            if (response.status == 1) {
                console.log(response)
                this.setState({
                    is_like_count: response.result.total_likes,
                    user_likes: response.result.is_like,
                })
            }
        });
    }

    likeFollow = (is_like) => {

        let reviewData = JSON.stringify({
            user_id: this.state.user_id,
            subcategory: this.props.paramId.category,
            category: this.props.paramId.main_category,
            page_id: this.props.paramId.ref_id,
            vendor_id: this.props.paramId.vendor_id,
            is_like: is_like ? 1 : 0,
            role: 4
        });

        saveLikePartnerVideo(reviewData).then((response) => {
            let toastr = '';
            if (response.status == 1) {
                this.GetDeta(this.state.user_id);
            } else {
            }
        });
    }

    isliked(is_like) {
        this.likeFollow(is_like);
    }


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="text-small d-inline-flex mr-3">
                            <div class="form-group">
                                {(this.state.user_likes === 0) ?
                                    <span title='Like us' style={{ color: '#000' }} onClick={() => this.isliked(true)}><i class="fa fa-thumbs-up" aria-hidden="true"></i> {this.state.is_like_count} Likes</span>
                                    :
                                    <span title='Like us' style={{ color: '#000' }} onClick={() => this.isliked(false)} ><i class="fa fa-thumbs-up" style={{ color: 'grey' }} aria-hidden="true"></i> {this.state.is_like_count} Likes </span>
                                }
                            </div>
                        </div>
                        <div className="text-small d-inline-flex">
                            <div class="form-group">
                                <button data-toggle="modal" data-target="#feedBackModal" className='btn btn-primary' >Feedback</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
export { LikeVideo };
