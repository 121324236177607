import React, { Component } from 'react';
// import { like_follow, getLike } from '../../API/FetchCall';
import { BASE_URL } from '../../constant';
import $ from 'jquery';
import { serviceProvider } from '../../API/FetchCall';
class ContactInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: false,
      q_comments: '',
      org_name: this.props.data.org_name ? this.props.data.org_name : '',
      q_name: '',
      q_type: '',
      q_phone_no: '',
      q_subject: '',
    };
  }

  componentDidMount() {
    let auth = localStorage.getItem("logged_auth");
    let data_auth = (localStorage.getItem("myData"));
    if (data_auth) {
      let auth_data = JSON.parse(data_auth)
      this.setState({
        logged_name: auth_data.first_name + ' ' + auth_data.last_name,
        authe: true,
        user_id: auth_data.user_id,
        q_email_id: auth_data.email_id,
        q_phone_no: auth_data.mobile_no,
      });
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleQuery = (e) => {
    e.preventDefault();

    if (
      this.state.q_comments != '' && this.state.q_name != '' && this.state.q_type != '' &&
      this.state.q_phone_no != '' && this.state.q_subject != '') {

      let regData = JSON.stringify({
        q_type: this.state.q_type,
        name: this.state.q_name,
        email_id: this.state.q_email_id,
        subject: this.state.q_subject,
        phone_no: this.state.q_phone_no,
        comments: this.state.q_comments,
        user_id: this.state.user_id,
        vendor_id: this.props.data.vendor_id,
        category_id: this.props.data.category_id,
        category: this.props.data.category,
        service_id: this.props.data.id,
        vendor_email: this.props.data.email_id,
      });



      serviceProvider(regData).then((response) => {
        let toastr = '';
        if (response.status == 1) {

          toastr = window.toastr.success;
          toastr('', 'Thanks for contact partner!..', {
            positionClass: "toast-bottom-center",
            containerId: "toast-bottom-center"
          });
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        } else {
          toastr = window.toastr.error; //warning
          let msg = response.message ? response.message : 'Failed'
          toastr('', msg, {
            positionClass: "toast-bottom-center",
            containerId: "toast-bottom-center"
          });
        }
      });

    } else {
      let toastr = '';
      if (this.state.q_name == '') {
        toastr = window.toastr.error; //warning
        toastr('', 'Name Required!...', {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
      } else if (this.state.q_type == '') {
        toastr = window.toastr.error; //warning
        toastr('', 'Query type Required!...', {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
      } else if (this.state.q_email_id == '') {
        toastr = window.toastr.error; //warning
        toastr('', 'Email. Required!...', {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
      } else if (this.state.q_phone_no == '') {
        toastr = window.toastr.error; //warning
        toastr('', 'Phone No. Required!...', {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
      } else if (this.state.q_subject == '') {
        toastr = window.toastr.error; //warning
        toastr('', 'Subject Required!...', {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
      } else if (this.state.q_comments == '') {
        toastr = window.toastr.error; //warning
        toastr('Error', 'Comments Required!...', {
          positionClass: "toast-bottom-center",
          containerId: "toast-bottom-center"
        });
      }

    }
  };

  render() {


    return (
      <React.Fragment>
        <div className="post-query-btn contact-institution">
          <div class="tooltip-custom">
            <a style={{ height: '22px' }} class="float-wtsapp" data-toggle="modal" data-target="#service_provider">

              <img className="blink-style ga-post-query" style={{ height: 'auto' }} src={BASE_URL + 'assets/icons/whatsapp.png'} />
            </a>

            {/* <span class="tooltiptext">Will be available when we are Live</span> */}
          </div>
        </div>
        <div
          className='modal fade review-modal'
          id='service_provider'
        >
          <div className='modal-dialog model-box modal-md' >
            <form method='post'
              onSubmit={this.handleQuery}
              autoComplete='off'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h3 className='modal-title r-text'>
                    Contact {this.state.org_name}
                  </h3>
                  <button
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                  >
                    <span aria-hidden='true'>&times;</span>
                  </button>
                </div>
                <div className='modal-body'>

                  <div className="row contactus-form">
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label className='login-label r-text'>
                          Name<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input className='form-control'
                          value={this.state.q_name}
                          name="q_name"
                          onChange={this.handleChange}
                          type='text' placeholder="Enter your name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label className='login-label r-text'>
                          Query Type<span style={{ color: 'red' }}>*</span>
                        </label>
                        <select name="q_type" onChange={this.handleChange} className="form-control contactus-formli">
                          <option value="">Select</option>
                          <option value="1">Have a query</option>
                          <option value="2">Have a suggestion</option>
                          <option value="3">Need a help</option>
                          <option value="4">Others</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className='form-group'>
                        <label className='login-label r-text'>
                          Email<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input className='form-control' type='email'
                          value={this.state.q_email_id}
                          name="q_email_id"
                          onChange={this.handleChange}
                          placeholder="Enter your email" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className='form-group'>
                        <label className='login-label r-text'>
                          Phone Number<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input className='form-control' type='text'
                          value={this.state.q_phone_no}
                          name="q_phone_no"
                          maxlength="10"
                          onChange={this.handleChange}
                          placeholder="Enter your Phone Number " />
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div className='form-group'>
                        <label className='login-label r-text'>
                          Subject<span style={{ color: 'red' }}>*</span>
                        </label>
                        <input className='form-control' type='text'
                          value={this.state.q_subject}
                          name="q_subject"
                          onChange={this.handleChange}
                          placeholder="Enter subject" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className='form-group'>
                        <label className='r-text '>Comments<span style={{ color: 'red' }}>*</span>
                        </label>
                        <textarea
                          className='form-control'
                          id='message-text'
                          name="q_comments"
                          value={this.state.q_comments}
                          onChange={this.handleChange}
                          rows='4'
                          cols='50'
                        ></textarea>
                      </div>
                    </div>

                  </div>

                </div>
                <div className='modal-footer' style={{ border: 'none' }}>
                  <button type='button' className='btn  btn-default' data-dismiss='modal' aria-label='Close'>
                    {' '}
                    Cancel
                  </button>
                  <button type='submit' className='btn btn-primary'>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment >
    );
  }
}
export { ContactInfo };
