import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import {
    Modal, ModalBody, Row, Col,
} from 'reactstrap';
import { withCookies, Cookies } from 'react-cookie';
import {
    BASE_URL
} from '../constant';
import IdleTimer from 'react-idle-timer';

class IdlePop extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {
        super(props);
        const { cookies } = this.props;
        this.state = {
            auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            timeout: 1000 * 5 * 1,
            isTimedOut: false,
            showAutoPop: false
        };
        this.idleTimer = null
        this.onIdle = this._onIdle.bind(this)
    }
    componentDidMount() {
        if (this.state.auth == 'true' && this.state.auth_data) {

        }
    }
    _onIdle(e) {
        const { isTimedOut, auth } = this.state;
        const { cookies } = this.props;
        let autoPop = document.cookie.indexOf('autoPop') > -1 ? (cookies.get('autoPop')) : false;
        if (isTimedOut && autoPop.toString() === 'false' && auth.toString() === 'false') {
            cookies.set('autoPop', true, { path: '/' });
            this.setState({
                showAutoPop: true
            })
            this.openSignup();
            // localStorage.setItem('autoPop', true);
        } else {
            this.idleTimer.reset();
            this.setState({ isTimedOut: true })
        }
    }
    openSignup = () => {
        this.setState({
            showAutoPop: false
        })
        this.props.callSingup();
    }
    render() {
        return (
            <React.Fragment>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    debounce={0}
                    timeout={6500} />
                <Modal isOpen={false} centered size='md'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={() => { this.setState({ showAutoPop: false }) }}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>
                        <div className=''>
                            <Row className='text-center'>
                                <Col sm={{ size: 12 }} className=''>
                                    <div className=''>
                                        <img onClick={this.openSignup} alt='Signup Pop' src='/assets/images/autopop.png'
                                            style={{ width: '100%', cursor: 'pointer' }} />
                                        {/* <h4>Access all the premium features with free&nbsp;
                                            <span style={{ color: '#82b6ff', fontWeight: 500, cursor: 'pointer' }} onClick={this.openSignup}>signup</span>.</h4> */}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}
export default withCookies(IdlePop)