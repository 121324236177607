import React, { Component } from 'react';
import { ratingReview } from '../../API/FetchCall';
import Rating from 'react-star-rating-component';
import { BASE_URL } from '../../constant';
import swal from 'sweetalert';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import facebook from "../../../src/assets/icons/new_facebook_icon.png";
import instagram from "../../../src/assets/icons/new_instagram_icon.png";
import twitter from "../../../src/assets/icons/new_twitter_icon.png";
import linkedin from "../../../src/assets/icons/new_linkedin_icon.png";
import mailus from "../../../src/assets/icons/new_email_icon.png";
import firstreview from "../../../src/assets/icons/firstreview.png";
import logoBewise from '../../assets/images/bewise-logo-new.png';
import $ from 'jquery';
const wordCount = 60;
const errorWords = `Allowed only ${wordCount} words`;
function preventContent() {
    return (
        <div className=''>
            <div className='bewise-logo active'>
                <div class="row m-3">
                    <div>
                        <a className='logo-text' href='/'>
                            <img src={logoBewise} style={{ width: "55px", marginRight: "2px" }} />
                        </a>
                    </div>
                    <div>
                        <h5 style={{ lineHeight: "3px", marginTop: "10px", fontWeight: "bold", transform: "scale(1, 1.1)" }}>BeWise</h5>
                        <p style={{ fontSize: "8px", fontWeight: "bold", transform: "scale(.75, 1)", marginLeft: "-10px" }}>Enabling Learning & Fun</p>
                    </div>
                </div>
            </div>
            <Row className='m-3'>
                <Col sm={{ size: 12 }} className=''>
                    <div className=''>
                        <ul>
                            <li>Provide constructive feedback</li>
                            <li>Keep it short and specific enough</li>
                            <li>Best value is when you feedback on entire experience</li>
                            <li>Review / proofread once before submitting it</li>
                            <li>Feel free to edit any time</li>
                            <li>
                                Your review on a scale of 1-5 gets averaged and is a
                                good balance amidst multiple experiences.
                                However, your words in the feedback / review
                                carry a much higher value to everyone reading.
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
class ReviewSchools extends React.Component {
    constructor() {
        super();
        this.state = {
            child_name: '',
            child_safety: '',
            covid19: '',
            facilities: '',
            food: '',
            grade: [],
            infrastructure: '',
            overall_expirence: '',
            qty_info_website: '',
            qty_interaction: '',
            qty_teachers: '',
            other_comment: '',
            qty_teaching: '',
            teacher_student_ratio: '',
            transport_arrange: '',
            visit_date: '',
            commets_show: 1,
            info_pop: false,
            logged_auth: localStorage.getItem('logged_auth') ? localStorage.getItem('logged_auth') : '',
        };
    }
    componentDidMount() {
        let THIS = this;
        $(document).on('click', '.prevent-pop', function () {
            THIS.setState({
                info_pop: true
            })
        })
    }
    closepop = () => {
        this.setState({
            info_pop: false
        })
    }
    handleChange = (e) => {
        const propName = e.target.name;
        var propValue = e.target.value;
        if (propName == 'other_comment') {
            let words = propValue.split(" ");
            if (words.length > wordCount) {
                let toastr = '';
                words.length = wordCount;
                propValue = words.join(" ");
                toastr = window.toastr.error;
                toastr('', errorWords, {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            }
        }
        this.setState({ [propName]: propValue });
    };

    handleSelect = (e) => {
        const propName = e.target.name;
        const value = [...e.target.selectedOptions].map((item) => item.value);
        this.setState({ [propName]: value });
    }

    handleClick = (e) => {
        // console.log(e.target.name)
        const propName = e.target.name;
        this.setState(prevState => ({
            [propName]: !prevState[propName]
        }));
    }

    onStarClick(nextValue, prevValue, name) {
        let probName = name;
        this.setState({ [probName]: nextValue });
    }




    reviewSubmit = (e) => {

        e.preventDefault();
        if (this.state.overall_expirence == '') {
            let toastr = '';
            toastr = window.toastr.error; //warning
            toastr('Required', 'Enter Overall Rating', {
                positionClass: "toast-bottom-center",
                containerId: "toast-bottom-center"
            });
            return false;
        }
        swal({
            title: "Are you sure to submit the Reviews?",
            text: "Overall rating is compulsory, would be good to have ratings for every element!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    let total = parseInt(this.state.covid19) + parseInt(this.state.facilities) +
                        parseInt(this.state.food) + parseInt(this.state.infrastructure) + parseInt(this.state.overall_expirence)
                        + parseInt(this.state.qty_info_website) + parseInt(this.state.qty_interaction) + parseInt(this.state.qty_teachers)
                        + parseInt(this.state.qty_teaching) + parseInt(this.state.child_safety) +
                        parseInt(this.state.teacher_student_ratio) + parseInt(this.state.transport_arrange);

                    let reviewData = JSON.stringify({
                        category_id: this.props.data.keyword,
                        vendor_id: this.props.data.vendor_id,
                        review_id: this.props.data.review_id,
                        user_id: this.props.data.user_id,
                        child_name: this.state.child_name,
                        child_safety: this.state.child_safety,
                        covid19: this.state.covid19,
                        facilities: this.state.facilities,
                        food: this.state.food,
                        infrastructure: this.state.infrastructure,
                        overall_expirence: this.state.overall_expirence,
                        other_comment: this.state.other_comment,
                        qty_info_website: this.state.qty_info_website,
                        qty_interaction: this.state.qty_interaction,
                        qty_teachers: this.state.qty_teachers,
                        qty_teaching: this.state.qty_teaching,
                        rating: this.state.rating,
                        teacher_student_ratio: this.state.teacher_student_ratio,
                        transport_arrange: this.state.transport_arrange,
                        visit_date: this.state.visit_date,
                        commets_show: this.state.commets_show,
                        total: total
                    });
                    let toastr = '';
                    ratingReview(reviewData).then((response) => {
                        console.log(response);

                        if (response.status == 1) {
                            toastr = window.toastr.success;
                            toastr('Success', 'Thanks for Reviews!..', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });

                            setTimeout(function () {
                                window.location.reload();
                            }, 1000);

                        } else {
                            toastr = window.toastr.error; //warning
                            toastr('Error', 'Something went wrong. try again!...', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                        }
                    });
                }
            });
    }



    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div data-toggle="modal" data-target="#reviewModal" id='review-btn'>
                    <div className="prevent-pop banner-btn">
                        <div className="btn-icon">
                            <img src={BASE_URL + 'assets/icons/review.png'} alt='img' />
                        </div>
                        <div className="text-btn">
                            Write a Review
                        </div>
                    </div>
                </div>
                <div
                    className='modal fade review-modal'
                    id='reviewModal'
                >
                    <div className='modal-dialog ipad-lg model-box modal-lg' >
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h3 className='modal-title r-text'>Review</h3>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div id="formContent">
                                <form method='post'
                                    onSubmit={this.reviewSubmit}
                                    autoComplete='off'>
                                    <div className='modal-body'>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>Customer Rating</h5>
                                            </div>
                                            {/* <div className="col-md-6">
                                            <div className='form-group'>
                                                <label className='login-label r-text'>
                                                    Name of the Children                        </label>
                                                <input value={this.state.child_name} name="child_name" className='form-control' onChange={this.handleChange} type='text' />
                                            </div>
                                        </div> */}
                                            {/* <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        Date of Visit</label>
                                                    <input onChange={this.handleChange} name="visit_date" value={this.state.visit_date} className='form-control' type='date' />
                                                </div>
                                            </div> */}
                                            <div className='col-md-12 rating-box '>
                                                <div className="row">
                                                    <div className='col-12 col-md-6'>
                                                        
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Teachers :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_teachers"
                                                                starCount={5}
                                                                value={this.state.qty_teachers}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Teaching Methods :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_teaching"
                                                                starCount={5}
                                                                value={this.state.qty_teaching}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Other Staff Interaction :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_interaction"
                                                                starCount={5}
                                                                value={this.state.qty_interaction}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Child Safety :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="child_safety"
                                                                starCount={5}
                                                                value={this.state.child_safety}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Information @ Website :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_info_website"
                                                                starCount={5}
                                                                value={this.state.qty_info_website}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Campus &amp; Facilities :</span>
                                                            <Rating className="col-5 col-md-4 col-5 col-md-4"
                                                                name="facilities"
                                                                starCount={5}
                                                                value={this.state.facilities}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Transport arrangement :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="transport_arrange"
                                                                starCount={5}
                                                                value={this.state.transport_arrange}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Teacher : Students Ratio :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="teacher_student_ratio"
                                                                starCount={5}
                                                                value={this.state.teacher_student_ratio}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Overall Rating :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="overall_expirence"
                                                                starCount={5}
                                                                value={this.state.overall_expirence}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <div className=''>Note : Review options displayed based on  teaching location</div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='r-text '>Other Comments (60 Words)</label>
                                                    <textarea
                                                        className='form-control'
                                                        required
                                                        id='message-text'
                                                        rows='4'
                                                        cols='50'
                                                        value={this.state.other_comment}
                                                        name="other_comment"
                                                        onChange={this.handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='modal-footer mob-btn-row' style={{ border: 'none' }}>
                                        <button type='button' className='btn btn-default' data-dismiss='modal' aria-label='Close'>
                                            Cancel </button>
                                        <input
                                            type='submit'
                                            name='submit'
                                            value='Submit'
                                            className='btn btn-primary'
                                        />
                                    </div>
                                </form>
                            </div>

                            <div className='reviewFirstMessage' id="firstReviewMessage">

                                <div class="imagecenter">
                                    <img src={firstreview} className="firstreview" alt="First Review" />
                                </div>
                                <div class="imagecenter">
                                    <br />
                                    <p>Thank you for your your first review. </p>
                                    <p>You are now automatically included in the <span class="boldblue">‘Book Bonanza - Be Lucky, Get Booky Lucky!’</span> Draw contest!</p>
                                    <p>You can be a <i>lucky winner</i>, selected from a live, unbiased lucky draw.</p>
                                    <p>Stay tuned for more updates on social media!</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <Modal isOpen={this.state.info_pop} centered size='lg'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={this.closepop}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>
                        {preventContent()}
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}

class ReviewPlayArea extends React.Component {
    constructor() {
        super();
        this.state = {
            covid19: '',
            qty_staff: '',
            cleanliness: '',
            child_safety: '',
            facilities_parent: '',
            food: '',
            facilities_location: '',
            qty_info_website: '',
            crowd_size: '',
            overall_expirence: '',
            other_comment: '',
            visit_date: '',
            child_name: '',
            commets_show: 1,
            info_pop: false,
            logged_auth: localStorage.getItem('logged_auth') ? localStorage.getItem('logged_auth') : '',
        };
    }

    handleChange = (e) => {
        const propName = e.target.name;
        var propValue = e.target.value;
        if (propName == 'other_comment') {
            let words = propValue.split(" ");
            if (words.length > wordCount) {
                let toastr = '';
                words.length = wordCount;
                propValue = words.join(" ");
                toastr = window.toastr.error;
                toastr('', errorWords, {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            }
        }
        this.setState({ [propName]: propValue });
    };
    componentDidMount() {
        let THIS = this;
        $(document).on('click', '.prevent-pop', function () {
            THIS.setState({
                info_pop: true
            })
        })
    }
    closepop = () => {
        this.setState({
            info_pop: false
        })
    }
    handleSelect = (e) => {
        const propName = e.target.name;
        const value = [...e.target.selectedOptions].map((item) => item.value);
        this.setState({ [propName]: value });
    }

    handleClick = (e) => {
        // console.log(e.target.name)
        const propName = e.target.name;
        this.setState(prevState => ({
            [propName]: !prevState[propName]
        }));
    }

    onStarClick(nextValue, prevValue, name) {
        let probName = name;
        this.setState({ [probName]: nextValue });
    }

    reviewSubmit = (e) => {
        let total = parseInt(this.state.covid19) + parseInt(this.state.qty_staff) +
            parseInt(this.state.cleanliness) + parseInt(this.state.child_safety) + parseInt(this.state.facilities_parent)
            + parseInt(this.state.food) + parseInt(this.state.facilities_location) + parseInt(this.state.qty_info_website)
            + parseInt(this.state.crowd_size) + parseInt(this.state.overall_expirence);
        e.preventDefault();
        if (this.state.overall_expirence == '') {
            let toastr = '';
            toastr = window.toastr.error; //warning
            toastr('Required', 'Enter Overall Rating', {
                positionClass: "toast-bottom-center",
                containerId: "toast-bottom-center"
            });
            return false;
        }
        swal({
            title: "Are you sure to submit the Reviews?",
            text: "Overall rating is compulsory, would be good to have ratings for every element!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let reviewData = JSON.stringify({
                        category_id: this.props.data.keyword,
                        review_id: this.props.data.review_id,
                        user_id: this.props.data.user_id,
                        vendor_id: this.props.data.vendor_id,
                        child_name: this.state.child_name,
                        covid19: this.state.covid19,
                        qty_staff: this.state.qty_staff,
                        cleanliness: this.state.cleanliness,
                        child_safety: this.state.child_safety,
                        facilities_parent: this.state.facilities_parent,
                        food: this.state.food,
                        facilities_location: this.state.facilities_location,
                        qty_info_website: this.state.qty_info_website,
                        crowd_size: this.state.crowd_size,
                        overall_expirence: this.state.overall_expirence,
                        other_comment: this.state.other_comment,
                        visit_date: this.state.visit_date,
                        commets_show: this.state.commets_show,
                        total: total
                    });
                    let toastr = '';
                    ratingReview(reviewData).then((response) => {
                        // console.log('status', response)
                        if (response.status == 1) {

                            toastr = window.toastr.success;
                            toastr('Success', 'Thanks for Reviews!..', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                            setTimeout(function () {
                                window.location.reload();
                            }, 1000);
                        } else {
                            toastr = window.toastr.error; //warning
                            toastr('Error', 'Something went wrong. try again!...', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                        }
                    });
                }
            });
    }



    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div data-toggle="modal" data-target="#reviewModal" id='review-btn'>
                    <div className="banner-btn prevent-pop">
                        <div className="btn-icon">
                            <img src={BASE_URL + 'assets/icons/review.png'} alt='img' />
                        </div>
                        <div className="text-btn">
                            Write a Review
                        </div>
                    </div>
                </div>
                <div
                    className='modal fade review-modal'
                    id='reviewModal'
                >
                    <div className='modal-dialog model-box modal-lg' >
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h3 className='modal-title r-text'>Review</h3>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div id="formContent">
                                <form method='post'
                                    onSubmit={this.reviewSubmit}
                                    autoComplete='off'>
                                    <div className='modal-body'>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>Customer Rating</h5>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        Date of Visit</label>
                                                    <input onChange={this.handleChange} name="visit_date" value={this.state.visit_date} className='form-control' type='date' />
                                                </div>
                                            </div> */}
                                            <div className='col-md-12 rating-box '>
                                                <div className="row">
                                                    <div className='col-12 col-md-6'>
                                                        
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Quality of Staff :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_staff"
                                                                starCount={5}
                                                                value={this.state.qty_staff}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Cleanliness :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="cleanliness"
                                                                starCount={5}
                                                                value={this.state.cleanliness}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Child Safety :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="child_safety"
                                                                starCount={5}
                                                                value={this.state.child_safety}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Facilities for parents :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="facilities_parent"
                                                                starCount={5}
                                                                value={this.state.facilities_parent}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Food Quality :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="food"
                                                                starCount={5}
                                                                value={this.state.food}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Crowd size :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="crowd_size"
                                                                starCount={5}
                                                                value={this.state.crowd_size}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Overall Rating :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="overall_expirence"
                                                                starCount={5}
                                                                value={this.state.overall_expirence}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <div className=''>Note : Review options displayed based on  teaching location</div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='r-text '>Other Comments (60 Words)</label>
                                                    <textarea
                                                        className='form-control'
                                                        id='message-text'
                                                        required
                                                        rows='4'
                                                        cols='50'
                                                        value={this.state.other_comment}
                                                        name="other_comment"
                                                        onChange={this.handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='modal-footer mob-btn-row' style={{ border: 'none' }}>
                                        <button type='button' className='btn btn-default' data-dismiss='modal' aria-label='Close'>
                                            Cancel </button>
                                        <input
                                            type='submit'
                                            name='submit'
                                            value='Submit'
                                            className='btn btn-primary'
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className='reviewFirstMessage' id="firstReviewMessage">

                                <div class="imagecenter">
                                    <img src={firstreview} className="firstreview" alt="First Review" />
                                </div>
                                <div class="imagecenter">
                                    <br />
                                    <p>Thank you for your your first review. </p>
                                    <p>You are now automatically included in the <span class="boldblue">‘Book Bonanza - Be Lucky, Get Booky Lucky!’</span> Draw contest!</p>
                                    <p>You can be a <i>lucky winner</i>, selected from a live, unbiased lucky draw.</p>
                                    <p>Stay tuned for more updates on social media!</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.info_pop} centered size='lg'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={this.closepop}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>

                        {preventContent()}
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}

class ReviewHobbies extends React.Component {
    constructor() {
        super();
        this.state = {
            partner_type: 1,
            covid19: '',
            qty_teachers: '',
            qty_teaching: '',
            qty_info_website: '',
            child_safety: '',
            facilities_parent: '',
            campus_facilities: '',
            qty_intraction: '',
            batch_size: '',
            overall_expirence: '',
            other_comment: '',
            visit_date: '',
            child_name: '',
            ontime_punctuality: '',
            commets_show: 1,
            info_pop: false,
            logged_auth: localStorage.getItem('logged_auth') ? localStorage.getItem('logged_auth') : '',
        };
    }
    componentDidMount() {
        this.setState({
            partner_type: this.props.data.partner_type
        })
        let THIS = this;
        $(document).on('click', '.prevent-pop', function () {
            THIS.setState({
                info_pop: true
            })
        })
    }
    closepop = () => {
        this.setState({
            info_pop: false
        })
    }
    handleChange = (e) => {
        const propName = e.target.name;
        var propValue = e.target.value;
        if (propName == 'other_comment') {
            let words = propValue.split(" ");
            if (words.length > wordCount) {
                let toastr = '';
                words.length = wordCount;
                propValue = words.join(" ");
                toastr = window.toastr.error;
                toastr('', errorWords, {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            }
        }
        this.setState({ [propName]: propValue });
    };

    handleSelect = (e) => {
        const propName = e.target.name;
        const value = [...e.target.selectedOptions].map((item) => item.value);
        this.setState({ [propName]: value });
    }

    handleClick = (e) => {
        // console.log(e.target.name)
        const propName = e.target.name;
        this.setState(prevState => ({
            [propName]: !prevState[propName]
        }));
    }

    onStarClick(nextValue, prevValue, name) {
        let probName = name;
        this.setState({ [probName]: nextValue });
    }

    reviewSubmit = (e) => {

        e.preventDefault();
        if (this.state.overall_expirence == '') {
            let toastr = '';
            toastr = window.toastr.error; //warning
            toastr('Required', 'Enter Overall Rating', {
                positionClass: "toast-bottom-center",
                containerId: "toast-bottom-center"
            });
            return false;
        }
        let total = parseInt(this.state.covid19) + parseInt(this.state.qty_teachers) + parseInt(this.state.qty_teaching)
            + parseInt(this.state.qty_info_website) + parseInt(this.state.child_safety) + parseInt(this.state.facilities_parent)
            + parseInt(this.state.campus_facilities) + parseInt(this.state.qty_intraction)
            + parseInt(this.state.batch_size) + parseInt(this.state.overall_expirence);
        let toastr = '';
        swal({
            title: "Are you sure to submit the Reviews?",
            text: "Overall rating is compulsory, would be good to have ratings for every element!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let reviewData = JSON.stringify({
                        category_id: this.props.data.keyword,
                        review_id: this.props.data.review_id,
                        user_id: this.props.data.user_id,
                        vendor_id: this.props.data.vendor_id,
                        child_name: this.state.child_name,
                        covid19: this.state.covid19,
                        qty_teachers: this.state.qty_teachers,
                        qty_teaching: this.state.qty_teaching,
                        qty_info_website: this.state.qty_info_website,
                        child_safety: this.state.child_safety,
                        facilities_parent: this.state.facilities_parent,
                        campus_facilities: this.state.campus_facilities,
                        qty_intraction: this.state.qty_intraction,
                        batch_size: this.state.batch_size,
                        ontime_punctuality: this.state.ontime_punctuality,
                        overall_expirence: this.state.overall_expirence,
                        other_comment: this.state.other_comment,
                        visit_date: this.state.visit_date,
                        commets_show: this.state.commets_show,
                        total: total
                    });
                    ratingReview(reviewData).then((response) => {
                        // console.log('status', response)
                        if (response.status == 1) {

                            toastr = window.toastr.success;
                            toastr('Success', 'Thanks for Reviews!..', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                            setTimeout(function () {
                                window.location.reload();
                            }, 1000);
                        } else {
                            toastr = window.toastr.error; //warning
                            toastr('Error', 'Something went wrong. try again!...', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                        }
                    });
                }
            });
    }



    render() {
        console.log('review partner_type', this.state.partner_type)
        const partner_type = this.state.partner_type;
        return (
            <React.Fragment>
                <div data-toggle="modal" data-target="#reviewModal" id='review-btn'>
                    <div className="prevent-pop banner-btn">
                        <div className="btn-icon">
                            <img src={BASE_URL + 'assets/icons/review.png'} alt='img' />
                        </div>
                        <div className="text-btn">
                            Write a Review
                        </div>
                    </div>
                </div>
                <div
                    className='modal fade review-modal'
                    id='reviewModal'
                >
                    <div className='modal-dialog model-box modal-lg' >
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h3 className='modal-title r-text'>Review</h3>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div id="formContent">
                                <form method='post'
                                    onSubmit={this.reviewSubmit}
                                    autoComplete='off'>
                                    <div className='modal-body'>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>Customer Rating</h5>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        Date of Visit</label>
                                                    <input onChange={this.handleChange} name="visit_date" value={this.state.visit_date} className='form-control' type='date' />
                                                </div>
                                            </div> */}
                                            <div className='col-md-12 rating-box '>
                                                <div className="row">

                                                   
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">{partner_type == 1 ? ('Quality of Teachers / Coaches') : ('Comfort with Trainer / Teacher')} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_teachers"
                                                                starCount={5}
                                                                value={this.state.qty_teachers}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">{partner_type == 1 ? ('Quality of Teaching / Coaching') : ('Method of Teaching / Coaching / Counselling')} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_teaching"
                                                                starCount={5}
                                                                value={this.state.qty_teaching}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    {partner_type == 1 && (
                                                        <div className="col-12 col-md-6">
                                                            <p className='rating r-text row'>

                                                                <span className="col-7 col-md-8">Information @ Website :</span>
                                                                <Rating className="col-5 col-md-4"
                                                                    name="qty_info_website"
                                                                    starCount={5}
                                                                    value={this.state.qty_info_website}
                                                                    onStarClick={this.onStarClick.bind(this)}
                                                                />
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Child Safety :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="child_safety"
                                                                starCount={5}
                                                                value={this.state.child_safety}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">{partner_type == 1 ? ('Campus & Facilities') : ('Quality of Connectivity / Campus')} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="campus_facilities"
                                                                starCount={5}
                                                                value={this.state.campus_facilities}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Ease of Interaction :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_intraction"
                                                                starCount={5}
                                                                value={this.state.qty_intraction}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    {partner_type == 1 && (
                                                        <div className="col-12 col-md-6">
                                                            <p className='rating r-text row'>

                                                                <span className="col-7 col-md-8">Students per batch :</span>
                                                                <Rating className="col-5 col-md-4"
                                                                    name="batch_size"
                                                                    starCount={5}
                                                                    value={this.state.batch_size}
                                                                    onStarClick={this.onStarClick.bind(this)}
                                                                />
                                                            </p>
                                                        </div>
                                                    )}
                                                    {partner_type == 2 && (
                                                        <div className="col-12 col-md-6">
                                                            <p className='rating r-text row'>

                                                                <span className="col-7 col-md-8">Ontime / Punctuality :</span>
                                                                <Rating className="col-5 col-md-4"
                                                                    name="ontime_punctuality"
                                                                    starCount={5}
                                                                    value={this.state.ontime_punctuality}
                                                                    onStarClick={this.onStarClick.bind(this)}
                                                                />
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Overall Ratings / Experience :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="overall_expirence"
                                                                starCount={5}
                                                                value={this.state.overall_expirence}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <div className=''>Note : Review options displayed based on  teaching location</div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='r-text '>Other Comments (60 Words)</label>
                                                    <textarea
                                                        className='form-control'
                                                        required
                                                        id='message-text'
                                                        rows='4'
                                                        cols='50'
                                                        value={this.state.other_comment}
                                                        name="other_comment"
                                                        onChange={this.handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='modal-footer mob-btn-row' style={{ border: 'none' }}>
                                        <button type='button' className='btn btn-default' data-dismiss='modal' aria-label='Close'>
                                            Cancel </button>
                                        <input
                                            type='submit'
                                            name='submit'
                                            value='Submit'
                                            className='btn btn-primary'
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className='reviewFirstMessage' id="firstReviewMessage">

                                <div class="imagecenter">
                                    <img src={firstreview} className="firstreview" alt="First Review" />
                                </div>
                                <div class="imagecenter">
                                    <br />
                                    <p>Thank you for your your first review. </p>
                                    <p>You are now automatically included in the <span class="boldblue">‘Book Bonanza - Be Lucky, Get Booky Lucky!’</span> Draw contest!</p>
                                    <p>You can be a <i>lucky winner</i>, selected from a live, unbiased lucky draw.</p>
                                    <p>Stay tuned for more updates on social media!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.info_pop} centered size='lg'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={this.closepop}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>

                        {preventContent()}
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}

class ReviewTutions extends React.Component {
    constructor() {
        super();
        this.state = {
            partner_type: 1,
            covid19: '',
            qty_teachers: '',
            qty_teaching: '',
            child_safety: '',
            facilities: '',
            ontime_punctuality: '',
            qty_intraction: '',
            overall_expirence: '',
            other_comment: '',
            visit_date: '',
            child_name: '',
            commets_show: 1,
            info_pop: false,
            logged_auth: localStorage.getItem('logged_auth') ? localStorage.getItem('logged_auth') : '',
        };
    }
    componentDidMount() {
        this.setState({
            partner_type: this.props.data.partner_type
        })
        let THIS = this;
        $(document).on('click', '.prevent-pop', function () {
            THIS.setState({
                info_pop: true
            })
        })
    }
    closepop = () => {
        this.setState({
            info_pop: false
        })
    }
    handleChange = (e) => {
        const propName = e.target.name;
        var propValue = e.target.value;
        if (propName == 'other_comment') {
            let words = propValue.split(" ");
            if (words.length > wordCount) {
                let toastr = '';
                words.length = wordCount;
                propValue = words.join(" ");
                toastr = window.toastr.error;
                toastr('', errorWords, {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            }
        }
        this.setState({ [propName]: propValue });
    };

    handleSelect = (e) => {
        const propName = e.target.name;
        const value = [...e.target.selectedOptions].map((item) => item.value);
        this.setState({ [propName]: value });
    }

    handleClick = (e) => {
        // console.log(e.target.name)
        const propName = e.target.name;
        this.setState(prevState => ({
            [propName]: !prevState[propName]
        }));
    }

    onStarClick(nextValue, prevValue, name) {
        let probName = name;
        this.setState({ [probName]: nextValue });
    }

    reviewSubmit = (e) => {

        e.preventDefault();
        if (this.state.overall_expirence == '') {
            let toastr = '';
            toastr = window.toastr.error; //warning
            toastr('Required', 'Enter Overall Rating', {
                positionClass: "toast-bottom-center",
                containerId: "toast-bottom-center"
            });
            return false;
        }
        let total = parseInt(this.state.covid19) + parseInt(this.state.qty_teachers) + parseInt(this.state.qty_teaching)
            + parseInt(this.state.child_safety) + parseInt(this.state.facilities) + parseInt(this.state.ontime_punctuality)
            + parseInt(this.state.overall_expirence);
        let toastr = '';
        swal({
            title: "Are you sure to submit the Reviews?",
            text: "Overall rating is compulsory, would be good to have ratings for every element!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let reviewData = JSON.stringify({
                        category_id: this.props.data.keyword,
                        review_id: this.props.data.review_id,
                        vendor_id: this.props.data.vendor_id,
                        user_id: this.props.data.user_id,
                        child_name: this.state.child_name,
                        covid19: this.state.covid19,
                        qty_teachers: this.state.qty_teachers,
                        qty_teaching: this.state.qty_teaching,
                        child_safety: this.state.child_safety,
                        facilities: this.state.facilities,
                        ontime_punctuality: this.state.ontime_punctuality,
                        qty_intraction: this.state.qty_intraction,
                        overall_expirence: this.state.overall_expirence,
                        other_comment: this.state.other_comment,
                        visit_date: this.state.visit_date,
                        commets_show: this.state.commets_show,
                        total: total
                    });
                    ratingReview(reviewData).then((response) => {
                        // console.log('status', response)
                        if (response.status == 1) {

                            toastr = window.toastr.success;
                            toastr('Success', 'Thanks for Reviews!..', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                            setTimeout(function () {
                                window.location.reload();
                            }, 1000);
                        } else {
                            toastr = window.toastr.error; //warning
                            toastr('Error', 'Something went wrong. try again!...', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                        }
                    });
                }
            });
    }



    render() {
        const partner_type = this.state.partner_type;
        const category = this.props.data.keyword;
        return (
            <React.Fragment>
                <div data-toggle="modal" data-target="#reviewModal" id='review-btn'>
                    <div className="prevent-pop banner-btn">
                        <div className="btn-icon">
                            <img src={BASE_URL + 'assets/icons/review.png'} alt='img' />
                        </div>
                        <div className="text-btn">
                            Write a Review
                        </div>
                    </div>
                </div>
                <div
                    className='modal fade review-modal'
                    id='reviewModal'
                >
                    <div className='modal-dialog model-box modal-lg' >
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h3 className='modal-title r-text'>Review</h3>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div id="formContent">
                                <form method='post'
                                    onSubmit={this.reviewSubmit}
                                    autoComplete='off'>
                                    <div className='modal-body'>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>Customer Rating</h5>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        Date of Visit</label>
                                                    <input onChange={this.handleChange} name="visit_date" value={this.state.visit_date} className='form-control' type='date' />
                                                </div>
                                            </div> */}
                                            <div className='col-md-12 rating-box '>
                                                <div className="row">
                                                    
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">
                                                                {partner_type == 1 ?
                                                                    (
                                                                        category == 6 ?
                                                                            ('Quality of Teacher')
                                                                            :
                                                                            ('Counsellor')
                                                                    )
                                                                    :
                                                                    ('Comfort with Trainer / Teacher')
                                                                } :

                                                            </span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_teachers"
                                                                starCount={5}
                                                                value={this.state.qty_teachers}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">
                                                                {partner_type == 1 ?
                                                                    (
                                                                        category == 6 ?
                                                                            ('Quality of Teaching')
                                                                            :
                                                                            ('Counselling')
                                                                    )
                                                                    :
                                                                    ('Method of Teaching / Coaching / Counselling')
                                                                } :
                                                            </span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_teaching"
                                                                starCount={5}
                                                                value={this.state.qty_teaching}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Child Safety :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="child_safety"
                                                                starCount={5}
                                                                value={this.state.child_safety}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>

                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">
                                                                {partner_type == 1 ? 'Facilities & Connectivity'
                                                                    : 'Quality of Connectivity / Campus'} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="facilities"
                                                                starCount={5}
                                                                value={this.state.facilities}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">{partner_type == 1 ? ('Ontime / Punctuality') : ('Punctuality')} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="ontime_punctuality"
                                                                starCount={5}
                                                                value={this.state.ontime_punctuality}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">{
                                                                (category == 6 && partner_type == 1) ? ('Ease of Interaction') :
                                                                    partner_type == 1 ? ('Information @ Website') : 'Ease of Interaction'} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_intraction"
                                                                starCount={5}
                                                                value={this.state.qty_intraction}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Overall Rating :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="overall_expirence"
                                                                starCount={5}
                                                                value={this.state.overall_expirence}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <div className=''>Note : Review options displayed based on  teaching location</div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='r-text '>Other Comments (60 Words)</label>
                                                    <textarea
                                                        className='form-control'
                                                        required
                                                        id='message-text'
                                                        rows='4'
                                                        cols='50'
                                                        value={this.state.other_comment}
                                                        name="other_comment"
                                                        onChange={this.handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='modal-footer mob-btn-row' style={{ border: 'none' }}>
                                        <button type='button' className='btn btn-default' data-dismiss='modal' aria-label='Close'>
                                            Cancel </button>
                                        <input
                                            type='submit'
                                            name='submit'
                                            value='Submit'
                                            className='btn btn-primary'
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className='reviewFirstMessage' id="firstReviewMessage">

                                <div class="imagecenter">
                                    <img src={firstreview} className="firstreview" alt="First Review" />
                                </div>
                                <div class="imagecenter">
                                    <br />
                                    <p>Thank you for your your first review. </p>
                                    <p>You are now automatically included in the <span class="boldblue">‘Book Bonanza - Be Lucky, Get Booky Lucky!’</span> Draw contest!</p>
                                    <p>You can be a <i>lucky winner</i>, selected from a live, unbiased lucky draw.</p>
                                    <p>Stay tuned for more updates on social media!</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.info_pop} centered size='lg'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={this.closepop}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>

                        {preventContent()}
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}
class ReviewCamp extends React.Component {
    constructor() {
        super();
        this.state = {
            partner_type: 1,
            covid19: '',
            qty_teachers: '',
            qty_teaching: '',
            qty_food: '',
            campus_facilities: '',
            child_safety: '',
            facilities_parents: '',
            qty_info_website: '',
            crowd_size: '',
            infrastructure: '',
            ontime_punctuality: '',
            overall_expirence: '',
            other_comment: '',
            visit_date: '',
            child_name: '',
            commets_show: 1,
            info_pop: false,
            logged_auth: localStorage.getItem('logged_auth') ? localStorage.getItem('logged_auth') : '',
        };
    }
    componentDidMount() {
        this.setState({
            partner_type: this.props.data.partner_type
        })
        let THIS = this;
        $(document).on('click', '.prevent-pop', function () {
            THIS.setState({
                info_pop: true
            })
        })
    }
    closepop = () => {
        this.setState({
            info_pop: false
        })
    }

    handleChange = (e) => {
        const propName = e.target.name;
        var propValue = e.target.value;
        if (propName == 'other_comment') {
            let words = propValue.split(" ");
            if (words.length > wordCount) {
                let toastr = '';
                words.length = wordCount;
                propValue = words.join(" ");
                toastr = window.toastr.error;
                toastr('', errorWords, {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            }
        }
        this.setState({ [propName]: propValue });
    };

    handleSelect = (e) => {
        const propName = e.target.name;
        const value = [...e.target.selectedOptions].map((item) => item.value);
        this.setState({ [propName]: value });
    }

    handleClick = (e) => {
        // console.log(e.target.name)
        const propName = e.target.name;
        this.setState(prevState => ({
            [propName]: !prevState[propName]
        }));
    }

    onStarClick(nextValue, prevValue, name) {
        let probName = name;
        this.setState({ [probName]: nextValue });
    }

    reviewSubmit = (e) => {

        e.preventDefault();
        if (this.state.overall_expirence == '') {
            let toastr = '';
            toastr = window.toastr.error; //warning
            toastr('Required', 'Enter Overall Rating', {
                positionClass: "toast-bottom-center",
                containerId: "toast-bottom-center"
            });
            return false;
        }
        let total = parseInt(this.state.covid19) + parseInt(this.state.qty_teachers) + parseInt(this.state.qty_teaching)
            + parseInt(this.state.qty_food) + parseInt(this.state.campus_facilities) + parseInt(this.state.child_safety)
            + parseInt(this.state.facilities_parents) + parseInt(this.state.qty_info_website) +
            parseInt(this.state.crowd_size) + parseInt(this.state.infrastructure) + parseInt(this.state.transport_arrange)
            + parseInt(this.state.overall_expirence);
        console.log(total);
        let toastr = '';
        swal({
            title: "Are you sure to submit the Reviews?",
            text: "Overall rating is compulsory, would be good to have ratings for every element!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let reviewData = JSON.stringify({
                        category_id: this.props.data.keyword,
                        review_id: this.props.data.review_id,
                        user_id: this.props.data.user_id,
                        vendor_id: this.props.data.vendor_id,
                        child_name: this.state.child_name,
                        covid19: this.state.covid19,
                        qty_teachers: this.state.qty_teachers,
                        qty_teaching: this.state.qty_teaching,
                        qty_food: this.state.qty_food,
                        campus_facilities: this.state.campus_facilities,
                        child_safety: this.state.child_safety,
                        facilities_parents: this.state.facilities_parents,
                        qty_info_website: this.state.qty_info_website,
                        crowd_size: this.state.crowd_size,
                        infrastructure: this.state.infrastructure,
                        transport_arrange: this.state.transport_arrange,
                        ontime_punctuality: this.state.ontime_punctuality,
                        overall_expirence: this.state.overall_expirence,
                        other_comment: this.state.other_comment,
                        visit_date: this.state.visit_date,
                        commets_show: this.state.commets_show,
                        total: total
                    });
                    ratingReview(reviewData).then((response) => {
                        // console.log('status', response)
                        if (response.status == 1) {

                            toastr = window.toastr.success;
                            toastr('Success', 'Thanks for Reviews!..', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                            setTimeout(function () {
                                window.location.reload();
                            }, 1000);
                        } else {
                            toastr = window.toastr.error; //warning
                            toastr('Error', 'Something went wrong. try again!...', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                        }
                    });
                }
            });
    }



    render() {
        const partner_type = this.state.partner_type;
        return (
            <React.Fragment>
                <div data-toggle="modal" data-target="#reviewModal" id='review-btn'>
                    <div className="prevent-pop banner-btn">
                        <div className="btn-icon">
                            <img src={BASE_URL + 'assets/icons/review.png'} alt='img' />
                        </div>
                        <div className="text-btn">
                            Write a Review
                        </div>
                    </div>
                </div>
                <div
                    className='modal fade review-modal'
                    id='reviewModal'
                >
                    <div className='modal-dialog model-box modal-lg' >
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h3 className='modal-title r-text'>Review</h3>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div id="formContent">
                                <form method='post'
                                    onSubmit={this.reviewSubmit}
                                    autoComplete='off'>
                                    <div className='modal-body'>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>Customer Rating</h5>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        Date of Visit</label>
                                                    <input onChange={this.handleChange} name="visit_date" value={this.state.visit_date} className='form-control' type='date' />
                                                </div>
                                            </div> */}
                                            <div className='col-md-12 rating-box '>
                                                <div className="row">
                                                   
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">{partner_type == 1 ? 'Quality of Teachers / Coaches' : 'Comfort with Trainer / Teacher'} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_teachers"
                                                                starCount={5}
                                                                value={this.state.qty_teachers}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">{partner_type == 1 ? 'Method of Teaching / Coaching' : 'Method of Teaching / Coaching / Counselling'} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_teaching"
                                                                starCount={5}
                                                                value={this.state.qty_teaching}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">{partner_type == 1 ? 'Facilities & Connectivity' : 'Quality of Connectivity / Campus'} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="campus_facilities"
                                                                starCount={5}
                                                                value={this.state.campus_facilities}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Child Safety :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="child_safety"
                                                                starCount={5}
                                                                value={this.state.child_safety}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">{partner_type == 1 ? 'Information @ Website' : 'Ease of Interactions'} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_info_website"
                                                                starCount={5}
                                                                value={this.state.qty_info_website}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    {partner_type == 1 && (
                                                        <div className='col-12 col-md-6'>
                                                            <p className='rating r-text row'>

                                                                <span className="col-7 col-md-8">Students per batch : </span>
                                                                <Rating className="col-5 col-md-4"
                                                                    name="crowd_size"
                                                                    starCount={5}
                                                                    value={this.state.crowd_size}
                                                                    onStarClick={this.onStarClick.bind(this)}
                                                                />
                                                            </p>
                                                        </div>
                                                    )}
                                                    {partner_type == 2 && (
                                                        <div className='col-12 col-md-6'>
                                                            <p className='rating r-text row'>

                                                                <span className="col-7 col-md-8">Punctuality :</span>
                                                                <Rating className="col-5 col-md-4"
                                                                    name="ontime_punctuality"
                                                                    starCount={5}
                                                                    value={this.state.ontime_punctuality}
                                                                    onStarClick={this.onStarClick.bind(this)}
                                                                />
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Overall Ratings / Experience :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="overall_expirence"
                                                                starCount={5}
                                                                value={this.state.overall_expirence}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <div className=''>Note : Review options displayed based on  teaching location</div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='r-text '>Other Comments (60 Words)</label>
                                                    <textarea
                                                        className='form-control'
                                                        required
                                                        id='message-text'
                                                        rows='4'
                                                        cols='50'
                                                        value={this.state.other_comment}
                                                        name="other_comment"
                                                        onChange={this.handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='modal-footer mob-btn-row' style={{ border: 'none' }}>
                                        <button type='button' className='btn btn-default' data-dismiss='modal' aria-label='Close'>
                                            Cancel </button>
                                        <input
                                            type='submit'
                                            name='submit'
                                            value='Submit'
                                            className='btn btn-primary'
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className='reviewFirstMessage' id="firstReviewMessage">

                                <div class="imagecenter">
                                    <img src={firstreview} className="firstreview" alt="First Review" />
                                </div>
                                <div class="imagecenter">
                                    <br />
                                    <p>Thank you for your your first review. </p>
                                    <p>You are now automatically included in the <span class="boldblue">‘Book Bonanza - Be Lucky, Get Booky Lucky!’</span> Draw contest!</p>
                                    <p>You can be a <i>lucky winner</i>, selected from a live, unbiased lucky draw.</p>
                                    <p>Stay tuned for more updates on social media!</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.info_pop} centered size='lg'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={this.closepop}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>

                        {preventContent()}
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}
class ReviewEvents extends React.Component {
    constructor() {
        super();
        this.state = {
            user_type: 1,
            covid19: '',
            qty_food: '',
            qty_info_website: '',
            child_safety: '',
            qty_intraction: '',
            qty_method: '',
            ontime_punctuality: '',
            overall_expirence: '',
            other_comment: '',
            visit_date: '',
            child_name: '',
            commets_show: 1,
            info_pop: false,
            logged_auth: localStorage.getItem('logged_auth') ? localStorage.getItem('logged_auth') : '',
        };
    }
    componentDidMount() {
        this.setState({
            user_type: this.props.data.partner_type
        })
        let THIS = this;
        $(document).on('click', '.prevent-pop', function () {
            THIS.setState({
                info_pop: true
            })
        })
    }
    closepop = () => {
        this.setState({
            info_pop: false
        })
    }
    handleChange = (e) => {
        const propName = e.target.name;
        var propValue = e.target.value;
        if (propName == 'other_comment') {
            let words = propValue.split(" ");
            if (words.length > wordCount) {
                let toastr = '';
                words.length = wordCount;
                propValue = words.join(" ");
                toastr = window.toastr.error;
                toastr('', errorWords, {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
            }
        }
        this.setState({ [propName]: propValue });
    };

    handleSelect = (e) => {
        const propName = e.target.name;
        const value = [...e.target.selectedOptions].map((item) => item.value);
        this.setState({ [propName]: value });
    }

    handleClick = (e) => {
        // console.log(e.target.name)
        const propName = e.target.name;
        this.setState(prevState => ({
            [propName]: !prevState[propName]
        }));
    }

    onStarClick(nextValue, prevValue, name) {
        let probName = name;
        this.setState({ [probName]: nextValue });
    }

    reviewSubmit = (e) => {

        e.preventDefault();
        if (this.state.overall_expirence == '') {
            let toastr = '';
            toastr = window.toastr.error; //warning
            toastr('Required', 'Enter Overall Rating', {
                positionClass: "toast-bottom-center",
                containerId: "toast-bottom-center"
            });
            return false;
        }
        let total = parseInt(this.state.covid19) + parseInt(this.state.qty_food) + parseInt(this.state.qty_info_website)
            + parseInt(this.state.ontime_punctuality) + parseInt(this.state.overall_expirence);
        let toastr = '';
        swal({
            title: "Are you sure to submit the Reviews?",
            text: "Overall rating is compulsory, would be good to have ratings for every element!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    let reviewData = JSON.stringify({
                        category_id: this.props.data.keyword,
                        review_id: this.props.data.review_id,
                        vendor_id: this.props.data.vendor_id,
                        user_id: this.props.data.user_id,
                        child_name: this.state.child_name,
                        covid19: this.state.covid19,
                        qty_food: this.state.qty_food,
                        qty_info_website: this.state.qty_info_website,
                        ontime_punctuality: this.state.ontime_punctuality,
                        child_safety: this.state.child_safety,
                        qty_intraction: this.state.qty_intraction,
                        qty_method: this.state.qty_method,
                        overall_expirence: this.state.overall_expirence,
                        other_comment: this.state.other_comment,
                        visit_date: this.state.visit_date,
                        commets_show: this.state.commets_show,
                        total: total
                    });
                    ratingReview(reviewData).then((response) => {
                        // console.log('status', response)
                        if (response.status == 1) {

                            toastr = window.toastr.success;
                            toastr('Success', 'Thanks for Reviews!..', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                            setTimeout(function () {
                                window.location.reload();
                            }, 1000);
                        } else {
                            toastr = window.toastr.error; //warning
                            toastr('Error', 'Something went wrong. try again!...', {
                                positionClass: "toast-bottom-center",
                                containerId: "toast-bottom-center"
                            });
                        }
                    });
                }
            });

    }



    render() {
        const { user_type } = this.state;
        return (
            <React.Fragment>
                <div data-toggle="modal" data-target="#reviewModal" id='review-btn'>
                    <div className="prevent-pop banner-btn">
                        <div className="btn-icon">
                            <img src={BASE_URL + 'assets/icons/review.png'} alt='img' />
                        </div>
                        <div className="text-btn">
                            Write a Review
                        </div>
                    </div>
                </div>
                <div
                    className='modal fade review-modal'
                    id='reviewModal'
                >
                    <div className='modal-dialog model-box modal-lg' >
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h3 className='modal-title r-text'>Review</h3>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div id="formContent">
                                <form method='post'
                                    onSubmit={this.reviewSubmit}
                                    autoComplete='off'>
                                    <div className='modal-body'>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <h5>Customer Rating</h5>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        Date of Visit</label>
                                                    <input onChange={this.handleChange} name="visit_date" value={this.state.visit_date} className='form-control' type='date' />
                                                </div>
                                            </div> */}
                                            <div className='col-md-12 rating-box '>
                                                <div className="row">
                                                    

                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>
                                                            <span className={user_type == 1 ? 'col-7 col-md-8' : 'col-7 col-md-8 review-disable'}>
                                                                {user_type == 1 ? 'Quality of service' : 'Comfort with Trainer / Teacher'} :
                                                            </span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_food"
                                                                starCount={5}
                                                                value={this.state.qty_food}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    {user_type == 2 && (
                                                        <div className='col-12 col-md-6'>
                                                            <p className='rating r-text row'>
                                                                <span className={user_type == 1 ? 'col-7 col-md-8' : 'col-7 col-md-8 review-disable'}>Method of Teaching / Coaching / Counselling :</span>
                                                                <Rating className="col-5 col-md-4"
                                                                    name="qty_method"
                                                                    starCount={5}
                                                                    value={this.state.qty_method}
                                                                    onStarClick={this.onStarClick.bind(this)}
                                                                />
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">{user_type == 1 ? 'Information @ Website' : 'Quality of Connectivity / Campus'} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_info_website"
                                                                starCount={5}
                                                                value={this.state.qty_info_website}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    {user_type == 2 && (
                                                        <div className='col-12 col-md-6'>
                                                            <p className='rating r-text row'>

                                                                <span className={user_type == 1 ? 'col-7 col-md-8' : 'col-7 col-md-8 review-disable'}>Child Safety :</span>
                                                                <Rating className="col-5 col-md-4"
                                                                    name="child_safety"
                                                                    starCount={5}
                                                                    value={this.state.child_safety}
                                                                    onStarClick={this.onStarClick.bind(this)}
                                                                />
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Ease of Interaction :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="qty_intraction"
                                                                starCount={5}
                                                                value={this.state.qty_intraction}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">{user_type == 1 ? 'Puntcuality / Connectivity' : 'Puntcuality'} :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="ontime_punctuality"
                                                                starCount={5}
                                                                value={this.state.ontime_punctuality}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Overall Ratings :</span>
                                                            <Rating className="col-5 col-md-4"
                                                                name="overall_expirence"
                                                                starCount={5}
                                                                value={this.state.overall_expirence}
                                                                onStarClick={this.onStarClick.bind(this)}
                                                            />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12 mb-3'>
                                                <div className=''>Note : Review options displayed based on  teaching location</div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='r-text '>Other Comments (60 Words)</label>
                                                    <textarea
                                                        className='form-control'
                                                        required
                                                        id='message-text'
                                                        rows='4'
                                                        cols='50'
                                                        value={this.state.other_comment}
                                                        name="other_comment"
                                                        onChange={this.handleChange}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='modal-footer mob-btn-row' style={{ border: 'none' }}>
                                        <button type='button' className='btn btn-default' data-dismiss='modal' aria-label='Close'>
                                            Cancel </button>
                                        <input
                                            type='submit'
                                            name='submit'
                                            value='Submit'
                                            className='btn btn-primary'
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className='reviewFirstMessage' id="firstReviewMessage">

                                <div class="imagecenter">
                                    <img src={firstreview} className="firstreview" alt="First Review" />
                                </div>
                                <div class="imagecenter">
                                    <br />
                                    <p>Thank you for your your first review. </p>
                                    <p>You are now automatically included in the <span class="boldblue">‘Book Bonanza - Be Lucky, Get Booky Lucky!’</span> Draw contest!</p>
                                    <p>You can be a <i>lucky winner</i>, selected from a live, unbiased lucky draw.</p>
                                    <p>Stay tuned for more updates on social media!</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.info_pop} centered size='lg'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={this.closepop}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>

                        {preventContent()}
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}

class ReviewComponent extends React.Component {
    constructor() {
        super();

        this.state = {
            rating: 1
        };
    }

    onStarClick(nextValue, prevValue, name) {
        this.setState({ rating: nextValue });
    }

    render() {

        return (
            <React.Fragment>
                <div data-toggle="modal" data-target="#reviewModal">
                    <div className="banner-btn">
                        <div className="btn-icon">
                            <img src={BASE_URL + 'assets/icons/review.png'} alt='img' />
                        </div>
                        <div className="text-btn">
                            Write a Review
                        </div>
                    </div>
                </div>
                <div
                    className='modal fade review-modal'
                    id='reviewModal'
                >
                    <div className='modal-dialog model-box modal-md' >
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h3 className='modal-title r-text'>
                                    Review
                                </h3>
                                <button
                                    type='button'
                                    className='close'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <span aria-hidden='true'>&times;</span>
                                </button>
                            </div>
                            <div className='modal-body'>
                                <div id="formContent">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        Name of the Children
                                                    </label>
                                                    <input className='form-control' type='text' />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        Grade
                                                    </label>
                                                    <div className='form-group'>
                                                        <select className='form-control' id='sel1'>
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className='form-group'>
                                                    <label className='login-label r-text'>
                                                        Date of Visit
                                                    </label>
                                                    <input className='form-control' type='date' />
                                                </div>
                                            </div>
                                            <div className='col-md-12 rating-box '>
                                                <div className="row">
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Overall :</span>
                                                            <div className="col-5 col-md-4">
                                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                            </div>
                                                        </p>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Infrastructure:</span>
                                                            <div className="col-5 col-md-4">
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                            </div>
                                                        </p>
                                                        <p className='rating r-text row'>

                                                            <span className="col-7 col-md-8">Academics :</span>
                                                            <div className="col-5 col-md-4">
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                            </div>
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Sports : </span>
                                                            <div className="col-5 col-md-4">
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                            </div>
                                                        </p>
                                                        <p className='rating r-text row'>
                                                            <span className="col-7 col-md-8">Faculty : </span>
                                                            <div className="col-5 col-md-4">
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                            </div>
                                                        </p>
                                                        <p className='rating r-text'>

                                                            <span className="col-7 col-md-8">Safety : </span>
                                                            <div className="col-5 col-md-4">
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                                <i className='far fa-star'></i>
                                                            </div>

                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className='form-group'>
                                                    <label className='r-text '>Comments</label>
                                                    <textarea
                                                        className='form-control'
                                                        id='message-text'
                                                        rows='4'
                                                        cols='50'
                                                    ></textarea>
                                                </div>
                                                <div className='form-group'>
                                                    <div className="flex-item mb-4">
                                                        <a href="https://www.facebook.com/bewise.in" target='_blank'>
                                                            <img src={facebook} className="social-icons" alt="Facebook Icon" />
                                                        </a>
                                                        <a href="https://www.linkedin.com/company/bewise-in" target='_blank'>
                                                            <img src={linkedin} className="social-icons" alt="Linkedin Icon" />
                                                        </a>

                                                        <a href="https://www.instagram.com/bewise_in/" target='_blank'>
                                                            <img src={instagram} className="social-icons" alt="Instagram Icon" />
                                                        </a>
                                                        <a href="https://twitter.com/BewiseIn" target='_blank'>
                                                            <img src={twitter} className="social-icons" alt="Twitter Icon" />
                                                        </a>
                                                        <a href="mailto:contact@bewise.in">
                                                            <img src={mailus} className="social-icons" alt="E-Mail Icon" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='modal-footer mob-btn-row' style={{ border: 'none' }}>
                                            <button type='button' className='btn btn-default' data-dismiss='modal' aria-label='Close'>
                                                Cancel </button>
                                            <input
                                                type='submit'
                                                name='submit'
                                                value='Submit'
                                                className='btn btn-primary'
                                            />
                                        </div>

                                    </form>
                                </div>

                                <div className='reviewFirstMessage' id="firstReviewMessage">

                                    <div class="imagecenter">
                                        <img src={firstreview} className="firstreview" alt="First Review" />
                                    </div>
                                    <div class="imagecenter">
                                        <br />
                                        <p>Thank you for your your first review. </p>
                                        <p>You are now automatically included in the <span class="boldblue">‘Book Bonanza - Be Lucky, Get Booky Lucky!’</span> Draw contest!</p>
                                        <p>You can be a <i>lucky winner</i>, selected from a live, unbiased lucky draw.</p>
                                        <p>Stay tuned for more updates on social media!</p>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
export default { ReviewComponent }
export {ReviewEvents}
export {ReviewSchools}
export {ReviewPlayArea}
export {ReviewHobbies}
export {ReviewTutions}
export {ReviewCamp}
