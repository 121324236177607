import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import {
    getSearchList
} from '../../API/FetchCall';
class HomeSearchMobile extends React.Component {
    constructor() {
        super();
        console.log('this.props.type', this.props);
        this.state = {
            suggestions: [],
            // type: this.props.type,
            value: localStorage.getItem("searchdata") ? localStorage.getItem("searchdata") : '',
            current_location: localStorage.getItem("current_location") ? localStorage.getItem("current_location") : 'bengaluru',
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        var ignoreClickOnMeElement = document.getElementById('top-search-sec-d');
        let THIS = this;
        document.addEventListener('click', function (event) {
            var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
            if (!isClickInsideElement) {
                THIS.handleClickOutside();
            }
        });
    }
    handleClickOutside = () => {
        this.setState({
            showsuggest: false,
        })
    }
    escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }


    getSuggestions = async (value) => {
        const escapedValue = this.escapeRegexCharacters(value.trim());
        let list = await this.getSearchListFetch(escapedValue);
        var final = [];
        if (list.status === 1) {
            final = list.list
            this.setState({
                searchList: list.list
            })
        } else {
            final = [];
        }
        return final;
    }
    getSearchListFetch = (val) => {
        let data = JSON.stringify({
            keyword: val,
            city: this.state.current_location,
        })
        return getSearchList(data).then(response => response);
    }
    shouldRenderSuggestions = (value, reason) => {
        return value.trim().length > 2;
    };
    getSuggestionValue(suggestion) {
        return suggestion.name;
    }

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.name}</span>
        );
    }

    searchLink4 = (e) => {
        e.preventDefault();
        let search_text = document.getElementById("searchmob").value;

        this.setState({
            value: search_text
        });

        if (search_text.length > 3) {
            var search = search_text.split(' ').join('+');
            window.location.replace('/search/' + search);
        }
    }
    onSuggestionsFetchRequested = ({ value }) => {
        this.getSuggestions(value).then(response => {
            this.setState({
                suggestions: response
            });
        })
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    };
    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "",
            value,
            id: 'searchmob',
            class: 'custom-search-text',
            onChange: this.onChange
        };
        const inputPropsD = {
            placeholder: "Search for a school or an after school activity",
            value,
            class: 'd-search-text',
            id: 'searchmob',
            onChange: this.onChange
        };
        return (
            <React.Fragment>
                <div class="input-field second-wrap search-text-home" id='top-search-sec-m'>
                    <Autosuggest
                        suggestions={suggestions}
                        shouldRenderSuggestions={this.shouldRenderSuggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.renderSuggestion}
                        inputProps={inputPropsD} />
                    {/* <input id="search" type="text" placeholder="Search for a school or an after school activity" /> */}
                </div>
                <div class="input-field third-wrap">
                    <button class="btn-search" type="button" onClick={this.searchLink4}>
                        <svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                        </svg>
                    </button>
                </div>

            </React.Fragment>
        )
    }
}
export {
    HomeSearchMobile
}