import React, { Component } from 'react';
import { commonAPI } from '../../API/FetchCall';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { BASE_URL } from '../../constant';
import { send } from 'react-ga';

class Invite extends React.Component {
    constructor(props) {
        super(props);
        let data_auth = (localStorage.getItem("myData"));
        let auth_data = JSON.parse(data_auth);
        this.state = {
            share_url: this.props.param.url,
            share_name: this.props.param.share_name,
            category_name: this.props.param.School,
            user_data: auth_data,
            invite_mail: ''
        };
    }

    componentDidMount() {
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    invitePeople = (e) => {
        e.preventDefault();
        let sendData = JSON.stringify({
            email_id: this.state.invite_mail,
            share_url: this.state.share_url,
            share_name: this.state.share_name,
            user_name: this.state.user_data.first_name + ' ' + this.state.user_data.last_name,
            url: 'invite_people'
        });

        commonAPI(sendData).then(res => {
            if (res.status === 1) {
                let toastr = '';
                toastr = window.toastr.success;
                toastr('', 'Invitation sent successfully! </br>(Check SPAM folder too)', {
                    positionClass: "toast-bottom-center",
                    containerId: "toast-bottom-center"
                });
                this.setState({
                    invite_mail: ''
                })
            }
        });
    }
    render() {
        let gaFun = "ga(‘send’, ‘event’, " + this.state.category_name + ", 'Invite People','Invite People' , ’start’, {‘NonInteraction’: 1});";
        return (
            <React.Fragment>
                <div className='banner-btn-main col' id="PopoverLegacy" >
                    <div className="banner-btn">
                        <div className="btn-icon">
                            <img src={BASE_URL + 'assets/icons/invite.png'} alt='img' />
                        </div>
                        <div className="text-btn" onclick={gaFun}>
                            Invite People
                        </div>
                    </div>

                    <UncontrolledPopover trigger="legacy" placement="bottom" target="PopoverLegacy">
                        <PopoverBody>
                            <div className="invite-form">
                                <form onSubmit={this.invitePeople}>
                                    <div className="d-inline-block">
                                        <div className="d-inline-block">
                                            <div className='form-group'>
                                                <input
                                                    required
                                                    onChange={(e) => this.handleChange(e)}
                                                    className='form-control popover-input'
                                                    type='Email' placeholder="Enter Email"
                                                    name='invite_mail'
                                                    autoComplete='off'
                                                    value={this.state.invite_mail}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-inline-block">
                                            <div className='form-group'>
                                                <input type="submit" value='Submit' className='btn btn-danger btn-mt' />
                                                {/* <button onclick={(e) => this.invitePeople()} type='button' className='btn btn-danger btn-mt'>
                                                    Submit
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </PopoverBody>
                    </UncontrolledPopover>
                </div>
            </React.Fragment >
        );
    }
}
export { Invite };
