import React, { Component } from 'react';
import { getTrending, getinsightcontent } from '../../API/FetchCall';
import { BASE_URL } from '../../constant';
import { Modal, ModalBody, Row, Col } from 'reactstrap';

class RelatedTopics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: '',
            similarList: [],
            contentList: [],
            auth: false,
            currentTitle: '',
            currentDetail: '',
            location: localStorage.getItem('current_location') ? localStorage.getItem('current_location') : 'Bengaluru',
            showPop: false
        };
    }

    componentDidMount() {
        let THIS = this;
        let auth = localStorage.getItem("logged_auth");
        let data_auth = (localStorage.getItem("myData"));
        if (data_auth) {
            let auth_data = JSON.parse(data_auth)
            this.setState({
                logged_name: auth_data.first_name + ' ' + auth_data.last_name,
                authe: true,
                user_id: auth_data.user_id,
            });
        }
        this.topics();
        window.jQuery(document).on('click', '.more-pop', function () {
            let content = THIS.state.contentList;
            let filterData = content.filter((item) => item.id == window.jQuery(this).attr('id'));
            console.log(filterData);
            let data = filterData[0];
            THIS.setState({
                currentDetail: data.detail_content,
                currentTitle: data.title,
                showPop: true
            });
        });
    }
    topics = async () => {
        try {
            let req = JSON.stringify({
                role: 4,
                category: this.props.param.category_id
            })
            const response = await getinsightcontent(req);
            if (response.status === 1) {
                this.setState({ contentList: response.list });
            } else {
                this.setState({ contentList: [] });
            }

        } catch (error) {
            this.setState({ error: true });
        }
    };

    readMore(id) {
        let content = this.state.contentList;
        let filterData = content.filter((item) => item.id == id);
        console.log(filterData);
        let data = filterData[0];
        this.setState({
            currentDetail: data.detail_content,
            currentTitle: data.title,
            showPop: true
        })
    }
    closepop = (e) => {
        this.setState({ showPop: false });
    };
    render() {
        return (
            <React.Fragment>
                <div>

                    {/* related Topics start */}

                    <div className="left-side-box">
                        <div className="left-head trend-head"> Articles and Blogs </div>
                        <div className="left-side-body">
                            <ul className='related-topics'>
                                {this.state.contentList.map((content, i) => {
                                    let THIS = this;
                                    let cont = (content.title.length > 40) ? content.title.substring(0, 40) + '... ' : content.title;
                                    return (
                                        <React.Fragment>
                                            <div className='row insight-list-page'>
                                                <div className='col-md-3'>
                                                    {(content.banner_image != '') &&
                                                        (<img src={content.base_url + content.banner_image} className="insight-home-custome-img" alt='Banner Image' />)
                                                    }
                                                </div>
                                                <div className='col-md-9 relatedLi'>
                                                    <p className=''>{cont}</p>
                                                    <small> <a href={BASE_URL + 'articles-blogs/' + content.slug_term} target='_blank' className="" >Read More</a></small>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                        // <li className="relatedLi">
                                        //     <span className=''>{cont}</span>
                                        //     <small> <a href={'insight-details/' + content.insight_id} target='_blank' className="" >Read More</a></small>
                                        // </li>

                                    )
                                })}

                            </ul>
                        </div>
                    </div>
                </div>
                <Modal className="ipad-lg" isOpen={this.state.showPop} centered size='lg'>
                    <ModalBody className='p-0'>
                        <button
                            className='close-btn'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={this.closepop}
                        >
                            <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
                        </button>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle"> {this.state.currentTitle}</h5>
                            </div>
                            <div className="modal-body popup-insight-scroll" dangerouslySetInnerHTML={{ __html: this.state.currentDetail }}>

                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment >
        );
    }
}
export { RelatedTopics };
