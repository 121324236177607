import React, { Component } from 'react';
import { storeUserAgent, AddWistData } from './API/FetchCall';
import swal from 'sweetalert';

export const iplocation = (partner_id, category, user_id, pageid) => {
    fetch('https://ipapi.co/json/')
        .then(response => response.json())
        .then(data => {
            let ua = navigator.userAgent;
            let device = 'desktop';
            if (/(tablet|iPad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
                device = "tablet";
            }
            else if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
            ) {
                device = "mobile";
            }
            // alert(JSON.stringify(ua));
            let client_data = {
                ip_address: data.ip,
                country: data.country,
                location: data.city,
                device: device,
                user_id: user_id,
                vendor_id: partner_id,
                category: category,
                page_id: pageid
            };
            storeUserAgent(JSON.stringify(client_data)).then((response) => {
            });
        })
        .catch(error => {
            console.log('Error at fetching customer IP', error)
        });
};

export const addToWishList = (data) => {
    swal({
        text: "Do you want add to Wishlist",
        icon: "warning",
        buttons: true,
    }).then((value) => {
        if (value) {
            let reviewData = JSON.stringify({
                menuid: data.category_id,
                category: data.category,
                productid: data.id,
                rating: '4.1*',
                userid: data.user_id,
                vendor_id: data.vendor_id,
                full_url: data.full_url
            });
            AddWistData(reviewData).then((response) => {
                let toastr = '';
                if (response.status == 1) {
                    toastr = window.toastr.success; //warning
                    toastr('', response.message, {
                        positionClass: "toast-bottom-center",
                        containerId: "toast-bottom-center"
                    });
                }
            });
        }
    });

};
export const addToCompareList = (data) => {
    var compare_data = localStorage.getItem(data.keyword) ? JSON.parse(localStorage.getItem(data.keyword)) : [];
    let toastr = '';
    if (compare_data.length < 3) {
        let checkExist = compare_data.filter(o => o.id == data.id);
        if (checkExist.length > 0) {
            toastr = window.toastr.error; //warning
            toastr('', 'Already in compare list', {
                positionClass: "toast-bottom-center",
                containerId: "toast-bottom-center"
            });
        } else {
            swal({
                text: "Do you want add to Compare",
                icon: "warning",
                buttons: true,
            }).then((value) => {
                if (value) {
                    compare_data.push({
                        id: data.id,
                        type: data.type
                    });
                    localStorage.setItem(data.keyword, JSON.stringify(compare_data));
                    toastr = window.toastr.success;
                    toastr('', 'Added To compare list', {
                        positionClass: "toast-bottom-center",
                        containerId: "toast-bottom-center"
                    });
                }
            });
        }
    } else {
        toastr = window.toastr.error; //warning
        toastr('', 'Only allow 3 Data to compare', {
            positionClass: "toast-bottom-center",
            containerId: "toast-bottom-center"
        });
    }
};

export const partnerSocialLinks = (social_link_data) => {
    let dataHtml = '';
    social_link_data = social_link_data ? JSON.parse(social_link_data) : []
    social_link_data.map((item, i) => {
        let icon = '', link = 'javascript:;';
        let active_color = '';
        if (item.link)
            link = item.link;
        if (item.id == 'fb') {
            active_color = 'fb-clr';
            icon = `<a href='${link}' target='${item.link ? '_blank' : '_self'}'>
                                    <i class="custom-social-icon-view fa fa-facebook-square ${active_color}" aria-hidden="true"></i>
                                    </a>`;
        }
        if (item.id == 'li' && item.link) {
            active_color = 'li-clr';
            icon = `<a href='${link}' target='${item.link ? '_blank' : '_self'}'>
                                    <i class="custom-social-icon-view fa fa-linkedin-square ${active_color}" aria-hidden="true"></i>
                                    </a>`;
        }
        if (item.id == 'in' && item.link) {
            active_color = 'in-clr';
            icon = `<a href='${link}' target='${item.link ? '_blank' : '_self'}'>
                                    <i class="custom-social-icon-view fa fa-instagram ${active_color}" aria-hidden="true"></i>
                                    </a>`;
        }
        if (item.id == 'tw' && item.link) {
            active_color = 'tw-clr';
            icon = `<a href='${link}' target='${item.link ? '_blank' : '_self'}'>
                                    <i class="custom-social-icon-view fa fa-twitter-square ${active_color}" aria-hidden="true"></i>
                                    </a>`;
        }
        if (icon)
            dataHtml += `<span>${icon}</span>`;
    })
    return dataHtml;
};
