import React, { Component } from 'react';
import { ratingReview } from '../../API/FetchCall';
import { BASE_URL } from '../../constant';
import { IMG_URL, ADMIN_URL } from "../../constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 8000,
    dots: false,
    arrows: false
};

const settings2 = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    dots: false,
    arrows: false
};

const current_location = localStorage.getItem('current_location') ? localStorage.getItem('current_location').toLowerCase() : 'bengaluru';

const banner_arr = [
   
   
    
    {
        image: '/assets/images/ad-banner/112-600-180822-img-5.png',
        link: `/${current_location}/after-school-activity/sports`
    },
    
];

const banner_arr_rev = banner_arr.slice().reverse();


const banner_arr_afterschool = [


  /*AuroScholar / BeWise & DIYA – 8 sec rule for rotation) */

   {
    image: 'https://api.bewise.in/uploads/excl_bannerebhxvw.jpg',
    link: `https://www.bewise.in/exclusive-partnerships/skopickcom`
    },
   {
       image: '/assets/images/ad-banner/112-600-180822-img-5.png',
       link: `/${current_location}/after-school-activity/sports`
   },
   {
       image: 'https://api.bewise.in/uploads/excl_bannerqqsn1j.png',
       link: `https://www.bewise.in/exclusive-partnerships/auro-scholar`
   }
  
];

const banner_arr_afterschool_rev = banner_arr.slice().reverse();


class AfterSchoolRight extends React.Component {
    constructor() {
        super();
        this.state = {
            logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            url: '/signin?prev_path=/funlearning'
        };
    }
    componentDidMount() {
        if (this.state.logged_auth != false) {
            this.setState({
                url: '/funlearning'
            })
        }
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div className='row mb-5 landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_afterschool.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='row landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_afterschool_rev.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </React.Fragment >
        );
    }
}

const banner_arr_tuitions = [
        {
        image: 'https://api.bewise.in/uploads/excl_bannerebhxvw.jpg',
        link: `https://www.bewise.in/exclusive-partnerships/skopickcom`
        },
        {
            image: '/assets/images/ad-banner/112-600-180822-img-2.png',
            link: `/${current_location}/tuitions/maths`
        },
        {
            image: 'https://api.bewise.in/uploads/excl_bannerqqsn1j.png',
            link: `https://www.bewise.in/exclusive-partnerships/auro-scholar`
        }

];
const banner_arr_tuitions_rev = banner_arr.slice().reverse();

class TuitionRight extends React.Component {
    constructor() {
        super();
        this.state = {
            logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            url: '/signin?prev_path=/funlearning'
        };
    }
    componentDidMount() {
        if (this.state.logged_auth != false) {
            this.setState({
                url: '/funlearning'
            })
        }
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div className='row mb-5 landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_tuitions.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='row landing-right-ad'>
                    <Slider {...settings2}>
                        {banner_arr_tuitions_rev.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </React.Fragment >
        );
    }
}

class LearningRight extends React.Component {
    constructor() {
        super();
        this.state = {
            logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            url: '/signin?prev_path=/funlearning'
        };
    }
    componentDidMount() {
        if (this.state.logged_auth != false) {
            this.setState({
                url: '/funlearning'
            })
        }
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div className='row mb-5 landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='row landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_rev.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </React.Fragment >
        );
    }
}
const banner_arr_counselling = [
   
        {
            image: '/assets/images/ad-banner/112-600-180822-img-2.png',
            link: `/${current_location}/tuitions/maths`
        },
        {
            image: 'https://api.bewise.in/uploads/excl_bannerqqsn1j.png',
            link: `https://www.bewise.in/exclusive-partnerships/auro-scholar`
        },
        {
            image: 'https://api.bewise.in/uploads/excl_bannercyehun.png',
            link: `https://www.bewise.in/exclusive-partnerships/know-thy-choice`
        }

];
const banner_arr_counselling_rev = banner_arr.slice().reverse();

class CommunityRight extends React.Component {
    constructor() {
        super();
        this.state = {
            logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            url: '/signin?prev_path=/funlearning'
        };
    }
    componentDidMount() {
        if (this.state.logged_auth != false) {
            this.setState({
                url: '/funlearning'
            })
        }
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div className='row mb-5 landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_counselling.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='row landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_counselling_rev.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </React.Fragment >
        );
    }
}

class BirthEventRight extends React.Component {
    constructor() {
        super();
        this.state = {
            logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            url: '/signin?prev_path=/funlearning'
        };
    }
    componentDidMount() {
        if (this.state.logged_auth != false) {
            this.setState({
                url: '/funlearning'
            })
        }
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div className='row mb-5 landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='row landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_rev.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </React.Fragment >
        );
    }
}

class SchoolRight extends React.Component {
    constructor() {
        super();
        this.state = {
            logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            url: '/signin?prev_path=/funlearning'
        };
    }
    componentDidMount() {
        if (this.state.logged_auth != false) {
            this.setState({
                url: '/funlearning'
            })
        }
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div className='row mb-5 landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='row landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_rev.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </React.Fragment >
        );
    }
}

class PreschoolRight extends React.Component {
    constructor() {
        super();
        this.state = {
            logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            url: '/signin?prev_path=/funlearning'
        };
    }
    componentDidMount() {
        if (this.state.logged_auth != false) {
            this.setState({
                url: '/funlearning'
            })
        }
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div className='row mb-5 landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='row landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_rev.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </React.Fragment >
        );
    }
}

class KidsplayRight extends React.Component {
    constructor() {
        super();
        this.state = {
            logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            url: '/signin?prev_path=/funlearning'
        };
    }
    componentDidMount() {
        if (this.state.logged_auth != false) {
            this.setState({
                url: '/funlearning'
            })
        }
    }
    render() {
        // console.log(this.state)

        const banner_arr_kids = (current_location=='bengaluru') ? [
        
            
           {
               image: '/assets/images/ad-banner/112-600-180822-img-5.png',
               link: `/${current_location}/after-school-activity/sports`
           },
           
   
           {
               image: '/assets/ads/aimsonly/v/1.png',
               link: `/${current_location}/pre-schools/montessori/aims-preschool`
           },
   
   ] :[
        
    
{
    image: '/assets/images/ad-banner/112-600-180822-img-5.png',
    link: `/${current_location}/after-school-activity/sports`
},

];


     

        return (
            <React.Fragment>
                <div className='row mb-5 landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_kids.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className='row landing-right-ad'>
                    <Slider {...settings}>
                        {banner_arr_rev.map((item, i) => {
                            return (
                                <div>
                                    <a href={item.link}>
                                        <div className=" position-moved">
                                            <div className="ads-sction landing-right">
                                                <img src={item.image} alt='banner' />
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </React.Fragment >
        );
    }
}

export {
    AfterSchoolRight, TuitionRight, LearningRight, CommunityRight,
    BirthEventRight, SchoolRight, PreschoolRight, KidsplayRight
};
