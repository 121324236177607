import { Component } from 'react';
import config from './DomainName';
// import { conditionalExpression } from '@babel/types';
// var FormData = require('form-data');

const token =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZW5kZXIiOnsiaWQiOiI1ZWFhOWM2MjI4NTcxYjFiZDAxMDc0OTcifSwiaWF0IjoxNTg4NTg3NDMwLCJleHAiOjE1ODg5NDc0MzB9.gkKX8V7RNl_bEH8dme04b-tyXn8HGPSf1Z0HTwE53dk';

class FetchCall extends Component {
  static getSchools = async (data) => {
    let result = await fetch(config.register + 'user/school', {
      method: 'GET',
      mode: 'cors',
      'x-auth-token': token,
      headers: { 'Content-Type': 'application/json' },
    });
    return result.json();
  };

  static getSchoolListDatas = async (id) => {
    let result = await fetch(config.register + 'admin/common/get_drop_down/' + id, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
    });
    return result.json();
  };
  static getNanniesDatas = async (id) => {
    let result = await fetch(config.register + 'admin/common/get_drop_downs/' + id, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
    });
    return result.json();
  };
  static getSubCategory = async (id) => {
    let result = await fetch(config.register + 'admin/common/getcategory/' + id, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
    });
    return result.json();
  };
}


async function getSchools(data) {
  let result = await fetch(config.register + 'user/school', {
    method: 'GET',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}


// async function getSchoolDetails(id) {
//   let result = await fetch(config.register + 'user/school/vender', {
//     method: 'POST',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getSchoolDetails(data) {
  let result = await fetch(config.register + 'user/school/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


/* 2021 Oct */
async function getSchoolDetailsByTerm(data) {
  let result = await fetch(config.register + 'user/school/term', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getPreSchoolDetailsByTerm(data) {
  let result = await fetch(config.register + 'user/preschool/term', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getTuitionDetailsByTerm(data) {
  let result = await fetch(config.register + 'user/tution/term', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getAfterSchoolDetailsByTerm(data) {
  let result = await fetch(config.register + 'user/hobbies_intrest/term', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getLearningCampsDetailsByTerm(data) {
  let result = await fetch(config.register + 'user/camps/term', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function getCommunitySupportDetailsByTerm(data) {
  let result = await fetch(config.register + 'user/advice/term', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getKidsPlayAreaDetailsByTerm(data) {
  let result = await fetch(config.register + 'user/playarea/term', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getBirthDayEventsDetailsByTerm(data) {
  let result = await fetch(config.register + 'user/events/term', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getNameBySlug(data, whichtable) {

  console.log(data);

  if (whichtable == 'schools') {

    let result = await fetch(config.register + 'user/school/getname', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: data,
    });
    console.log('Success Slug Term'); console.log(data);
    return result.json();

  }

  if (whichtable == 'preschools') {

    let result = await fetch(config.register + 'user/preschool/getname', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: data,
    });

    console.log('Success Slug Term'); console.log(data);
    return result.json();

  }

  if (whichtable == 'learningcamps') {

    let result = await fetch(config.register + 'user/camps/getname', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: data,
    });

    console.log('Success Slug Term'); console.log(data);
    return result.json();
  }

  if (whichtable == 'events') {

    let result = await fetch(config.register + 'user/events/getname', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: data,
    });
    console.log('Success Slug Term'); console.log(data);
    return result.json();

  }

  if (whichtable == 'communitysupport') {

    let result = await fetch(config.register + 'user/advice/getname', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: data,
    });

    console.log('Success Slug Term'); console.log(data);
    return result.json();
  }

  if (whichtable == 'tuition') {

    let result = await fetch(config.register + 'user/tution/getname', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: data,
    });
    console.log('Success Slug Term'); console.log(data);
    return result.json();

  }

  if (whichtable == 'hobbies') {

    let result = await fetch(config.register + 'user/hobbies_intrest/getname', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: data,
    });

    console.log('Success Slug Term'); console.log(data);
    return result.json();
  }

  if (whichtable == 'playarea') {

    let result = await fetch(config.register + 'user/playarea/getname', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'x-auth-token': token,
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: data,
    });

    console.log('Success Slug Term'); console.log(data);
    return result.json();
  }


}

async function getSchoolNameBySlug(data) {
  let result = await fetch(config.register + 'user/school/getname', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success Slug Term'); console.log(data);
  return result.json();
}


async function getSchoolCategoryIdBySlug(data) {
  let result = await fetch(config.register + 'user/school/getsubcategory', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success Slug Term'); console.log(data);
  return result.json();
}

async function getPreSchoolCategoryIdBySlug(data) {
  let result = await fetch(config.register + 'user/preschool/getsubcategory', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success Slug Term'); console.log(data);
  return result.json();
}

async function getTuitionCategoryIdBySlug(data) {
  let result = await fetch(config.register + 'user/tution/getsubcategory', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success Slug Term'); console.log(data);
  return result.json();
}

async function getAfterSchoolActCategoryIdBySlug(data) {
  let result = await fetch(config.register + 'user/afterschool/getsubcategory', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success Slug Term'); console.log(data);
  return result.json();
}

async function getLearningCampsCategoryIdBySlug(data) {
  let result = await fetch(config.register + 'user/camps/getsubcategory', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success Slug Term'); console.log(data);
  return result.json();
}

async function getCommunitySupportCategoryIdBySlug(data) {
  let result = await fetch(config.register + 'user/advice/getsubcategory', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success Slug Term'); console.log(data);
  return result.json();
}

async function getKidsPlayAreaCategoryIdBySlug(data) {
  let result = await fetch(config.register + 'user/playarea/getsubcategory', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success Slug Term'); console.log(data);
  return result.json();
}

async function getBirthDayEventsCategoryIdBySlug(data) {
  let result = await fetch(config.register + 'user/events/getsubcategory', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success Slug Term'); console.log(data);
  return result.json();
}

// async function creativeActivityInfo(id) {
//   let result = await fetch(config.register + 'user/hobbies_intrest/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function creativeActivityInfo(data) {
  let result = await fetch(config.register + 'user/hobbies_intrest/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getYoga(data) {
  let result = await fetch(config.register + 'user/hobbies_intrest', {
    method: 'GET',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}
async function getMusic(data) {
  let result = await fetch(config.register + 'user/hobbies_intrest', {
    method: 'GET',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}


// async function getTutionDetails(id) {
//   let result = await fetch(config.register + 'school/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getAllPresSchools() {
  let result = await fetch(config.register + 'user/preschool', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}



async function pageFilter(data) {
  let result = await fetch(config.register + 'user/preschool/filter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function schoolFilter(data) {
  let result = await fetch(config.register + 'user/school/filter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function playareaFilter(data) {
  let result = await fetch(config.register + 'user/playarea/filter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function tuitionFilter(data) {
  let result = await fetch(config.register + 'user/tution/filter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function campFilter(data) {
  let result = await fetch(config.register + 'user/camps/filter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function venueFilter(data) {
  let result = await fetch(config.register + 'user/events/venuefilter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function cakeFilter(data) {
  let result = await fetch(config.register + 'user/events/cakefilter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function partyFilter(data) {
  let result = await fetch(config.register + 'user/events/partyfilter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function entertainerFilter(data) {
  let result = await fetch(config.register + 'user/events/enterfilter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function nanniesFilter(data) {
  let result = await fetch(config.register + 'user/advice/nanniesfilter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function hobbiesFilter(data) {
  let result = await fetch(config.register + 'user/hobbies_intrest/hobbiesfilter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function adviceFilter(data) {
  let result = await fetch(config.register + 'user/advice/advicefilter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

// async function getPreSchoolDetails(id) {
//   let result = await fetch(config.register + 'user/preschool/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getPreSchoolDetails(data) {
  let result = await fetch(config.register + 'user/preschool/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getAllPlayAreas() {
  let result = await fetch(config.register + 'user/playarea', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}
// async function getPlayAreaDetails(id) {
//   let result = await fetch(config.register + 'user/playarea/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getPlayAreaDetails(data) {
  let result = await fetch(config.register + 'user/playarea/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getEventsList() {
  let result = await fetch(config.register + 'partyhall', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

async function getEventsDetails(id) {
  let result = await fetch(config.register + 'partyhall/vender/' + id, {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

async function getVenueList() {
  let result = await fetch(config.register + 'user/events', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

// async function getVenueDetails(id) {
//   let result = await fetch(config.register + 'user/events/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }


async function getVenueDetails(data) {
  let result = await fetch(config.register + 'user/events/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function getEntertainerList() {
  let result = await fetch(config.register + 'user/events', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

// async function getEntertainerInfo(id) {
//   let result = await fetch(config.register + 'user/events/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getEntertainerInfo(data) {
  let result = await fetch(config.register + 'user/events/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function getSupplierList() {
  let result = await fetch(config.register + 'user/events', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

// async function getSupplierInfo(id) {
//   let result = await fetch(config.register + 'user/events/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getSupplierInfo(data) {
  let result = await fetch(config.register + 'user/events/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function getCakeList() {
  let result = await fetch(config.register + 'user/events', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

// async function getCakeDetails(id) {
//   let result = await fetch(config.register + 'user/events/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getCakeDetails(data) {
  let result = await fetch(config.register + 'user/events/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getAdviceList() {
  let result = await fetch(config.register + 'user/advice', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

// async function getAdviceDetails(id) {
//   let result = await fetch(config.register + 'user/advice/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getAdviceDetails(data) {
  let result = await fetch(config.register + 'user/advice/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}




// async function getCakeDetails(id) {
//   let result = await fetch(config.register + 'user/events/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getTutionList() {
  let result = await fetch(config.register + 'user/tution', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

// async function getTutionDetils(id) {
//   let result = await fetch(config.register + 'user/tution/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getTutionDetils(data) {
  let result = await fetch(config.register + 'user/tution/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function getSports() {
  let result = await fetch(config.register + 'user/hobbies_intrest', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}
async function getDance() {
  let result = await fetch(config.register + 'user/hobbies_intrest', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

// async function getSportsInfo(id) {
//   let result = await fetch(config.register + 'user/hobbies_intrest/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }

async function getSportsInfo(data) {
  let result = await fetch(config.register + 'user/hobbies_intrest/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function getCamp() {
  let result = await fetch(config.register + 'user/camps', {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

// async function getCampInfo(id) {
//   let result = await fetch(config.register + 'user/camps/vender/' + id, {
//     method: 'GET',
//     'x-auth-token': token,
//     mode: 'cors',
//     headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
//   });
//   return result.json();
// }


async function getCampInfo(data) {
  let result = await fetch(config.register + 'user/camps/vender', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getSummerCampInfo(data) {
  let result = await fetch(config.register + 'summercamps/getsummercamp', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function userSignUp(data) {
  let result = await fetch(config.register + 'user/auth/register', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function reSendVerify(data) {
  let result = await fetch(config.register + 'user/auth/resendverify', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function userLogin(data) {
  let result = await fetch(config.register + 'user/auth/login', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function postQuery(data) {
  let result = await fetch(config.register + 'user/auth/contactus', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function emailValidate(data) {
  let result = await fetch(config.register + 'user/auth', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getcategorydetail(data) {

  let result = await fetch(config.register + 'admin/common/getcategorydetail', {
    method: 'POST',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}


async function getSearchList(data) {
  // let data = JSON.stringify({
  //   keyword: keyword
  // })
  let result = await fetch(config.register + 'admin/common/search', {
    method: 'POST',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function getSearchCity(data) {
  // let data = JSON.stringify({
  //   keyword: keyword
  // })
  let result = await fetch(config.register + 'admin/common/searchcity', {
    method: 'POST',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}



async function ratingReview(data) {
  let result = await fetch(config.register + 'reviews', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function comments(data) {
  let result = await fetch(config.register + 'user/comments', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function like_follow(data) {
  let result = await fetch(config.register + 'admin/common/postLikeFlw', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getLike(data) {
  let result = await fetch(config.register + 'admin/common/getLikeFlw', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getLikePartnerVideo(data) {
  let result = await fetch(config.register + 'partnervideo/get_parntervideo_like', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function saveLikePartnerVideo(data) {
  let result = await fetch(config.register + 'partnervideo/save_partnervideo_like', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getComments(data) {
  let result = await fetch(config.register + 'user/comments/listComments', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function editComments(data) {
  let result = await fetch(config.register + 'user/comments/editcomment', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function SimilarResults(data) {
  let result = await fetch(config.register + 'similar', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function TrendingResults(data) {
  let result = await fetch(config.register + 'similar/trending', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function TrendingEvent(data) {
  let result = await fetch(config.register + 'similar/eventtrending_list', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function TrendingHobbies(data) {
  let result = await fetch(config.register + 'similar/hobbiestrending_list', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function TrendingCommunity(data) {
  let result = await fetch(config.register + 'similar/communitytrending_list', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function AddWistData(data) {
  let result = await fetch(config.register + 'user/wishlist/addwishlist', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function removeWishlist(data) {
  let result = await fetch(config.register + 'user/wishlist/removewishlist', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function SubscriptionData(data) {
  let result = await fetch(config.register + 'newsletter', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function social_login(data) {
  let result = await fetch(config.register + 'user/auth/social_login', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function wishlistGet(id) {
  let result = await fetch(config.register + 'user/wishlist/getwishlist/' + id, {
    method: 'GET',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}

async function getTrending(data) {
  let t = JSON.parse(data);
  console.log('**', t)
  let result = await fetch(`${config.register}trending_now/${t.url}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getTrendingInSearch(data) {
  let result = await fetch(config.register + 'admin/common/topTrendsForSearch', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}


async function ForgotPass(data) {
  let result = await fetch(config.register + 'admin/common/reset_password', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function ResetPass(data) {
  let result = await fetch(config.register + 'admin/common/update_password', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function SearchData(data) {
  let result = await fetch(config.register + 'admin/common/search_data_new', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function CompareData(data) {
  let result = await fetch(config.register + 'admin/common/compare_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function SearchFilterData(data) {
  let result = await fetch(config.register + 'admin/common/search_filter_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function SearchSortByData(data) {
  let result = await fetch(config.register + 'admin/common/search_sort_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function GetLocationData(data) {
  let result = await fetch(config.register + 'admin/common/location_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function LocationData(data) {
  let result = await fetch(config.register + 'admin/common/getLocation', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function serviceProvider(data) {
  let result = await fetch(config.register + 'user/auth/service_provider', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function verifyEmail(data) {
  let result = await fetch(config.register + 'user/auth/verifylogin', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function preschoolSecondFilter(data) {
  let result = await fetch(config.register + 'user/preschool/second_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


/* new vertical api calls */
async function verticalTotalCount(data) {
  let result = await fetch(config.register + 'vertical/get_vertical_total_count', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}



async function schoolSecondFilter(data) {
  let result = await fetch(config.register + 'user/school/second_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function playareaSecondFilter(data) {
  let result = await fetch(config.register + 'user/playarea/second_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function tutionSecondFilter(data) {
  let result = await fetch(config.register + 'user/tution/second_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function campSecondFilter(data) {
  let result = await fetch(config.register + 'user/camps/second_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function adviceSecondFilter(data) {
  let result = await fetch(config.register + 'user/advice/second_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function adviceSecondSubFilter(data) {
  let result = await fetch(config.register + 'user/advice/subsecond_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function eventSecondFilter(data) {
  let result = await fetch(config.register + 'user/events/second_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function eventSecondSubFilter(data) {
  let result = await fetch(config.register + 'user/events/subsecond_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function hobbiesSecondFilter(data) {
  let result = await fetch(config.register + 'user/hobbies_intrest/second_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function hobbiesSecondSubFilter(data) {
  let result = await fetch(config.register + 'user/hobbies_intrest/subsecond_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function tutionSecondSubFilter(data) {
  let result = await fetch(config.register + 'user/tution/subsecond_landing_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function catgoryList(data) {
  let result = await fetch(config.register + 'admin/common/secondlanding_cate', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getUser(data) {
  let result = await fetch(config.register + 'user/auth/getUser', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  //console.log('success : '+result.json());
  return result.json();
}
async function getChild(data) {
  let result = await fetch(config.register + 'user/auth/getChild', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function userUpdate(data) {
  let result = await fetch(config.register + 'user/auth/updateUser', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function userChild(data) {
  let result = await fetch(config.register + 'user/auth/userChild', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function get_resource_list_user(data) {
  let result = await fetch(config.register + 'eventresource/get_resource_list_user', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function resource_download(data) {
  let result = await fetch(config.register + 'eventresource/resource_download', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function getSclname(data) {
  let result = await fetch(config.register + 'user/auth/getsclname', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}



async function storeUserAgent(data) {
  let result = await fetch(config.register + 'admin/common/saveUserAgent', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getinsightcontent(data) {
  let result = await fetch(config.register + 'insights/get_insight_list_user', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function getinsightTrending(data) {
  let result = await fetch(config.register + 'insights/get_insight_trending', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}


async function getinsightcontentDetailPage(data) {
  let result = await fetch(config.register + 'insights/get_insight_list_user_detail', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}


async function getinsightlatestten(data) {
  let result = await fetch(config.register + 'insights/get_insight_latest_ten', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}



async function getinsightDetail(data) {
  let result = await fetch(config.register + 'insights/insight_detail', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function get_like_follow_insight(data) {
  let result = await fetch(config.register + 'insights/get_insight_like_follow', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function save_like_insight(data) {
  let result = await fetch(config.register + 'insights/save_insight_like', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function save_follow_insight(data) {
  let result = await fetch(config.register + 'insights/save_author_follow', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function commonAPI(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'admin/common/' + t.url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getVideoAdmin(data) {
  let result = await fetch(config.register + 'adminvideo/get_video_user', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getinsightDetailBySlug(data) {
  let result = await fetch(config.register + 'insights/insight_detail_slug', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function saveInsightView(data) {
  let result = await fetch(config.register + 'insights/saveUserAgent', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}


async function saveCuratedPageView(data) {
  let result = await fetch(config.register + 'insights/saveCuratedUserAgent', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}


async function getinsightList(data) {
  let result = await fetch(config.register + 'insights/get_insight_list_pagi', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}
async function userEvents(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'user_events/' + t.url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}
async function userEventsDoc(data) {
  console.log('data', data)
  const formData = new FormData();
  for (let key in data) {
    if (key == 'project_docs') {
      for (let i = 0; i < data[key].length; i++) {
        formData.append('project_docs', data[key][i]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  let result = await fetch(config.register + 'user_events/save_event', {
    method: 'POST',
    mode: 'cors',
    headers: { 'x-auth-token': token },
    body: formData,
  });
  console.log('Success');
  return result.json();
}
async function pNotification(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'pnotification/' + t.url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}
async function userHistory(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'user_history/' + t.url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function verticalListing(data) {
  let result = await fetch(config.register + 'vertical/get_vertical_list', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function campRegisterationApi(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'curatedcamps/register', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getcuratedcamps(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'curatedcamps/getcuratedcampsfront', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getCuratedCampFront(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'curatedcamps/getcuratedcampsfrontdetail/' + t.path_term, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    }

  });
  return result.json();
}


async function getsummercampsv1(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'summercamps/getsummercampsv1', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getLearningSummerCamps(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'summercamps/getsummercamps', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getLearningSummerCampsExcluded(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'curatedcamps/getsummercampexluded', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function partnerdetailforsummercamp(partner_id) {

  let result = await fetch(config.register + 'user/camps/partnerdetailforsummercamp/' + partner_id, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
  return result.json();
}

async function partnerdetailforsummercamptuition(partner_id) {

  let result = await fetch(config.register + 'user/tution/partnerdetailforsummercamp/' + partner_id, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
  return result.json();
}


async function getCuratedCamp(data) {
  let result = await fetch(config.register + 'summercamps/getsummercampbyvendor', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function storeUserAgentCurated(data) {
  let result = await fetch(config.register + 'curatedcamps/saveUserAgent', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getpartnerprofileinfo(data) {
  //let t = JSON.parse(data);
  let result = await fetch(config.register + 'curatedcamps/getpartnerprofileinfo', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getMetaData(data) {
  let result = await fetch(config.register + 'meta_data', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function retailerAPI(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'retailer/' + t.url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}
async function eventModule(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'event_module/' + t.url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}
async function eventModuleReg(data) {
  let result = await fetch(config.register + 'event_module/save_event_registration', {
    method: 'POST',
    mode: 'cors',
    headers: { 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function exclprtshipsList(data) {
  let result = await fetch(config.register + 'exclusive/common/get_list_front', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}


async function exclprtshipsListAll() {
  let result = await fetch(config.register + 'exclusive/common/get_list_front_all', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token }
  });
  return result.json();
}


async function exclprtshipsListFive() {
  let result = await fetch(config.register + 'exclusive/common/get_list_front_5', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token }
  });
  return result.json();
}



async function getExclPartnerShipDetailBySlug(data) {
  let result = await fetch(config.register + 'exclusive/common/detail_by_slug', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function getCommentsExcl(data) {
  let result = await fetch(config.register + 'exclusive/common/getcomments', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function searchModule(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'user_search/' + t.url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}


async function searchInArticles(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'insights/search', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}



async function saveLead(data) {
  let result = await fetch(config.register + 'leads/processlead/savelead', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}


async function sendsaveOTP(data) {
  let result = await fetch(config.register + 'leads/processlead/sendsaveOTP', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function verifyOTP(data) {
  let result = await fetch(config.register + 'leads/processlead/verifyOTP', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}


async function getAds() {

  let result = await fetch(config.register + 'leads/processlead/getPartneradsWebsite', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
  });
  return result.json();
}

async function getPremimumAds(data) {

  let result = await fetch(config.register + 'ads/common/getPremimumAds', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function saveOnclicks(data) {

  let result = await fetch(config.register + 'analytics/process/saveaclick', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}


async function getAdsFrontEnd(data) {

  let result = await fetch(config.register + 'leads/processlead/getAdsFrontEnd', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function saveAnaLytics(data) {

  let result = await fetch(config.register + 'leads/processlead/saveanalytics', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getAreaNames(data) {

  let result = await fetch(config.register + 'leads/processlead/getAreaNames', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}



async function findKeywordAndFetchAds(data) {

  let result = await fetch(config.register + 'exclusive/common/findMatchingAds', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function findMatchingAdsMobile(data) {

  let result = await fetch(config.register + 'exclusive/common/findMatchingAdsMobile', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getBannersListing() {
  let result = await fetch(config.register + 'ads/common/getBannersListingMobile', {
    method: 'POST',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
  });
  return result.json();
}


async function verticalSeoContent(data) {

  let result = await fetch(config.register + 'seo/common/getVertical_SEOContent', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getPrevPost(data) {

  let result = await fetch(config.register + 'seo/common/getPrevPost', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

async function getNextPost(data) {

  let result = await fetch(config.register + 'seo/common/getNextPost', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}


async function otpVerify(data) {
  let result = await fetch(config.register + 'user/auth/verifyOTP', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}



async function sendOTPPartner(data) {
  let result = await fetch(config.register + 'user/auth/sendOTPtoPartner', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}


async function VerifyOTPAccount(data) {
  let result = await fetch(config.register + 'user/auth/verifyOTPPartner', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getUserDetailById(data) {
  let result = await fetch(config.register + 'user/auth/getUserDetailById', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getTokenAuroScholar() {
  let result = await fetch(config.register + '3partyapis/getTokenAuroScholar', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    
  });
  console.log('Success');
  return result.json();
}


async function pushAuroScholar(data) {

  let result = await fetch(config.register + '3partyapis/pushAuroScholar', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}

 

async function sendOTPtoVerify(data) {
  let result = await fetch(config.register + '3partyapis/sendsaveOTP', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function verifyOTP2(data) {
  let result = await fetch(config.register + '3partyapis/verifyOTP', {
    method: 'post',
    mode: 'cors',
    'x-auth-token': token,
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}


async function getCities(data) {
  // let data = JSON.stringify({
  //   keyword: keyword
  // })
  let result = await fetch(config.register + 'wc/getcities', {
    method: 'POST',
    'x-auth-token': token,
    mode: 'cors',
    headers: { 'Content-Type': 'application/json', 'x-auth-token': token },
    body: data,
  });
  return result.json();
}

async function searchModuleNew(data) {
  let t = JSON.parse(data);
  let result = await fetch(config.register + 'user_search/' + t.url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  return result.json();
}


async function saveEnqLead(data) {
  let result = await fetch(config.register + 'wc/saveenqlead', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function saveExclEnqLead(data) {
  let result = await fetch(config.register + 'wc/saveexclenqlead', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function getSpecialGuestPartnerShipDetailBySlug(data) {
  let result = await fetch(config.register + 'exclusive/common/fetchpromotionpage', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

async function fetchCategories() {
  let result = await fetch(config.register + 'insights/get_bewisecategories',{
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    }
    
  });
  console.log('Success');
  return result.json();
}

async function getPhonenumber(data) {
  let result = await fetch(config.register + 'wc/getphonenumber', {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-auth-token': token,
      'Content-Type': 'application/json; charset=UTF-8'
    },
    body: data,
  });
  console.log('Success');
  return result.json();
}

export {
  getPhonenumber,
  fetchCategories,
  searchInArticles,
  saveCuratedPageView,
  getinsightTrending,
  getSpecialGuestPartnerShipDetailBySlug,
  saveExclEnqLead,
  saveEnqLead,
  searchModuleNew,
  getSearchCity,
  getinsightcontentDetailPage,
  getCities,
  verifyOTP2,
  sendOTPtoVerify,
  getTokenAuroScholar,
  pushAuroScholar,
  getUserDetailById,
  exclprtshipsListFive,
  social_login, getTrending, SubscriptionData, removeWishlist, AddWistData,
  wishlistGet, postQuery, SimilarResults, getLike, like_follow, getComments,
  comments, ratingReview, getSearchList, userSignUp, userLogin, emailValidate,
  getAdviceDetails, getAdviceList, getCamp, getCampInfo, getSupplierList,
  getSupplierInfo, getEntertainerList, getEntertainerInfo, getCakeList,
  getCakeDetails, getVenueList, getVenueDetails, creativeActivityInfo,
  getDance, getMusic, getYoga, getTutionList, getTutionDetils, getSports,
  getSportsInfo, getEventsDetails, getEventsList, getSchoolDetails, getSchoolDetailsByTerm, getSchools,
  getAllPresSchools, getPreSchoolDetails, getAllPlayAreas, getPlayAreaDetails,
  ResetPass, ForgotPass, pageFilter, schoolFilter, playareaFilter, tuitionFilter,
  campFilter, venueFilter, cakeFilter, partyFilter, entertainerFilter, nanniesFilter,
  hobbiesFilter, adviceFilter, TrendingResults, TrendingEvent, TrendingHobbies,
  TrendingCommunity, SearchData, CompareData, SearchFilterData, LocationData, GetLocationData,
  serviceProvider, SearchSortByData, verifyEmail, preschoolSecondFilter, schoolSecondFilter,
  playareaSecondFilter, tutionSecondFilter, campSecondFilter, adviceSecondFilter, eventSecondFilter,
  hobbiesSecondFilter, catgoryList, hobbiesSecondSubFilter, tutionSecondSubFilter, eventSecondSubFilter,
  adviceSecondSubFilter, getUser, getChild, userUpdate, storeUserAgent, getinsightcontent, commonAPI, getVideoAdmin,
  getLikePartnerVideo, saveLikePartnerVideo, getinsightDetail, getinsightDetailBySlug, get_like_follow_insight,
  save_follow_insight, save_like_insight, editComments, userChild, get_resource_list_user, resource_download, getSclname, getSchoolCategoryIdBySlug,
  getPreSchoolCategoryIdBySlug, getTuitionCategoryIdBySlug, getAfterSchoolActCategoryIdBySlug, getLearningCampsCategoryIdBySlug,
  getBirthDayEventsCategoryIdBySlug, getKidsPlayAreaCategoryIdBySlug, getCommunitySupportCategoryIdBySlug, getBirthDayEventsDetailsByTerm,
  getKidsPlayAreaDetailsByTerm, getCommunitySupportDetailsByTerm, getLearningCampsDetailsByTerm, getAfterSchoolDetailsByTerm,
  getPreSchoolDetailsByTerm, getTuitionDetailsByTerm, getSchoolNameBySlug, getNameBySlug, getcategorydetail, getTrendingInSearch,
  pNotification, userEventsDoc, userHistory, reSendVerify, getinsightList, userEvents, saveInsightView, verticalListing,
  verticalTotalCount, getSummerCampInfo, campRegisterationApi, getLearningSummerCamps, getLearningSummerCampsExcluded,
  partnerdetailforsummercamp, partnerdetailforsummercamptuition, getCuratedCamp, getsummercampsv1, getcuratedcamps,
  getpartnerprofileinfo, getCuratedCampFront, storeUserAgentCurated, getMetaData, retailerAPI,
  eventModule, eventModuleReg, exclprtshipsList,getExclPartnerShipDetailBySlug,getCommentsExcl,searchModule, saveLead,
  sendsaveOTP,verifyOTP,exclprtshipsListAll,getAds, getAdsFrontEnd, saveOnclicks, saveAnaLytics, findKeywordAndFetchAds, getAreaNames, findMatchingAdsMobile,
  getBannersListing,verticalSeoContent, getPremimumAds, getPrevPost, getNextPost, otpVerify, sendOTPPartner,VerifyOTPAccount, getinsightlatestten
};


export default FetchCall;