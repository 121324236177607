import React, { Component } from 'react';
import { ratingReview, getAdsFrontEnd, getPremimumAds } from '../../API/FetchCall';
import { BASE_URL } from '../../constant';
import { IMG_URL, ADMIN_URL } from "../../constant";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    speed: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    dots: false
};

class AdsOne extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                {/* Ads Section 1 start  */}
                <a href='/learning-camps/curated-learning-camps' target='_blank'>
                    <div className="d56bf5 new-view-right-banner">
                        <img src={'/assets/images/ad-banner/banner-right-viewmore.png'} />
                    </div>
                </a>
                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}

class AdsTwo extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                {/* Ads Section 2 start  */}
                <div className="row">
                    <div className="col-md-6">
                        <div className="text-center adstwo">
                            <div className="ads-sction2" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/homepagebbbanner.png')` }}>
                            </div>
                            {/* <div className="ads-sction2" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/ads3.png')` }}>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="text-center adstwo">
                            <div data-toggle="modal" data-target="#feedBackModal" className="ads-sction2" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/ads2.png')` }}>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}

const current_location = localStorage.getItem('current_location') ? localStorage.getItem('current_location').toLowerCase() : 'bengaluru';


const banner_foot = [
    {
        image: '/assets/images/ad-banner/468-60-180822-img-1.png',
        link: `https://www.bewise.in/${current_location}/tuitions/maths`
    },
    {
        image: '/assets/images/ad-banner/468-60-180822-img-2.png',
        link: `https://www.bewise.in/`
    },
    {
        image: '/assets/images/ad-banner/468-60-180822-img-3.png',
        link: `https://www.bewise.in/${current_location}/tuitions/languages/french`
    },
    {
        image: '/assets/images/ad-banner/468-60-180822-img-4.png',
        link: `https://www.bewise.in/${current_location}/after-school-activity/mind-and-skill-enrichment/coding/diya-edulabs`
    },
    {
        image: '/assets/images/ad-banner/468-60-180822-img-5.png',
        link: `https://www.bewise.in/bengaluru/tuitions/languages/french/sangeetha-sethuraman`
    },
    {
        image: '/assets/images/ad-banner/468-60-180822-img-6.png',
        link: `https://www.bewise.in/`
    },

]

class AdsDynamicBottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bottom_ads:[],
        };
        this.getBottomAds();
        this.getBottomAds = this.getBottomAds.bind(this);
    }

    getBottomAds(){

        let filterData = JSON.stringify({
            position:'BT'
        });

        getAdsFrontEnd(filterData).then((response) => {

          this.setState({
            bottom_ads: response.list
          });

        });

    }

    render() {

        const settingsBt2 = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000
        };

        // console.log(this.state)
        const bottom_ads = this.state.bottom_ads;
        const IMG_URL = 'https://api.bewise.in/';
        return (
            
            <React.Fragment>
            {bottom_ads?.length>0 && (
                <Slider {...settingsBt2}>
                    {bottom_ads.map((item, i) => {
                        return (
                            <div className="bottomAdLarge">
                                <a href={item.link}>
                                    <div className="text-center adstwo">
                                        <div className="ads-sction2 homefooter">
                                            <img src={IMG_URL+"uploads/"+item.adfilepath} alt='banner' />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </Slider>
            )}
            </React.Fragment >
           
        );
    }
}

class AdsDynamicRightPosition2 extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            rightside_ads:[],
            page:(this.props.params.page)?this.props.params.page:'listing'
        };

        this.getRightSideAds = this.getRightSideAds.bind(this);
    }

    componentDidMount() {
         this.getRightSideAds();
    }

    getRightSideAds(){

        let filterData = JSON.stringify({
            position:'R2',
            page:this.state.page
        });

        getAdsFrontEnd(filterData).then((response) => {

          this.setState({
            rightside_ads: response.list
          });

        });

    }

    render() {

        const settingsRightSide = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000
        };

        const rightside_ads = this.state.rightside_ads;
        //const IMG_URL = 'http://localhost:5005/';
        return (
            <React.Fragment>
                <Slider {...settingsRightSide}>
                    {rightside_ads?.map((item, i) => {
                        return (
                            <div className="">
                                <a href={item.link}>
                                    <div className="text-center">
                                        <div className="landing-right">
                                            <img src={IMG_URL+"uploads/"+item.adfilepath} alt='banner' class="rsadwidth100"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </Slider>
                
            </React.Fragment >
        );
    }
}

class AdsDynamicMobileMiddle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rightside_mobilesR1:''
        };
       
        //this.getRightSideAds = this.getRightSideAds.bind(this);
    }

    componentDidMount() {
         this.getRightSideAds();
    }

    getRightSideAds(){

        let filterData = JSON.stringify({
            position:'R1',
            type:'mobile',
            page:'listing'
        });

        getAdsFrontEnd(filterData).then((response) => {

          this.setState({
            rightside_mobilesR1: response.list
          });

        });

    }

    render() {

        const settingsRightSideP1Mobile = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000
        };

        const rightside_mobilesR1 = (this.state.rightside_mobilesR1=='')?[]:this.state.rightside_mobilesR1;
        //const IMG_URL = 'http://localhost:5005/';
        return (
            <React.Fragment>
                <div class="bannerblock mobileonly">
                <Slider {...settingsRightSideP1Mobile} ref="mobibanners">
                    {rightside_mobilesR1?.map((item, i) => {
                        return (
                            <div className="">
                                <a href={item.link}>
                                    <div className="text-center ">
                                        <div className="landing-right">
                                            <img src={IMG_URL+"uploads/"+item.adfilepath} alt='banner' class="rsadwidth100"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </Slider>
                </div>
            </React.Fragment >
        );
    }
}

class AdsDynamicRightTop extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rightside_adsp1:'',
            display_position:this.props.params.display_position,
            display_in:this.props.params.display_in,
            display_mode:this.props.params.display_mode,

            speed:(this.props.params.speed)?this.props.params.speed:500,
            autoplayspeed:(this.props.params.autoplayspeed)?this.props.params.autoplayspeed:5000,

            location:localStorage.getItem('current_location') ? localStorage.getItem('current_location').toLowerCase() : 'bengaluru'
        };
        
        //this.getRightSideAds = this.getRightSideAds.bind(this);
    }

    componentDidMount() {
         this.getRightSideAds();
    }

    getRightSideAds(){

        let filterData = JSON.stringify({
            display_position:this.state.display_position,
            display_in:this.state.display_in,
            display_mode:this.state.display_mode,
            location:this.state.location
        });

        getPremimumAds(filterData).then((response) => {

          this.setState({
            rightside_adsp1: response.list
          });

        });

    }

    render() {

        const settingsRightSideP1 = {
            dots: false,
            infinite: true,
            speed: this.state.speed,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: this.state.autoplayspeed
        };

        const rightside_adsp1 = (this.state.rightside_adsp1=='')?[]:this.state.rightside_adsp1;
        //const IMG_URL = 'http://localhost:5005/';
        return (
            <React.Fragment>
            <div className='row mb-5 landing-right-ad'>
                <Slider {...settingsRightSideP1}>
                    {rightside_adsp1?.map((item, i) => {
                        return (
                            <div className="">
                                <a href={item.link}>
                                    <div className="text-center ">
                                        <div className="landing-right">
                                            <img src={IMG_URL+"uploads/"+item.adfilepath} alt='banner' class="rsadwidth100"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </Slider>
            </div>    
            </React.Fragment >
        );
    }
}


class AdsDynamicRightPosition980 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rightside_adsp1:'',
            page:(this.props.params.page)?this.props.params.page:'listing',
        };
       
        //this.getRightSideAds = this.getRightSideAds.bind(this);
    }

    componentDidMount() {
         this.getRightSideAds();
    }

    getRightSideAds(){

        let filterData = JSON.stringify({
            position:'R1',
            page:this.state.page
            
        });

        getAdsFrontEnd(filterData).then((response) => {

          this.setState({
            rightside_adsp1: response.list
          });

        });

    }

    render() {

        const settingsRightSideP1 = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000
        };

        const rightside_adsp1 = (this.state.rightside_adsp1=='')?[]:this.state.rightside_adsp1;
        //const IMG_URL = 'http://localhost:5005/';
        const TESTADURL = '/assets/images/rec_fix_ads.png';
        return (
            <React.Fragment>
                <Slider {...settingsRightSideP1}>
                            <div className="">
                                <a href="https://google.com">
                                    <div className="text-center ">
                                        <div className="landing-right">
                                            <img src={TESTADURL} alt='banner' class="rsadwidth100"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                </Slider>
            </React.Fragment >
        );
    }
}

class AdsDynamicRightPosition1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rightside_adsp1:'',
            page:(this.props.params.page)?this.props.params.page:'listing',
        };
       
        //this.getRightSideAds = this.getRightSideAds.bind(this);
    }

    componentDidMount() {
         this.getRightSideAds();
    }

    getRightSideAds(){

        let filterData = JSON.stringify({
            position:'R1',
            page:this.state.page
            
        });

        getAdsFrontEnd(filterData).then((response) => {

          this.setState({
            rightside_adsp1: response.list
          });

        });

    }

    render() {

        const settingsRightSideP1 = {
            dots: false,
            infinite: true,
            speed: 1000,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 8000
        };

        const rightside_adsp1 = (this.state.rightside_adsp1=='')?[]:this.state.rightside_adsp1;
        //const IMG_URL = 'http://localhost:5005/';
        return (
            <React.Fragment>
                <Slider {...settingsRightSideP1}>
                    {rightside_adsp1?.map((item, i) => {
                        return (
                            <div className="">
                                <a href={item.link}>
                                    <div className="text-center ">
                                        <div className="landing-right">
                                            <img src={IMG_URL+"uploads/"+item.adfilepath} alt='banner' class="rsadwidth100"/>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </Slider>
                
            </React.Fragment >
        );
    }
}

class AdsHorizandal extends React.Component {
    constructor(props) {
        super(props);
        console.log('this.props.match.params', this.props.is_school);
        this.state = {
            is_school: this.props.is_school ? this.props.is_school : false
        };
    }

    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <Slider {...settings}>
                     
                    {banner_foot.map((item, i) => {
                        return (

                            <div className="bottomAdLarge">
                                <a href={item.link}>
                                    <div className="text-center adstwo">
                                        <div className="ads-sction2 homefooter">
                                            <img src={item.image} alt='Bewise' />
                                        </div>
                                    </div>
                                </a>
                            </div>
                            
                        )
                    })}
                </Slider>
                {/* <div className="row">
                    
                </div> */}
            </React.Fragment >
        );
    }
}

class AdsVertical extends React.Component {
    constructor() {
        super();
        this.state = {
            logged_auth: localStorage.getItem("logged_auth") ? localStorage.getItem("logged_auth") : false,
            url: '/signin?prev_path=/funlearning'
        };
    }
    componentDidMount() {
        if (this.state.logged_auth != false) {
            this.setState({
                url: '/funlearning'
            })
        }
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                {/* Ads Section 1 start  */}
                <div className='row mb-5'>
                    <a href='/funlearning'>
                        <div className=" position-moved">
                            <div className="ads-sction landing-right">
                                <img src={'/assets/images/ad-banner/event-ad-verticals-page.png'} alt='banner' />
                            </div>
                            {/* <div className="ads-sction" style={{ backgroundImage: `url('/assets/icons/event-ad-verticals-page.png')` }}>
                            </div> */}
                        </div>
                    </a>
                </div>
                <div className='row'>
                    <a href={'javascript:;'}>
                        {/* <a href={this.state.url}> */}
                        <div className=" position-moved">
                            <div className="ads-sction landing-right">
                                <img src={'/assets/images/ad-banner/landing-rbanner-2.png'} alt='banner' />
                            </div>
                        </div>
                    </a>
                </div>
                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}
class HomeTop extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                {/* Ads Section 2 start  */}
                <div className="row">
                    <div className="col-md-6">
                        <div className="text-center adstwo">
                            <div className="ads-sction2 home-top" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/homepagebbbanner.png')` }}>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="text-center adstwo">
                            <div className="ads-sction2 home-top" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/ads2.png')` }}>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}
class HomeCenter extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                {/* Ads Section 2 start  */}
                <div className="row">
                    <div className="col-md-6">
                        <div className="text-center adstwo">
                            <div className="ads-sction2 home-center" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/homepagebbbanner.png')` }}>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="text-center adstwo">
                            <div className="ads-sction2 home-center" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/ads2.png')` }}>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}
class HomeAfterVideo extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }
    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                {/* Ads Section 2 start  */}
                <div className="row">
                    <div className="col-md-6">
                        <div className="text-center adstwo">
                            <div className="ads-sction2 homefooter" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/homepagebbbanner.png')` }}>
                            </div>
                            {/* <div className="ads-sction2" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/ads3.png')` }}>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="text-center adstwo">
                            <div data-toggle="modal" data-target="#feedBackModal" className="ads-sction2 homefooter" style={{ backgroundImage: `url('` + BASE_URL + `assets/icons/ads2.png')` }}>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}
class HomeBottom extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }
    render() {
        const settingsF = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
                        slidesToScroll: 1
                    }
                }
            ]
        };
        return (
            <React.Fragment>
                {/* Ads Section 2 start  */}
                <Slider {...settingsF}>
                    {banner_foot.map((item, i) => {
                        return (
                            <div className="">
                                <a href={item.link}>
                                    <div className="text-center adstwo">
                                        <div className="ads-sction2 homefooter">
                                            <img src={item.image} alt='banner' />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </Slider>

                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}
class ViewTop extends React.Component {
    constructor(props) {
        super(props);
        let common_image = 'banner-new-view-top-common.png',
            creative_image = 'banner-new-view-top-creative.png',
            prescl_image = 'banner-new-view-top-prescl.png',
            tuition_lang_image = 'banner-new-view-top-tuition-lang.png';
        console.log('this.props', this.props.img_id)
        let img_id = this.props.img_id;
        let banner_image = common_image;
        if (img_id == 1) {
            banner_image = common_image;
        } else if (img_id == 2) {
            banner_image = prescl_image;
        } else if (img_id == 3) {
            banner_image = creative_image;
        } else if (img_id == 4) {
            banner_image = tuition_lang_image;
        }
        this.state = {
            banner_image: banner_image
        };
    }
    render() {
        const settingsT = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
            autoplay: false,
            autoplaySpeed: 8000,
            dots: false,
            arrows: false
        };
        return (
            <React.Fragment>
                <div className="">
                    <Slider {...settingsT}>
                        {banner_arr.map((item, i) => {
                            return (

                                <a href={item.link}>
                                    <div className="text-center adstwo">
                                        <div className="ads-sction2 insighttopad" >
                                            <img src={item.image} alt='banner' />
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    </Slider>
                </div>
                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}

class ViewRightAd extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }

    render() {
        // console.log(this.state)
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 mb-3 viewrighadd">
                        <img src={BASE_URL + 'assets/icons/5.jpg'} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3 viewrighadd">
                        <img src={BASE_URL + 'assets/icons/6.jpg'} />
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
const banner_arr = [
    {
        image: '/assets/images/ad-banner/768-90-180822-img-1.png',
        link: `javascript:;`
    },
    {
        image: '/assets/images/ad-banner/768-90-180822-img-2.png',
        link: `javascript:;`
    },
    {
        image: '/assets/images/ad-banner/768-90-180822-img-3.png',
        link: `javascript:;`
    },
    {
        image: '/assets/images/ad-banner/768-90-180822-img-4.png',
        link: `javascript:;`
    },
    {
        image: '/assets/images/ad-banner/768-90-180822-img-5.png',
        link: `javascript:;`
    },
    {
        image: '/assets/images/ad-banner/768-90-180822-img-6.png',
        link: `javascript:;`
    },
]
class InsightTop extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }
    render() {
        const settingsT = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 8000,
            dots: false,
            arrows: false
        };
        return (
            <React.Fragment>
                <div className="">
                    <Slider {...settingsT}>
                        {banner_arr.map((item, i) => {
                            return (

                                <a href={item.link}>
                                    <div className="text-center adstwo">
                                        <div className="ads-sction2 insighttopad" >
                                            <img src={item.image} alt='banner' />
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    </Slider>
                </div>
                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}

class InsightTopNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banner_arr:this.props.banners
        };
        console.log(this.props.banners);
    }
    render() {
        const settingsT = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 8000,
            dots: false,
            arrows: false
        };
        const {banner_arr} = this.state;
        return (
            <React.Fragment>
                <div className="">
                    <Slider {...settingsT}>
                        {banner_arr.map((item, i) => {
                            return (

                                <a href={item.link}>
                                    <div className="text-center adstwo">
                                        <div className="ads-sction2 insighttopad" >
                                            <img src={item.image} alt='banner' />
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    </Slider>
                </div>
                {/* Ads Section end */}
            </React.Fragment >
        );
    }
}

export {
    AdsOne,
    AdsDynamicRightPosition980,
    AdsTwo, AdsHorizandal, AdsVertical, HomeTop, HomeCenter, ViewTop, HomeBottom,
    ViewRightAd, InsightTop, InsightTopNew, HomeAfterVideo, AdsDynamicRightPosition1, AdsDynamicRightPosition2, AdsDynamicBottom, AdsDynamicMobileMiddle, AdsDynamicRightTop
};
