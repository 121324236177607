import React, { Component } from 'react';
import { Mainmenu } from './Mainmenu';
import $ from 'jquery';
import '../assets/js/menuCustom.js';
import logoBewise from './../assets/images/bewiselogov1.png';
import { HomeSearchMobile } from '../components/NewHome/home_search_mobile';
import { Modal, ModalBody, Row, Col, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { BASE_URL } from '../constant';
import {
  LocationData, verifyEmail, getSearchList
} from '../API/FetchCall';

import Autosuggest from 'react-autosuggest';


import { HeadsearchNew } from './head_search_new';
import { LoginPop, RightMenu, PNotification } from './Login_pop';
import IdlePop from './AutoPop';
import { Newlogin } from './new_login_reg';
import ReactGA from 'react-ga';
import Rating from 'react-star-rating-component';
import { Helmet } from 'react-helmet';



const locations = [{"id":1,"location":"Bengaluru","latitude":"12.9716","longitude":"77.5946","status":1,"sort_order":1},{"id":12,"location":"Chennai","latitude":"13.0569","longitude":"80.2425","status":1,"sort_order":2},{"id":11,"location":"Coimbatore","latitude":"11.0168","longitude":"76.9558","status":1,"sort_order":3},{"id":27,"location":"Hosur","latitude":"12.7409","longitude":"77.8253","status":1,"sort_order":4},{"id":2,"location":"Hyderabad","latitude":"17.3850","longitude":"78.4867","status":1,"sort_order":4},{"id":24,"location":"Madurai","latitude":"9.9252","longitude":"78.1198","status":1,"sort_order":5},{"id":13,"location":"Mumbai","latitude":"19.0760","longitude":"72.8777","status":1,"sort_order":7},{"id":15,"location":"Kolkata","latitude":"22.5726","longitude":"88.3639","status":1,"sort_order":8},{"id":16,"location":"New Delhi","latitude":"28.6139","longitude":"77.2090","status":1,"sort_order":9},{"id":17,"location":"Jaipur","latitude":"26.9124","longitude":"75.7873","status":1,"sort_order":10},{"id":18,"location":"Chandigarh","latitude":"30.7333","longitude":"76.7794","status":1,"sort_order":11},{"id":19,"location":"Pune","latitude":"18.5204","longitude":"73.8567","status":1,"sort_order":12},{"id":20,"location":"Kochi","latitude":"9.9312","longitude":"76.2673","status":1,"sort_order":13},{"id":21,"location":"Noida","latitude":"28.5355","longitude":"77.3910","status":1,"sort_order":14},{"id":22,"location":"Agra","latitude":"27.1767","longitude":"78.0081","status":1,"sort_order":15},{"id":23,"location":"Puducherry","latitude":"11.9416","longitude":"79.8083","status":1,"sort_order":16},{"id":26,"location":"Indore","latitude":"22.719568","longitude":"75.857727","status":1,"sort_order":18},{"id":25,"location":"Tiruchirappalli","latitude":"10.7905","longitude":"78.7047","status":1,"sort_order":17}];
class NewHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged_name: '',
      authe: false,
      showModalSignup: false,
      showModalLogin: false,
      showLocationPop: false,
      mobLocationToggle: false,
      mobSearchShow: false,
      sidemenu: false,
      filter_opt: false,
      menu_data: {},
      showSuggestions: false,
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
      suggestions: [],
      location_list:locations,
      value: localStorage.getItem("searchdata") ? localStorage.getItem("searchdata") : '',
      current_location: localStorage.getItem("current_location") ? localStorage.getItem("current_location") : 'Bengaluru',
    };

    //this.LocationDataList();
    this.mbLocToogle = this.mbLocToogle.bind(this);
    this.onChangev1 = this.onChangev1.bind(this);

  }
  mbLocToogle = () => {
    this.setState({
      mobLocationToggle: !this.state.mobLocationToggle,
      showModalLogin: false,
      showModalSignup: false,
    });
  }

  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getSearchListFetch = (val) => {
    let data = JSON.stringify({
      keyword: val,
      city: 'Bengaluru',
    })
    return getSearchList(data).then(response => response);
  }

  getSuggestions = async (value) => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    var final1 = [];
    var final2 = [];
    if (escapedValue.length > 2) {
      let list = await this.getSearchListFetch(escapedValue);

      if (list.status === 1) {
        final1 = list.list;
        final1.forEach(element => {
          final2.push(element.name);
        });

      } else {
        final2 = [];
      }
    }
    return final2;
  }

  onChangev1 = e => {

    const userInput = e.currentTarget.value;

    this.getSuggestions(userInput).then(response => {

      const filteredSuggestions = response.filter(
        suggestion =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      console.log(filteredSuggestions);

      this.setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: userInput
      });

    })

  };



  onClickv1 = e => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });

    $(".btn-search-submit").removeClass('hidden');
    $(".btn-search").addClass('hidden');
    $("#search__field").removeClass('search__field');
    $("#search__field").addClass('search__field2');
  };

  onKeyDownv1 = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  mbSearchToogle = (e) => {
    e.preventDefault();
    this.setState({
      mobSearchShow: !this.state.mobSearchShow,
      showModalLogin: false,
      showModalSignup: false,
    });
  }
  componentDidMount() {
    let auth = localStorage.getItem("logged_auth");
    let data_auth = (localStorage.getItem("myData"));
    if (data_auth) {
      let auth_data = JSON.parse(data_auth);
      let login_type_name = '';
      if (auth_data.login_type == 2) {
        login_type_name = 'Google';
      } else if (auth_data.login_type == 3) {
        login_type_name = 'Facebook';
      } else if (auth_data.login_type == 4) {
        login_type_name = 'LinkedIn';
      }
      this.setState({
        logged_name: auth_data.first_name + ' ' + auth_data.last_name,
        authe: true,
        user_id: auth_data.user_id,
        login_type: auth_data.login_type,
        login_type_name: login_type_name
      });
    }
  }
  componentDidUpdate() {
    //window.jQuery('.selectpicker').selectpicker('refresh');
  }
  LocationDataList = async () => {
    try {
      const res = await LocationData();
      if (res.status == 1) {
        this.setState({
          location_list: res.results,
          error: false
        });
      }

    } catch (error) {
      this.setState({ error: true });
    }
  };

  locationPop = (e) => {
    this.setState({ showLocationPop: false });
  };

  handlechange_location = (e) => {
    // alert(e.target.value)
    let current_location = e.target.value;
    if ((current_location != 'Bengaluru' && current_location != 'Chennai' && current_location != 'Hosur')) {
      this.setState({
        showLocationPop: true
      })
    } else {
      this.setState({
        current_location: e.target.value,
        current_locations: e.target.value,
      });
      let check_location = this.state.location_list.filter(o => o.location == current_location);
      localStorage.setItem("current_location", current_location);
      let lat = check_location[0].latitude;
      let lng = check_location[0].longitude;
      localStorage.setItem("latitude", lat);
      localStorage.setItem("longitude", lng);
      var urltoredirect = window.location.href;
      //alert(urltoredirect);
      const urlparts = urltoredirect.split("/");
      //console.log(urlparts);
      if (urlparts[4] == 'schools' || urlparts[4] == 'pre-schools' || urlparts[4] == 'kids-play-area' || urlparts[4] == 'learning-camps' || urlparts[4] == 'community-support' || urlparts[4] == 'birthday-event' || urlparts[4] == 'tuitions' || urlparts[4] == 'after-school-activity') {
        let makeurl = BASE_URL + current_location.toLowerCase() + "/" + urlparts[4];
        if (urlparts[5] !== undefined) {
          makeurl = makeurl + "/" + urlparts[5];
        }
        //alert(makeurl);
        window.location.href = makeurl;
      }
      else
        window.location.reload();
    }

  }

  locationList(location_select = '') {
    let locate = this.state.location_list !== undefined ? this.state.location_list : [];

    let selected_name = '';
    if (location_select && this.state.location_list.length > 0) {
      selected_name = this.state.location_list.filter(o => o.location == location_select);
      if (selected_name.length > 0) {
        selected_name = selected_name ? selected_name[0]['location'] : '';
      }
    }

    let rows = "";
    if (locate.length > 0) {
      rows = locate.map((locate, index) => {
        let select = (selected_name == locate.location) ? 'selected' : '';
        return (
          <option key={index} value={locate.location} selected={select} >{locate.location}</option>
        );
      });

    } else {
      rows = "";
    }
    return rows;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.login === true) {
      this.setState({
        showModalLogin: true,
        showModalSignup: false,
        sidemenu: false,
      })
    } else if (nextProps.signup === true) {
      this.setState({
        showModalSignup: true,
        showModalLogin: false,
        sidemenu: false,
      })
    } else {
      this.setState({
        showModalSignup: false,
        showModalLogin: false,
        sidemenu: false,
      })
    }
    if (nextProps.menu_data) {
      this.setState({
        menu_data: nextProps.menu_data,
        sidemenu: false,
      })
    }
    if (nextProps.filter_opt) {
      this.setState({
        filter_opt: nextProps.filter_opt,
        sidemenu: false,
      })
    }
  }
  openSignin = (e) => {
    e.preventDefault();
   
    //alert(window.location.href);

    localStorage.setItem('pageclicked', window.location.href);

    this.setState({
      showModalLogin: true,
      sidemenu: false
    })
  }
  openSignup = (e) => {
    e.preventDefault();
    this.setState({
      showModalSignup: true,
      sidemenu: false
    })
  }
  openSiemenu = (e) => {
    this.setState({
      showModalSignup: false,
      showModalLogin: false,
      sidemenu: true
    })
  }
  searchLink = (e) => {
    e.preventDefault();
    let search_text = this.state.userInput;
    alert(search_text)
    this.setState({
      value: search_text
    });
    if (search_text.length > 3) {
      var search = search_text.split(' ').join('+');
      window.location.replace('/search/' + search);
    }
  }


  callFilter = () => {
    this.props.onMobileFilterCall();
  }
  callSort = () => {
    this.props.onMobileSortCall();
  }
  callSingup = () => {
    this.setState({
      showModalSignup: true
    })
  }

  render() {
    console.log('this.state.authe', this.state)
    let path = window.location.pathname, dekSearchCls = 'mainsearchareatop s003', isHome = false;
    if (path == '/') {
      dekSearchCls += ' topmainsearch';
      isHome = true;
    }
    const { rating, rating1 } = this.state;
    const pageUrl = window.location.href;
    /* const { value, suggestions } = this.state; */

    /*
    const inputPropsD = {
      placeholder: "Search for a school or an after school activity",
      value,
      class: 'search__field',
      id: 'searchmob',
      onChange: this.onChange
    };*/

    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions) {
      if (filteredSuggestions.length) {

        suggestionsListComponent = (
          <div class="suggestionbox"><ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }
              return (
                <li className={className} key={suggestion} onClick={this.onClickv1}>
                  {suggestion}
                </li>
              );
            })}
          </ul></div>
        );
      } else {
        suggestionsListComponent = (
          <div class="suggestionbox">
            <em>No suggestions available.</em>
          </div>
        );
      }
    }
    return (
      <React.Fragment>



        <Helmet>
           <link rel="canonical" href={pageUrl} />
        </Helmet>

        <IdlePop callSingup={this.callSingup} />
        <Newlogin login={this.state.showModalLogin} signup={this.state.showModalSignup} />
        <div style={{
          position: "sticky",
          zIndex: "999",
          background: "#fff",
          width: "100%",
          top: 0
        }} class="container-fluid desktop">

          
          <div className='tranparent-header-custom-inner'>
            <div className='container-fluid'>
              <div className='row align-items-center header-custom stickymenu sticky-top'>
                <div className='col-lg-2 col-md-6 col-xs-6 bewise-logo-block'>
                  <div className='bewise-logo active'>
                    <div class="row  display-flex-center">
                      <div>
                        <a className='logo-text' href='/'>
                          <img src={logoBewise} alt="bewiselogo" className="mainlogoimg" style={{ width: "100%", marginRight: "2px" }} />
                        </a>
                      </div>
                      {/* <div className="mainlogotext">
                        <a className='logo-link' href='/'>
                          <h5 style={{ lineHeight: "3px", marginTop: "10px", fontWeight: "bold", transform: "scale(1, 1.1)" }}>BeWise</h5>
                          <p className="logoslogan" style={{ fontSize: "8px", fontWeight: "bold", transform: "scale(.75, 1)" }}>Enabling Learning & Fun</p>
                        </a>
                      </div> */ }
                    </div>
                  </div>
                </div>

                <div className='col-lg-8 col-md-8 col-xs-8' style={{ textAlign: "center" }}>
                  <div className={dekSearchCls} style={{ display: isHome && 'none' }}>

                    <form >
                      <div class="inner-form">

                        <div class="input-field first-wrap">
                          <div class="cityselect input-select">

                            <select data-trigger="" value={this.state.current_location} name="choices-single-defaul" onChange={this.handlechange_location}>
                              <option placeholder="" className="select-items">Select a city</option>
                              {this.locationList()}
                            </select>
                          </div>
                        </div>
                        {/* <input id="search" type="text" placeholder="Search for a school or an after school activity" /> */}
                        <HeadsearchNew />


                      </div>
                    </form>
                  </div>
                </div>
                <div className='col-lg-2 col-md-6 col-xs-6 text-right bewise-signin-block'>
                  {(this.state.authe == false) ?
                    <a href="javascript:;" onClick={this.openSignin} className="nsignbtn">Sign In</a>
                    :
                    <React.Fragment>
                      <button className="sidebar-btn signin-name" onClick={() => this.openSiemenu()}>
                        <span className="signned-name">{this.state.logged_name}</span> <i className="fa fa-bars" aria-hidden="true"></i>
                      </button>
                      <PNotification />
                    </React.Fragment>
                  }
                </div>
              </div>

            </div>
          </div>

        </div>
        <Modal isOpen={this.state.showLocationPop} centered size='lg'>
          <ModalBody className='p-0'>
            <button
              className='close-btn'
              data-dismiss='modal'
              aria-label='Close'
              onClick={this.locationPop}
            >
              <img src={BASE_URL + 'assets/icons/close.svg'} alt='close' />
            </button>
            <div className=''>
              <Row className='m-5 text-center'>
                <Col sm={{ size: 12 }} className=''>
                  <div className=''>
                    <h4>Coming soon to your city. Stay tuned!</h4>
                  </div>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
        <div class="container-fluid mobile new-mobile-header-all home" style={{
          position: isHome && "fixed",
          zIndex: "999",
          background: "#fff",
          width: "100%",
          top: 0
        }}>
          <div className='col-12 p-0'>
            <div className="row align-items-center">

              <div class="col-6  p-0" style={{ padding: "10px" }}>

                <div className='bewise-logo active'>
                  <div class="row  display-flex-center">
                    <div>
                      <a className='logo-text' href='/'>
                        <img src={logoBewise} alt="bewiselogo" className="mainlogoimg mainlogoimg-mob" style={{ width: "100%", marginRight: "2px" }} />
                      </a>
                    </div>
                    { /*  <div className="mainlogotext">
                      <a className='logo-link' href='/'>
                        <h5 style={{ lineHeight: "3px", marginTop: "10px", fontWeight: "bold", transform: "scale(1, 1.1)" }}>BeWise</h5>
                        <p className="logoslogan" style={{ fontSize: "8px", fontWeight: "bold", transform: "scale(.75, 1)" }}>Enabling Learning & Fun</p>
                      </a>
                    </div> */ }
                  </div>
                </div>
              </div>

              <div className='col-6 p-0'>
                <div className='col-12 pull-right p-0'>
                  <div class="row pull-right display-flex-center header-access-icons">

                    <div className='header-search col-2'>
                      <i class="fa fa-search" aria-hidden="true" onClick={this.mbSearchToogle}></i>
                    </div>

                    <div class="search hidden">
                      <div class="search__wrapper">
                        <input type="text" name="" placeholder="Search for..."
                          class="search__field" id="search__field" onChange={this.onChangev1}
                          onKeyDown={this.onKeyDownv1}
                          value={userInput} />
                        <button class="btn-search search__icon"><i class="fas fa-search"></i></button>
                        <button class="btn-search-submit search__icon2 hidden" onClick={this.searchLink}><i class="fas fa-search"></i></button>
                        {suggestionsListComponent}
                      </div>

                    </div>

                    {(this.state.authe == false) &&
                      <div className={this.state.authe == false ? 'header-search col-2' : 'header-search col-5 pr-0 header-signin-icons'} onClick={this.openSignin}>
                        <i class="fa fa-sign-in" aria-hidden="true"></i>
                     </div>                         
                    }
                                        
                    {(this.state.authe == true) &&
                      <div className={this.state.authe == false ? 'header-search col-2' : 'header-search col-5 pr-0'}>
                        <div className="bewise-signin-block home-sign-name d-flex" onClick={() => this.openSiemenu()}><span className="signned-name">{this.state.logged_name}</span> </div>
                     </div>                         
                    }
                        
                     
                  </div>
                </div>
              </div>
              <Popover className='filter-pop-over' placement="bottom-end" trigger="focus"
                isOpen={this.state.mobLocationToggle} target="Popover1" toggle={this.mbLocToogle}>
                <PopoverBody className>
                  <div className='col-12'>
                    <div className='row align-items-center'>
                      <div className='col-10'>
                        <select value={this.state.current_location} className="selectpicker landing-sort-drop location-drom-mob form-control" onChange={this.handlechange_location}>
                          {this.locationList()}
                        </select>
                      </div>
                      <div className='col-2'>
                        <i class="fa fa-times pull-right" onClick={this.mbLocToogle} aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </PopoverBody>
              </Popover>
              <div className='col-md-12 text-center' style={{ display: this.state.mobSearchShow ? 'block' : 'none' }}>
                <div className='s003 wrapper'>
                  <form>
                    <div class="inner-form">
                      <HomeSearchMobile />
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>

        </div>
       

        <RightMenu sidemenu={this.state.sidemenu} filter_opt={this.state.filter_opt}
          onFilterClick={this.callFilter} onSortClick={this.callSort}
          onSigninClick={this.openSignin} onSignupClick={this.openSignup} />

        {!isHome &&
          <Mainmenu menu_data={this.state.menu_data} />
        }

      </React.Fragment >
    );
  }
  handleChangefeedback = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onStarClickfeedback(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }
  onStarClick1feedback(nextValue, prevValue, name) {
    this.setState({ rating1: nextValue });
  }
  handleQueryfeedback = (e) => {
    e.preventDefault();
    var emailtext = JSON.stringify({
      rating: this.state.rating,
      rating1: this.state.rating1,
      feature: this.state.feature,
      decision: this.state.decision,
      verticals: this.state.verticals,
      suggession: this.state.suggession,
      message: this.state.message
    });
    //console.log("email text",emailtext)
    let result = fetch(config.register + 'user/auth/sendfeedback', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: emailtext,
    });
    let toastr = '';
    toastr = window.toastr.success;

    toastr('', 'Thanks for your Feedback!..', {
      positionClass: "toast-bottom-center",
      containerId: "toast-bottom-center"
    });
    window.$('#feedBackModal').modal('hide');
    $('.modal-backdrop').removeClass("show");
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  }
}

export default NewHeader;

