import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { instanceOf } from 'prop-types';
import { withCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import {
    getSearchList, userHistory
} from '../API/FetchCall';

export class HeadsearchNew extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    constructor(props) {

        super(props);
        const { cookies } = this.props;
        let logged = localStorage.getItem('logged_auth') ? localStorage.getItem('logged_auth') : 'false';
        //let data = document.cookie.indexOf('search_data_cookie') > -1 ? (Cookies.get('search_data_cookie')) : [];
        //const uniq = new Set(data.map(e => JSON.stringify(e)));
        //const cookie_data = Array.from(uniq).map(e => JSON.parse(e));
        this.state = {
            suggestions: [],
            showsuggest: false,
            defaultSuggest: '',
            value: localStorage.getItem("searchdata") ? localStorage.getItem("searchdata") : '',
            current_location: localStorage.getItem("current_location") ? localStorage.getItem("current_location") : 'bengaluru',
            logged_auth: logged,
            logged_data: localStorage.getItem('myData') ? JSON.parse(localStorage.getItem('myData')) : [],
        };
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        if (logged == 'true') {
            this.getUserHistory();
        }
    }
    componentDidMount() {
        var ignoreClickOnMeElement = document.getElementById('top-search-sec-d');
        let THIS = this;
        document.addEventListener('click', function (event) {
            var isClickInsideElement = ignoreClickOnMeElement.contains(event.target);
            if (!isClickInsideElement) {
                THIS.handleClickOutside();
            }
        });
    }

    handleClickOutside = () => {
        this.setState({
            showsuggest: false,
        })
    }
    getCookies = () => {
        const { cookies } = this.props;
        let data = document.cookie.indexOf('search_data_cookie') > -1 ? (cookies.get('search_data_cookie')) : [];
        // const uniq = new Set(data.map(e => JSON.stringify(e)));

        // const res = Array.from(uniq).map(e => JSON.parse(e));
        // console.log('defaukt --', typeof res)
        this.setState({
            suggestions: data,
            defaultSuggest: data,
        })
    }
    getUserHistory = () => {
        const { user_id } = this.state.logged_data;
        let req = JSON.stringify({
            user_id: user_id,
            location: this.state.current_location,
            url: 'user_search_data'
        })
        userHistory(req).then(res => {
            if (res.status === 1) {
                this.setState({
                    suggestions: res.list,
                    defaultSuggest: res.list,
                })
            }
        });
    }

    escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    getSuggestions = async (value) => {
        const escapedValue = this.escapeRegexCharacters(value.trim());
        let list = {};
        list = await this.getSearchListFetch(escapedValue);
        var final = [];
        if(list.status){
        if (list.status === 1) {
            final = list.list
            this.setState({
                searchList: list.list
            })
        } else {
            final = [];
        }
        }
        return final;
    }
    getSearchListFetch = (val) => {
        if (val.trim().length > 2) {
            let data = JSON.stringify({
                keyword: val,
                city: this.state.current_location,
            })
            return getSearchList(data).then(response => response);
        }

    }
    // shouldRenderSuggestions = (value, reason) => {
    //     if (!this.state.showsuggest)
    //         return value.trim().length > 2;
    // };
    getSuggestionValue(suggestion) {
        return suggestion.name;
    }

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.name}</span>
        );
    }

    searchLink2 = (e) => {
        e.preventDefault();

        let search_text = this.state.value;
        if (search_text.length > 3) {
            //console.log('search_text',search_text)
            var search = search_text.split(' ').join('+');
            window.location.replace('/search/' + search);
        }
        // localStorage.setItem("searchdata", search_text);
    }
    onSuggestionsFetchRequested = ({ value }) => {
        this.getSuggestions(value).then(response => {
            this.setState({
                suggestions: response ? response : this.state.defaultSuggest,
                showsuggest: false,
            });
        })
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: this.state.defaultSuggest
        });
    };
 

    onSuggestionSelected(event, { suggestionValue }) {
        window.location.replace('/search/' + suggestionValue);
    }

    onChange = (event, { newValue, method }) => {
        this.setState({
            value: newValue,
            showsuggest: false
        });
    };
    searchLink = (e) => {
        e.preventDefault();
        let search_text = this.state.value;
        this.setState({
            value: search_text
        });
        if (search_text.length > 3) {
            var search = search_text.split(' ').join('+');
            window.location.replace('/search/' + search);
        }
    }
    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: "",
            value,
            class: 'custom-search-text',
            onChange: this.onChange,
            ref: this.wrapperRef,
            onClick: () => {
                this.setState({
                    showsuggest: true
                })
            }
        };
        const inputPropsD = {
            placeholder: "Search for a school or an after school activity",
            value,
            class: 'd-search-text',
            id: 'searchd',
            onChange: this.onChange,
            ref: this.wrapperRef,
            onClick: () => {
                this.setState({
                    showsuggest: true
                })
            }
        };
        return (
            <React.Fragment>
                <div class="input-field second-wrap">
                    <div className='desktop search-text-desktop' id='top-search-sec-d'>
                        <Autosuggest
                            suggestions={suggestions}
                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            onSuggestionSelected={this.onSuggestionSelected}
                            getSuggestionValue={this.getSuggestionValue}
                            renderSuggestion={this.renderSuggestion}
                            alwaysRenderSuggestions={this.state.showsuggest}
                            inputProps={inputPropsD} />
                    </div>
                    <div className='searchAndLangWrap cf d-md-none mobile' >
                        <div className='custom-search-sec'>
                            <div className='searchBox'>
                                <div className='searchBoxRow cf'>

                                    <Autosuggest
                                        suggestions={suggestions}
                                        shouldRenderSuggestions={this.shouldRenderSuggestions}
                                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                        getSuggestionValue={this.getSuggestionValue}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                        renderSuggestion={this.renderSuggestion}
                                        alwaysRenderSuggestions={this.state.showsuggest}
                                        inputProps={inputProps} />


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-field third-wrap">

                    <button class="btn-search searchGo" type="button" onClick={this.searchLink}>
                        <svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                        </svg>
                    </button>
                </div>

            </React.Fragment>
        )
    }
}
