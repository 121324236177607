import React, { Component } from 'react';
import { like_follow, getLike, getSchoolCategoryIdBySlug,getNameBySlug } from '../../API/FetchCall';
// import Rating from 'react-star-rating-component';

class LikeFollow extends React.Component {
    constructor(props) {
        super(props);
        let data_auth = (localStorage.getItem("myData"));
        let auth_data = JSON.parse(data_auth);
        let id = '';
        if(auth_data){
            id =  auth_data.user_id;
        }
        this.state = {
            user_likes: 2,
            user_follow: 2,
            user_id: id,
            auth: false,
            is_follow_count: 0,
            is_like_count: 0,
        };
    }

    componentDidMount() {
        
        let data_auth = (localStorage.getItem("myData"));
        console.log('**',this.state.user_id)
        if (data_auth) {
            let auth_data = JSON.parse(data_auth)
            // console.log('**', auth_data.user_id)
            this.setState({
                logged_name: auth_data.first_name + ' ' + auth_data.last_name,
                authe: true,
                user_id: auth_data.user_id,
            });           
        }
        this.GetDeta(this.state.user_id);
        

    }
    GetDeta(user_id) {

        let SlugName = JSON.stringify({
            category_slug: this.props.paramId.category_name
        });
        getSchoolCategoryIdBySlug(SlugName).then((response) => {
            if (response.status == 1) {
                let slugData = JSON.stringify({
                     slug: this.props.paramId.ref_name,
                     categoryname: this.props.paramId.whichtable
                });
                console.log("table",this.props.paramId.whichtable);
                console.log(slugData);
                 getNameBySlug(slugData,this.props.paramId.whichtable).then((response2) => {
                        if (response2.status == 1) {

        let reviewData = JSON.stringify({
            user_id: user_id,
            category: response.subcategory_id,
            main_category: this.props.paramId.category_id,
            ref_id: response2.id
        });
        console.log('**', reviewData)
        getLike(reviewData).then((response) => {
            let toastr = '';
            console.log('responseresponse', response)
            if (response.status == 1) {
                console.log(response)
                this.setState({
                    is_like_count: response.data.likes,
                    is_follow_count: response.data.follow,
                    user_likes: response.data.user_likes,
                    user_follow: response.data.user_follow
                })
            }
        });
            }});

        }}); 
    }

    likeFollow = (is_like) => {

        let reviewData = JSON.stringify({
            user_id: this.state.user_id,
            category: this.props.paramId.category,
            main_category: this.props.paramId.category_id,
            vendor_id: this.props.paramId.vendor_id,
            ref_id: this.props.paramId.id,
            is_like: is_like,
        });

        like_follow(reviewData).then((response) => {
            let toastr = '';
            if (response.status == 1) {
                this.GetDeta(this.state.user_id);
            } else {
            }
        });
    }
    Follow = (is_follows) => {

        let reviewData = JSON.stringify({
            user_id: this.state.user_id,
            main_category: this.props.paramId.category_id,
            category: this.props.paramId.category,
            vendor_id: this.props.paramId.vendor_id,
            ref_id: this.props.paramId.id,
            is_follow: is_follows
        });

        like_follow(reviewData).then((response) => {
            let toastr = '';
            if (response.status == 1) {
                this.GetDeta(this.state.user_id);
            } else {
            }
        });
    }
    isliked(is_like) {
        this.likeFollow(is_like);
    }
    isFollow(is_fol) {
        this.Follow(is_fol);
    }


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12 pt-3">
                        <div className="text-small d-inline-flex mr-3">
                            <div class="form-group">
                                {(this.state.user_likes === 2) ?
                                    <span title='Like us' onClick={() => this.isliked(true)}><i class="fa fa-thumbs-up" style={{ color: '#fff' }} aria-hidden="true"></i> {this.state.is_like_count} Likes</span>
                                    :
                                    <span title='Like us' onClick={() => this.isliked(false)} ><i class="fa fa-thumbs-up" style={{ color: 'grey' }} aria-hidden="true"></i> {this.state.is_like_count} Likes </span>
                                }
                            </div>
                        </div>
                        <div className="text-small d-inline-flex">
                            <div class="form-group">
                                {(this.state.user_follow === 2) ?
                                    <span title='Follow us to get notifications' onClick={() => this.isFollow(true)} > <i class="fa fa-user-plus" style={{ color: '#fff' }} aria-hidden="true"></i>  {this.state.is_follow_count} Followers</span>
                                    :
                                    <span title='Follow us to get notifications' onClick={() => this.isFollow(false)} > <i class="fa fa-user-plus" style={{ color: 'grey' }} aria-hidden="true"></i>  {this.state.is_follow_count} Followers</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
export { LikeFollow };
