import React, { Component } from 'react';
import Header from '../../layouts/Header';
import Footer from '../../layouts/Footer';
import { getinsightList, fetchCategories } from '../../API/FetchCall';
import { Helmet } from 'react-helmet';
import {
  PAGINATION_LBL
} from '../../constant';

class Blogs_article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      per_page: 6,
      page_number: 1,
      currentpage: 1,
      category: '',
      totalPages: 0,
      city: this.props.match.params.city,
      insightList: [],
      categories:[]
    };
    
    
    this.fetchCategories();
    this.setCategory = this.setCategory.bind(this);
    this.getList = this.getList.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.handleChange = this.handleChange
  }

  componentDidMount(){

    this.getList(1);

  }

  fetchCategories = () => {

    fetchCategories().then(res => {
      if (res.status === 1) {
        let list = res.list;
         
        this.setState({
          categories: list
        })
      }
    })

  }


  handleChange = (e) => {

    const sortOrder = e.target.value;

    let req = JSON.stringify({
      role: 4,
      location: this.state.city,
      category : this.state.category,
      sortOrder:sortOrder,
      page_number: 1,
      perpage: 6
    })
    getinsightList(req).then(res => {
      if (res.status === 1) {
        let data = res.data;
        let total_pages = res.total_pages;
        this.setState({
          insightList: data,
          totalPages: total_pages,
          currentpage: res.currentpage,
          page_number: res.currentpage,
          isloading: false
        })
        window.scrollTo(0, 0);
      }
    })


  }

  setCategory = (e) => {

     let catid = $(e.target).attr('data-catid');

     $(".catitem").removeClass('catselected');
     $(e.target).addClass("catselected");
     this.setState({category:catid, page_number:1, currentpage:1});
     this.refreshList(catid);

  }

  refreshList = (catid) => {
   
    let req = JSON.stringify({
      role: 4,
      location: this.state.city,
      category : catid,
      page_number: 1,
      perpage: 6
    })
    getinsightList(req).then(res => {
      if (res.status === 1) {
        let data = res.data;
        let total_pages = res.total_pages;
        this.setState({
          insightList: data,
          totalPages: total_pages,
          currentpage: res.currentpage,
          page_number: res.currentpage,
          isloading: false
        })
        window.scrollTo(0, 0);
      }
    })
  }

  getList = (page_number) => {
   
    let req = JSON.stringify({
      role: 4,
      location: this.state.city,
      category : this.state.category,
      page_number: page_number,
      perpage: this.state.per_page
    })
    getinsightList(req).then(res => {
      if (res.status === 1) {
        let data = res.data;
        let total_pages = res.total_pages;
        this.setState({
          insightList: data,
          totalPages: total_pages,
          currentpage: res.currentpage,
          page_number: res.currentpage,
          isloading: false
        })
        window.scrollTo(0, 0);
      }
    })
  }
  homeLink = () => {
    window.location.href = '/';
  }
  breadLinkHome = () => {
    window.location.href = '/' + this.state.city + '/' + 'community-support' + '/' + 'counselor';
  }
  render() {
    const menuData = {
      category: 'Community Support',
      category_link: `/${this.state.city}/community-support/counselor`,
      subcategory_one: 'Articles and Blogs',
      subcategory_one_link: '',
      subcategory_one_desc: '',
      active_main: 'community-support',
      active_menu: 'articles-blogs'
    }

    return (
      <React.Fragment>
        {this.state.isloading === true &&
          (
            <React.Fragment>
              <div class="overlay-custom"></div>
              <div class='spinner' role='status'>
                <span class='sr-only'>Loading...</span>
              </div>
            </React.Fragment>
          )}
        
        <Header menu_data={menuData} />
        <section class="secound-landing features-area nav nav-tabs" id="nav-tab" role="tablist">
          <div className='container'>
            <div className='row mb-1 d-none d-md-block'>
              <p className="sortby-text">
                <span className='mb4c'>
                  <b className='pointer' onClick={() => this.homeLink()}>Home&nbsp;<i class="fa fa-angle-right"></i>&nbsp;</b>
                  <b style={{ 'cursor': 'pointer' }} onClick={() => this.breadLinkHome()}>Community Support&nbsp;<i class="fa fa-angle-right"></i>&nbsp;</b>
                  <b><span>Articles and Blogs</span></b>&nbsp;
                </span>
              </p>
            </div>
            <div class="exc_head_title">
              <h2>Articles and Blogs</h2>
            </div>
            <div class="categoryblock">
              <div class="scrollx">
                <div class="smalltitle">Filter By Categories</div>
                <ul>
                   
                 {this.state.categories.map((item, index)=>{

                     if(item.count>0){

                        return <li class="catitem" onClick={this.setCategory} data-catid={item.id}>{item.name}</li>

                     }
                 })} 
                </ul>
              </div>
            </div>

            {this.state.totalPages ==0 &&
                 <div className='col-lg-12 col-md-12 text-center font16'>No Blogs</div>
            }     
            <div className='col-lg-12 col-md-12'>

              <div class="flexrow justifycontent_space">
               
                {(this.state.totalPages > 0) &&
                  // {(this.state.totalPages > 0 && (this.state.sub_categories == '122' || this.state.sub_categories == '148')) &&
                  (
                    <div class="flexitem"><div className='pagi-p text-center text-md-left blogpages_text'>
                      Showing {this.state.currentpage} of {this.state.totalPages} Pages
                    </div></div>
                  )
                }

                <div class="flexitem sortbysection blogpages_text">
                  sort by &nbsp; <select name="b_sort_by" onChange={this.handleChange}>
                  <option value="mostviewed">Most Viewed</option>
                  <option value="mostrecent">Most Recent</option>
                 </select>
                </div>

                </div>
            </div>

            

            <div className='row mb-3'>
              {this.state.insightList.map((item, i) => {
                 
                return (
                  <div className='col-md-4 mb-5'>
                    <div className='blog-container'>
                      <a href={'/articles-blogs/' + item.slug_term} title={item.title}>
                        <div className='blog-title col-md-12'>
                          <h5>{item.title.length > 30 ? item.title.substring(0, 45) + '...' : item.title}</h5>
                        </div>
                        <div className='blog-gallery'>
                          <img src={item.base_url + item.thumbnail} alt={item.title + ' - Gallery image'} />
                        </div>
                        <div className='blog-author'>
                          <div className='row p-0 m-0'>
                            <div className='col-md-12'><h6 className='mb-3'>BeWise</h6></div>
                             
                          </div>
                          <div className='row p-0 m-0'>
                            <div className='col-md-6'>
                              <i class="fa fa-user-plus" aria-hidden="true"></i> {item.follows}&nbsp;&nbsp;
                              <i class="fa fa-thumbs-up" aria-hidden="true"></i> {item.likes}
                            </div>
                            <div className='col-md-6'>
                              <p className='blog-date'><i class="fa fa-eye" aria-hidden="true"></i> {item.view_count ? item.view_count : 0}</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='row mb-3'>
              <div className='col-lg-6 col-md-12'>
                {(this.state.totalPages > 0) &&
                  // {(this.state.totalPages > 0 && (this.state.sub_categories == '122' || this.state.sub_categories == '148')) &&
                  (
                    <p className='pagi-p text-center text-md-left'>
                      Showing {this.state.currentpage} of {this.state.totalPages} Pages
                    </p>
                  )
                }
              </div>
              {
                this.state.totalPages > 0 && <div className='col-lg-6 col-md-12'>
                  <div className="pagenation-custom w-100 text-center text-md-left text-lg-right">
                    <ul className='pagination'>
                      <li className={this.state.page_number == 1 ? 'page-item first disabled' : 'page-item first'}
                        onClick={() => this.state.page_number != 1 && this.getList(1)}>
                        <a className="page-link">
                          {PAGINATION_LBL.first}
                        </a>
                      </li>
                      <li className={this.state.page_number == 1 ? 'page-item previous disabled' : 'page-item previous '}
                        onClick={() => this.state.page_number != 1 && this.getList(parseInt(this.state.page_number) - 1)}
                      >
                        <a class="page-link">
                          {PAGINATION_LBL.previous}
                        </a>
                      </li>
                      <li className={this.state.page_number == this.state.totalPages ? 'page-item next disabled' : 'page-item next'}
                        onClick={() => this.state.page_number < this.state.totalPages && this.getList(parseInt(this.state.page_number) + 1)}
                      >
                        <a className="page-link">
                          {PAGINATION_LBL.next}
                        </a>
                      </li>
                      <li className={this.state.page_number == this.state.totalPages ? 'page-item next disabled' : 'page-item next'}
                        onClick={() => this.state.page_number < this.state.totalPages && this.getList(this.state.totalPages)}
                      >
                        <a className="page-link">
                          {PAGINATION_LBL.last}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              }
            </div>
          </div>
        </section>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Blogs_article;
