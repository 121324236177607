import React, { Component } from 'react';

class General_Banner extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='col-md-12 p-0'>
          <div
            id='demo'
            className='carousel slide banner-home'
            data-ride='carousel'
          >
            <ul className='carousel-indicators'>
              <li data-target='#demo' data-slide-to='0' className='active'></li>
              <li data-target='#demo' data-slide-to='1'></li>
              <li data-target='#demo' data-slide-to='2'></li>
            </ul>

            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img src='../../assets/banner/general/1.jpg' alt='img' />
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/general/2.jpg' alt='Img' />
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/general/3.jpg' alt='img' />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
class Schools_Banner extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='col-md-12 p-0'>
          <div
            id='demo'
            className='carousel slide banner-home'
            data-ride='carousel'
          >
            <ul className='carousel-indicators'>
              <li data-target='#demo' data-slide-to='0' className='active'></li>
              <li data-target='#demo' data-slide-to='1'></li>
            </ul>

            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img src='../../assets/banner/schools/1.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Schools</h3>
                    <p className="banner-text">A school is an educational institution designed to provide learning spaces and learning environments for the teaching of students under the direction of teachers. Most countries have systems of formal education, which is commonly compulsory. In these systems, students’ progress through a series of grades & schools. The names for these schools vary by country, but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught, is commonly called a university college or university. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>

              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/schools/2.jpg' alt='Img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Schools</h3>
                    <p className="banner-text">A school is an educational institution designed to provide learning spaces and learning environments for the teaching of students under the direction of teachers. Most countries have systems of formal education, which is commonly compulsory. In these systems, students’ progress through a series of grades & schools. The names for these schools vary by country, but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught, is commonly called a university college or university. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content Modal */}
        <div className='modal fade review-modal content-modal-custom' id='schoolModal'>
          <div className='modal-dialog model-box modal-lg' >
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title r-text'> School </h3>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>A school is an educational institution designed to provide learning spaces and learning environments for the teaching of students under the direction of teachers. </p>
                <p>Most countries have systems of formal education, which is commonly compulsory. In these systems, students’ progress through a series of grades & schools. The names for these schools vary by country, but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught, is commonly called a university college or university.  </p>
                <p>In addition to core schools offering formal education, students in each country may also attend schools before and after primary / secondary education. A preschool, may be known by various names such as nursery school, pre-primary school, Kindergarten and provides schooling options to very young children in preparing them for School. It may be publicly or privately operated.  </p>
                <p>Alternative schools may provide non-traditional curriculum and methods at  home schooling and online schools, where teaching or learning takes place outside a traditional school building and in styles the Institution choses to establish in approach.  Children with different needs require a different approach to learning & the environment they are groomed in & usually fall under the category of special needs. </p>
                <p>Schools are operated by Governments and by private institutions or the various charitable trust depending on objectives established by owning / governing bodies.  They are all organized in several organizational models, including departmental, small learning communities, academies, integrated, and schools-within-a-school. </p>
                <p>In our approach @ BeWise, we tried to categorize and catalogue available curriculums, to help you make the right choice, one that you prioritize as your and your child’s need.  We have attempted to help with transparent information and comparative views to assists you make a “Wise” choice. </p>

              </div>
            </div>
          </div>
        </div>
        {/* Content Modal end */}


      </React.Fragment>
    );
  }
}
class PreSchools_Banner extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='col-md-12 p-0'>
          <div
            id='demo'
            className='carousel slide banner-home'
            data-ride='carousel'
          >
            <ul className='carousel-indicators'>
              <li data-target='#demo' data-slide-to='0' className='active'></li>
              <li data-target='#demo' data-slide-to='1'></li>
              <li data-target='#demo' data-slide-to='2'></li>
              <li data-target='#demo' data-slide-to='3'></li>

            </ul>

            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img src='../../assets/banner/preschools/1.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Pre-Schools</h3>
                    <p className="banner-text">A school is an educational institution designed to provide learning spaces and learning environments for the teaching of students under the direction of teachers. Most countries have systems of formal education, which is commonly compulsory. In these systems, students’ progress through a series of grades & schools. The names for these schools vary by country, but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught, is commonly called a university college or university. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/preschools/2.jpg' alt='Img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Pre-Schools</h3>
                    <p className="banner-text">A school is an educational institution designed to provide learning spaces and learning environments for the teaching of students under the direction of teachers. Most countries have systems of formal education, which is commonly compulsory. In these systems, students’ progress through a series of grades & schools. The names for these schools vary by country, but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught, is commonly called a university college or university. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/preschools/3.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Pre-Schools</h3>
                    <p className="banner-text">A school is an educational institution designed to provide learning spaces and learning environments for the teaching of students under the direction of teachers. Most countries have systems of formal education, which is commonly compulsory. In these systems, students’ progress through a series of grades & schools. The names for these schools vary by country, but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught, is commonly called a university college or university. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/preschools/4.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Pre-Schools</h3>
                    <p className="banner-text">A school is an educational institution designed to provide learning spaces and learning environments for the teaching of students under the direction of teachers. Most countries have systems of formal education, which is commonly compulsory. In these systems, students’ progress through a series of grades & schools. The names for these schools vary by country, but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught, is commonly called a university college or university. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content Modal */}
        <div className='modal fade review-modal content-modal-custom' id='schoolModal'>
          <div className='modal-dialog model-box modal-lg' >
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title r-text'> Pre-Schools </h3>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>A school is an educational institution designed to provide learning spaces and learning environments for the teaching of students under the direction of teachers. </p>
                <p>Most countries have systems of formal education, which is commonly compulsory. In these systems, students’ progress through a series of grades & schools. The names for these schools vary by country, but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught, is commonly called a university college or university.  </p>
                <p>In addition to core schools offering formal education, students in each country may also attend schools before and after primary / secondary education. A preschool, may be known by various names such as nursery school, pre-primary school, Kindergarten and provides schooling options to very young children in preparing them for School. It may be publicly or privately operated.  </p>
                <p>Alternative schools may provide non-traditional curriculum and methods at  home schooling and online schools, where teaching or learning takes place outside a traditional school building and in styles the Institution choses to establish in approach.  Children with different needs require a different approach to learning & the environment they are groomed in & usually fall under the category of special needs. </p>
                <p>Schools are operated by Governments and by private institutions or the various charitable trust depending on objectives established by owning / governing bodies.  They are all organized in several organizational models, including departmental, small learning communities, academies, integrated, and schools-within-a-school. </p>
                <p>In our approach @ BeWise, we tried to categorize and catalogue available curriculums, to help you make the right choice, one that you prioritize as your and your child’s need.  We have attempted to help with transparent information and comparative views to assists you make a “Wise” choice. </p>

              </div>
            </div>
          </div>
        </div>
        {/* Content Modal end */}

      </React.Fragment>
    );
  }
}
class PlayAreas_Banner extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='col-md-12 p-0'>
          <div
            id='demo'
            className='carousel slide banner-home'
            data-ride='carousel'
          >
            <ul className='carousel-indicators'>
              <li data-target='#demo' data-slide-to='0' className='active'></li>
              <li data-target='#demo' data-slide-to='1'></li>
            </ul>

            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img src='../../assets/banner/playarea/1.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Kids Play Areas</h3>
                    <p className="banner-text">A playground, playpark, or play area is a place specifically designed to enable children play there. Available in both indoors & outdoor, is usually designed for children of various age groups and in some cases are supportive to children of disabilities too. A playground might also include or exclude children of a certain age based on facilities to support this group. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>


                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/playarea/2.jpg' alt='Img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Kids Play Areas</h3>
                    <p className="banner-text">A playground, playpark, or play area is a place specifically designed to enable children play there. Available in both indoors & outdoor, is usually designed for children of various age groups and in some cases are supportive to children of disabilities too. A playground might also include or exclude children of a certain age based on facilities to support this group. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>

                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
              {/* <div className='carousel-item'>
                <img src='../../assets/banner/playarea/3.jpg' alt='img' />
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/playarea/4.jpg' alt='img' />
              </div> */}
            </div>
          </div>
        </div>

        {/* Content Modal */}
        <div className='modal fade review-modal content-modal-custom' id='schoolModal'>
          <div className='modal-dialog model-box modal-lg' >
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title r-text'>Kids Play Areas </h3>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>A playground, playpark, or play area is a place specifically designed to enable children play there. Available in both indoors & outdoor, is usually designed for children of various age groups and in some cases are supportive to children of disabilities too. A playground might also include or exclude children of a certain age based on facilities to support this group. </p>
                <p>Modern playgrounds often have recreational equipment such as see-saw, merry-go-round, swingsets, slides, child gym, chin-up bars, sandbox pits, trapeze rings, playhouses, building blocks, games, sand pits and mazes, many of which help children develop physical coordination, strength, and flexibility.  They also provide recreation and enjoyment which supports social and emotional development of the child.  </p>
                <p>Common in modern playgrounds are play structures that link many different pieces of equipment. Some playgrounds often also have facilities for playing informal games of sports, such as a baseball diamond, a skating arena, a basketball court etc.</p>
                <p>In our approach @ BeWise, we have tried to categorize the search to help you explore options and maximise value, by allowing you understand and compare facilities and flexibilities which enables you make “Wise” choice in your investment of time, effort & money. </p>

              </div>
            </div>
          </div>
        </div>
        {/* Content Modal end */}


      </React.Fragment>
    );
  }
}
class Hobbies_Banner extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='col-md-12 p-0'>
          <div
            id='demo'
            className='carousel slide banner-home'
            data-ride='carousel'
          >
            <ul className='carousel-indicators'>
              <li data-target='#demo' data-slide-to='0' className='active'></li>
              <li data-target='#demo' data-slide-to='1'></li>
              <li data-target='#demo' data-slide-to='2'></li>
              <li data-target='#demo' data-slide-to='3'></li>
              {/* <li data-target='#demo' data-slide-to='4'></li> */}
            </ul>

            <div className='carousel-inner'>
              <div className='carousel-item active'>
              <img src='../../assets/banner/hobbies/2.jpg' alt='Img' />
               
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">After School Activity</h3>
                    <p className="banner-text">After-school programs started in early 1900s mainly just as supervision of students after the final school bell. Today, after-school programs do much more. There is a focus on helping students with schoolwork but can be beneficial to students in other ways. An after-school program, today, will not limit its focus on academics but with a holistic sense of helping the student population. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>


                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
              <img src='../../assets/banner/hobbies/1.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">After School Activity</h3>
                    <p className="banner-text">After-school programs started in early 1900s mainly just as supervision of students after the final school bell. Today, after-school programs do much more. There is a focus on helping students with schoolwork but can be beneficial to students in other ways. An after-school program, today, will not limit its focus on academics but with a holistic sense of helping the student population. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/hobbies/3.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">After School Activity</h3>
                    <p className="banner-text">After-school programs started in early 1900s mainly just as supervision of students after the final school bell. Today, after-school programs do much more. There is a focus on helping students with schoolwork but can be beneficial to students in other ways. An after-school program, today, will not limit its focus on academics but with a holistic sense of helping the student population. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/hobbies/4.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">After School Activity</h3>
                    <p className="banner-text">After-school programs started in early 1900s mainly just as supervision of students after the final school bell. Today, after-school programs do much more. There is a focus on helping students with schoolwork but can be beneficial to students in other ways. An after-school program, today, will not limit its focus on academics but with a holistic sense of helping the student population. <br /> </p>
                    <a className="banner-reamore" data-toggle="modal" data-target="#schoolModal" href="#">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
              {/* <div className='carousel-item'>
                <img src='../../assets/banner/hobbies/5.jpg' alt='img' />
              </div> */}
            </div>
          </div>
        </div>

        {/* Content Modal */}
        <div className='modal fade review-modal content-modal-custom' id='schoolModal'>
          <div className='modal-dialog model-box modal-lg' >
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title r-text'>After School Activity </h3>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>After-school programs started in early 1900s mainly just as supervision of students after the final school bell. Today, after-school programs do much more. There is a focus on helping students with schoolwork but can be beneficial to students in other ways. An after-school program, today, will not limit its focus on academics but with a holistic sense of helping the student population.  </p>
                <p>An after-school activity is any organized program that youth voluntary can participate in outside of the traditional school day.  </p>
                <p>After-school activities are a cornerstone of concerted cultivation, which is a style of parenting that emphasizes children gaining leadership experience and social skills through participating in organized activities. Such children are believed by proponents to be more successful while some others do consider as too many activities leading to overparenting. These after school activities help a child develop passion or a hobby overtime, engaging in creative and artistic pursuits, playing sports, or pursuing other amusements. There are plenty of organized after-school activities for children and Teens today to help a child develop hobbies, passion, and Skills (or whatever the objective maybe). </p>
                <p>In our approach @ BeWise, we have tried to categorize & catalogue this search to help you & your child, explore options and maximise value for your time, effort, passion, money, and energy for topic.  We believe that all the options at your finger tip to compare and review will enable you choose “Wise”. At our platform, we have categorised them as you can see below: </p>

                <ul>
                  <li>Sports - soccer, cricket, baseball, scooter racing, hockey, swimming & many more</li>
                  <li>Performing arts - dance, drama, ballet, band & many more</li>
                  <li>Music – Focus is on both Vocal and Instruments</li>
                  <li>Creative arts - painting, drawing, crafts & others</li>
                  <li>Academic enrichment – Mental Math, Literacy & others </li>
                  <li>Yoga – Various options to build Physical and Mental Health</li>
                  <li>Cultural Learning – Balvihaar, Prayer classes & others </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Content Modal end */}


      </React.Fragment>
    );
  }
}
class Tutions_Banner extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='col-md-12 p-0'>
          <div
            id='demo'
            className='carousel slide banner-home'
            data-ride='carousel'
          >
            <ul className='carousel-indicators'>
              <li data-target='#demo' data-slide-to='0' className='active'></li>
              <li data-target='#demo' data-slide-to='1'></li>
              <li data-target='#demo' data-slide-to='2'></li>
            </ul>

            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img src='../../assets/banner/tution/1.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Tuitions</h3>
                    <p className="banner-text">Teaching or instruction as a learning methodology to support individual pupils or in small groups to help the child. Objective of this approach being support to a child to develop or reinforce better study skills...
                     <br /> </p>
                     <a className="banner-reamore" href="#" data-toggle="modal" data-target="#schoolModal">Read More</a>
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/tution/2.jpg' alt='Img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Tuitions</h3>
                    <p className="banner-text">Teaching or instruction as a learning methodology to support individual pupils or in small groups to help the child. Objective of this approach being support to a child to develop or reinforce better study skills...
                    <br /> </p>
                    <a className="banner-reamore" href="#" data-toggle="modal" data-target="#schoolModal">Read More</a>

                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/tution/3.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Tuitions</h3>
                    <p className="banner-text">Teaching or instruction as a learning methodology to support individual pupils or in small groups to help the child. Objective of this approach being support to a child to develop or reinforce better study skills...
                     <br /> </p>

                     <a className="banner-reamore" href="#" data-toggle="modal" data-target="#schoolModal">Read More</a>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content Modal */}
        <div className='modal fade review-modal content-modal-custom' id='schoolModal'>
          <div className='modal-dialog model-box modal-lg' >
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title r-text'>Tuitions </h3>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>Teaching or instruction as a learning methodology to support individual pupils or in small groups to help the child.  Objective of this approach being support to a child to develop or reinforce better study skills and ultimately perform better with those skills. Tuition helps in increased confidence in their studies & overall behaviours of the child. Tutors also develop a more personal relationship with their students and able to see and cultivate potential within them.</p>
                <p>Given varying needs and objectives of a child, the subjects where this reinforcement is needed is different too.  In our approach @ BeWise, we have tried to categorize & catalogue this search to help you explore options quickly and maximise value.  The platform allows you understand and compare options easily and hence you & your child can make a “Wise” choice for your time, effort & money.
</p>

              </div>
            </div>
          </div>
        </div>
        {/* Content Modal end */}


      </React.Fragment>
    );
  }
}
class Camp_Banner extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='col-md-12 p-0'>
          <div
            id='demo'
            className='carousel slide banner-home'
            data-ride='carousel'
          >
            <ul className='carousel-indicators'>
              <li data-target='#demo' data-slide-to='0' className='active'></li>
              <li data-target='#demo' data-slide-to='1'></li>
              <li data-target='#demo' data-slide-to='2'></li>
            </ul>

            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img src='../../assets/banner/camp/1.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Learning Camps</h3>
                    <p className="banner-text">A Learning camp is a supervised program for children or teenagers conducted in short durations for specific learning objectives. (In late 1900, these were originally be known as summer camps and was... 
                    <br /> </p>
                    <a className="banner-reamore" href="#" data-toggle="modal" data-target="#schoolModal">Read More</a> 
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/camp/2.jpg' alt='Img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Learning Camps</h3>
                    <p className="banner-text">A Learning camp is a supervised program for children or teenagers conducted in short durations for specific learning objectives. (In late 1900, these were originally be known as summer camps and was... 
                     <br /> </p>
                     <a className="banner-reamore" href="#" data-toggle="modal" data-target="#schoolModal">Read More</a>
                  </div>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='../../assets/banner/camp/3.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Learning Camps</h3>
                    <p className="banner-text">A Learning camp is a supervised program for children or teenagers conducted in short durations for specific learning objectives. (In late 1900, these were originally be known as summer camps and was... 
                     <br /> </p>
                     <a className="banner-reamore" href="#" data-toggle="modal" data-target="#schoolModal">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content Modal */}
        <div className='modal fade review-modal content-modal-custom' id='schoolModal'>
          <div className='modal-dialog model-box modal-lg' >
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title r-text'>Learning Camps </h3>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>A Learning camp is a supervised program for children or teenagers conducted in short durations for specific learning objectives.  (In late 1900, these were originally be known as summer camps and was conducted only during long summer breaks – however has more recently evolved to become all through the year, due to changes in - demands from nuclear families/ learning objectives of a child/ pace of Technology / evolving teaching and learning methods and many more). These learning camps are sometimes part of academic curriculum (however not a requirement), and in many others aimed towards building potential for topics or subjects that is not accomplished during the academic year, but is seen as a required skill or behaviour of value in future.</p>
                <p>There are plenty of learning camp under various titles, for example there are camps for the creative arts, sports, magic, computer programming, coding & digital learning, language learning, mathematics etc.  The primary purpose of these camps are mostly educational & capability building aimed at a child’s holistic development and allows a child to learn & acquire new skills in a safe and nurturing environment. </p>
                <p>In our approach @ BeWise, we have tried to categorize & catalogue this search based on age of your child, so that your able to immediately review all options that fit your child’s development needs. Our belief is that upon you seeing the options and being able to compare or review the best that fits your requirement does allow you stay “Wise” always. </p>
              </div>
            </div>
          </div>
        </div>
        {/* Content Modal end */}

      </React.Fragment>
    );
  }
}
class CommunityCenter_Banner extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='col-md-12 p-0'>
          <div
            id='demo'
            className='carousel slide banner-home'
            data-ride='carousel'
          >
            <ul className='carousel-indicators'>
              <li data-target='#demo' data-slide-to='0' className='active'></li>
            </ul>

            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img src='../../assets/banner/community/1.jpg' alt='img' />
                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Community Support</h3>
                    <p className="banner-text">As the saying goes – Commonality is what builds a community.
                    The commonality factor for community @ BeWise platform.
                    <br />
                    The commonality factor for community @ BeWise platform is you - the Parent/s...
  <br /> </p>
  <a className="banner-reamore" href="#" data-toggle="modal" data-target="#schoolModal">Read More</a>
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Content Modal */}
        <div className='modal fade review-modal content-modal-custom' id='schoolModal'>
          <div className='modal-dialog model-box modal-lg' >
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title r-text'>Community Support </h3>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>As the saying goes – Commonality is what builds a community.</p>
                <p>The commonality factor for community @ BeWise platform is you - the Parent/s. Many parents have similar needs, beliefs, resources, preferences & face similar challenges depending on the stage of development of themselves and their child.  Surely can maximise with the help from each other too, within the community.</p>
                <p>In our approach @ BeWise, we have categorized four key areas (based on feedback and research) to start this journey of helping the BeWise Parent community.  These are subjects addressed to help you maximise value with some additional perspectives and choices of reaching further, in case of need.  We realise you have so many tasks to accomplish in your journey stage, and we have just tried making it easy for support - so that you can decide “Wisely”.  </p>

              </div>
            </div>
          </div>
        </div>
        {/* Content Modal end */}


      </React.Fragment>
    );
  }
}
class Events_Banner extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className='col-md-12 p-0'>
          <div
            id='demo'
            className='carousel slide banner-home'
            data-ride='carousel'
          >
            <ul className='carousel-indicators'>
              <li data-target='#demo' data-slide-to='0' className='active'></li>
            </ul>

            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img src='../../assets/banner/community/1.jpg' alt='img' />

                <div class="carousel-caption">
                  <div className="banner-cont">
                    <h3 className="banner-heading">Birthdays and events</h3>
                    <p className="banner-text">Research has established that celebrating birthdays of young improves the child’s self-esteem with the emotional demonstration of love..
                    builds a perception of time and growth given they may be unaware...
<br /> </p>
<a className="banner-reamore" href="#" data-toggle="modal" data-target="#schoolModal"> Read More</a> 
                    {/* <p><a className="a-readmore" href="#">Read More</a></p> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Content Modal */}
        <div className='modal fade review-modal content-modal-custom' id='schoolModal'>
          <div className='modal-dialog model-box modal-lg' >
            <div className='modal-content'>
              <div className='modal-header'>
                <h3 className='modal-title r-text'>Birthdays and events </h3>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>Research has established that celebrating birthdays of young improves the child’s self-esteem with the emotional demonstration of love, builds a perception of time and growth given they may be unaware, builds family bonds by people coming together, establishes social relationships with strength as the child invites friends & and surely creates positive memories for many years to come. Experts do agree. Birthdays and other milestones celebrated, allow children to experience a stronger connect to their community emotionally.</p>
                <p>Parents have different perspectives on the “How” of celebrating birthdays and other significant events in the child’s growth for various reasons, however, every child’s birthday is important milestone and they take it seriously. Leading to the birthdays a lot of planning happens and is significant excitement for all at home. </p>
                <p>In our approach @ BeWise, we have categorised options available, so that you are able to filter based on priorities and maximise best suited approach (be it - early planning or a last minute change) and helps you stay resourceful, whatever scale of celebrations you will chose.  We have attempted making this significant event for you be planned easier using leveraging all options in our platform to best chose the one that works for you best, and makes you remain “Wise” with your resources.   </p>
              </div>
            </div>
          </div>
        </div>
        {/* Content Modal end */}
      </React.Fragment>
    );
  }
}




export default {CommunityCenter_Banner}
export { CommunityCenter_Banner}
export {Events_Banner}
export {Camp_Banner}
export {Tutions_Banner}
export {Hobbies_Banner}
export {PlayAreas_Banner}
export {PreSchools_Banner}
export {Schools_Banner}
export {General_Banner}


